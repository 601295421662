import React, { useState, useEffect, useRef, useContext } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  setTiptapSidemenu,
  setListSpaceToUpdate,
  setActions,
} from "../../../store/app";

import { ActionCableContext } from "../../../components/Index";

import TiptapAi from "./TiptapAi";

import { Text, Box, Modal, Loader } from "@mantine/core";

const TiptapChannel = (props) => {
  const test = useRef();
  const editor = useRef();
  const timeoutId = useRef();
  const tiptapChannel = useRef();
  const space = useSelector((state) => state.space);
  const user = useSelector((state) => state.user);
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);

  const dispatch = useDispatch();

  const cable = useContext(ActionCableContext);

  const [loadingModalOpened, setLoadingModalOpened] = useState(null);

  useEffect(() => {
    test.current = Math.random();
    cableConnect();
    return () => {
      if (tiptapChannel.current) {
        tiptapChannel.current.unsubscribe();
        tiptapChannel.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "loading") {
      setLoadingModalOpened(true);
    } else {
      setLoadingModalOpened(false);
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    editor.current = props.editor;
  }, [props]);

  const onInsertContent = (content, user_id, allow_ai) => {
    
    if (user.id != user_id) {
      return;
    }
    
    if (allow_ai) {
      dispatch(setTiptapSidemenu({ opened: "ai" }));
    }

    if (timeoutId.current) {
      return;
    }

    timeoutId.current = setTimeout(() => {
      if (editor.current) {

        if (!allow_ai) {
          dispatch(setTiptapSidemenu({ opened: null }));
        }
        
        timeoutId.current = null;

        window.$waitForTemplateResponse = false;
      }
    }, 5000);
  };

  const onUpdateContent = (content) => {
    if (editor.current) {
      for (let i = 0; i < content.length; i++) {
        dispatch(setListSpaceToUpdate({ slug: content[i].slug }));
      }
    }
  };

  const cableConnect = () => {
    if (!tiptapChannel.current) {
      tiptapChannel.current = cable.subscriptions.create(
        {
          channel: "TiptapChannel",
          pod_slug: space.slug,
          token: window.$currentUserAuthToken,
        },
        {
          connected: () => {},
          disconnected: () => {},
          received: (data) => {
            // Validate view name to avoud inserting content blocks into reg page and viceversa
            console.log("TIPTAP channel: " + JSON.stringify(data));
            if (data.slug == space.slug) {
              if (data.action == "insert" && window.$waitForTemplateResponse) {
                onInsertContent(data.content, data.user_id, data.allow_ai);
              }
              if (data.action == "finish") {
                dispatch(setTiptapSidemenu({ opened: null }));
              }
              if (data.action == "loading") {
                dispatch(setTiptapSidemenu({ opened: "loading", text: data.text, text2: data.text2 }));
              }
              if (data.action == "update") {
                onUpdateContent(data.content);
              }
              if (data.action == "save") {
                if (
                  window.$tiptapNeedSaving &&
                  window.$tiptapNeedSaving.leave == true
                ) {
                  dispatch(setActions({ editPage: false }));
                }
                window.$tiptapNeedSaving = { status: false, leave: false };
              }
              if (data.action == "new-post" || data.action == "thumbnail-upload" || data.action == "update-post") {
                if (typeof props.onActionCallback === "function") {
                  props.onActionCallback(data.new_slug);
                }
              }
            }
          },
        }
      );
    }
  };

  return (
    <>
      <Modal
        opened={loadingModalOpened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        withCloseButton={false}
        onClose={() => {}}
        zIndex={99999}
      >
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
          }}
          pt={10}
        >
          <Loader />
          <Text mt={30}>{tiptapSidemenu.text}</Text>
          {tiptapSidemenu.text2 && (
            <Text mt={5}>{tiptapSidemenu.text2}</Text>
          )}
        </Box>
      </Modal>
      <TiptapAi />
    </>
  );
};

export default TiptapChannel;
