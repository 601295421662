import React, { useState, useEffect } from "react";

import {
  Alert,
  Text,
  Textarea,
  Box,
  Checkbox,
  Button,
  Divider,
  MantineProvider,
  Select,
} from "@mantine/core";

import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Payment from "../privacy/Paid";
import { AlertCircle } from "tabler-icons-react";

import { useForm } from "@mantine/form";

import InvitationModel from "../../../../models/Invitation";
import PremiumModal from "../../../app/PremiumModal";

import Swal from "sweetalert2";

export default function InviteEmail(props) {
  const { t, i18n } = useTranslation();
  const [checked, setChecked] = useState(true);
  const [inviteSection, setInviteSection] = useState("email");
  const [settings, setSettings] = useState({});
  const [opened, setOpened] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  const space = useSelector((state) => state.space);

  useEffect(() => {
    if (props.settings && !loaded) {
      setLoaded(true);
      setSettings({ ...props.settings });
      onUpdatePaymentFromProps(props.settings);
    }
  }, [props, loaded]);

  const form = useForm({
    initialValues: {
      add_people: "",
      message: "",
      notify_members: true,
      circle_payment: "",
      currency: "",
      payment_amount: "",
      payment_frequency: "",
      subscription_type: "",
      until_date: "",
      number_of_times: "",
      role: "member",
      add_to_subspaces: false,
      trial_period: "",
    },

    validate: {
      add_people: (value) =>
        value.length > 0 && value.split(",").length > 0
          ? null
          : "Add at least one person",
    },
  });

  const onInvite = () => {
    const canAddNewManagers =
      props.countsManagers < space.permissions.max_allowed_managers[0] ||
      space.permissions.max_allowed_managers[0] == -1;
    if (!canAddNewManagers && form.values.role === "manager") {
      setOpened([
        true,
        space.permissions.max_allowed_managers[1],
        space.permissions.max_allowed_managers[2],
      ]);
    } else {
      InvitationModel.onCreate(
        form.values,
        space.slug,
        (data) => {
          Swal.fire(data.title, data.message, "success");
          props.setOpened(false);
          props.setRefresh(true);
        },
        (data) => {
          Swal.fire(data.title, data.message, "error");
        }
      );
    }
  };

  const onUpdatePaymentFromProps = (params) => {
    if (!params.payment_settings) {
      return;
    }
    if (params.circle_payment) {
      form.setFieldValue("circle_payment", params.circle_payment);
    }
    if (params.payment_settings.currency) {
      form.setFieldValue("payment_currency", params.payment_settings.currency);
    }
    if (params.payment_settings.default_payment_amount) {
      form.setFieldValue(
        "payment_amount",
        params.payment_settings.default_payment_amount
      );
    }
    if (params.payment_settings.default_payment_frequency) {
      form.setFieldValue(
        "payment_frequency",
        params.payment_settings.default_payment_frequency
      );
    }
    if (params.payment_settings.subscription_type) {
      form.setFieldValue(
        "subscription_type",
        params.payment_settings.subscription_type
      );
    }
    if (params.payment_settings.until_date) {
      form.setFieldValue("until_date", params.payment_settings.until_date);
    }
    if (params.payment_settings.number_of_times) {
      form.setFieldValue(
        "number_of_times",
        params.payment_settings.number_of_times
      );
    }
    if (params.payment_settings.trial_period) {
      form.setFieldValue(
        "trial_period",
        params.payment_settings.trial_period
      );
    }
  };

  const onUpdatePayment = (params) => {
    if (!params.pod_payment_settings_attributes) {
      return;
    }

    // const auxSettings = { ...settings };

    if (params.circle_payment) {
      form.setFieldValue("circle_payment", params.circle_payment);
    }
    if (params.pod_payment_settings_attributes.currency) {
      form.setFieldValue(
        "payment_currency",
        params.pod_payment_settings_attributes.currency
      );
    }
    if (params.pod_payment_settings_attributes.default_payment_amount) {
      form.setFieldValue(
        "payment_amount",
        params.pod_payment_settings_attributes.default_payment_amount
      );
    }
    if (params.pod_payment_settings_attributes.default_payment_frequency) {
      form.setFieldValue(
        "payment_frequency",
        params.pod_payment_settings_attributes.default_payment_frequency
      );
    }
    if (params.pod_payment_settings_attributes.subscription_type) {
      form.setFieldValue(
        "subscription_type",
        params.pod_payment_settings_attributes.subscription_type
      );
    }
    if (params.pod_payment_settings_attributes.until_date) {
      form.setFieldValue(
        "until_date",
        params.pod_payment_settings_attributes.until_date
      );
    }
    if (params.pod_payment_settings_attributes.number_of_times) {
      form.setFieldValue(
        "number_of_times",
        params.pod_payment_settings_attributes.number_of_times
      );
    }

    if (params.pod_payment_settings_attributes.trial_period) {
      form.setFieldValue(
        "trial_period",
        params.pod_payment_settings_attributes.trial_period
      );
    }

    // auxSettings.circle_payment = form.values.circle_payment;
    // auxSettings.payment_settings.currency = form.values.currency;
    // auxSettings.payment_settings.default_payment_amount =
    //   form.values.payment_amount;
    // auxSettings.payment_settings.default_payment_frequency =
    //   form.values.payment_frequency;
    // auxSettings.payment_settings.subscription_type =
    //   form.values.subscription_type;
    // auxSettings.payment_settings.until_date = form.values.until_date;
    // auxSettings.payment_settings.number_of_times = form.values.number_of_times;
    // auxSettings.payment_settings.trial_period = form.values.trial_period;
    // setSettings(auxSettings);
  };

  return (
    <Box mt={props.showTitle ? 5 : 15}>
      <form onSubmit={form.onSubmit((values) => onInvite(values))}>
        <Textarea
          {...form.getInputProps("add_people")}
          mt={props.showTitle ? 5 : 10}
          radius="sm"
          size="md"
          autosize
          minRows={2}
          maxRows={7}
          label={props.showTitle ? "Send email invitations" : ""}
          placeholder={t(
            "spaces.settings.members.inviteEmail.invite.placeholder"
          )}
        />
        <Textarea
          {...form.getInputProps("message")}
          mt={10}
          radius="sm"
          size="md"
          autosize
          minRows={2}
          maxRows={7}
          placeholder={t("spaces.settings.members.inviteEmail.invite.message")}
        />

        <Select
          {...form.getInputProps("role")}
          mt={10}
          radius="sm"
          size="md"
          label="Add as"
          placeholder="Pick one"
          data={[
            { value: "member", label: "Member" },
            { value: "moderator", label: "Moderator" },
            { value: "editor", label: "Editor" },
            { value: "manager", label: "Manager" }
          ]}
        />

        <Divider
          mt={20}
          sx={(theme) => ({
            borderTop: `1px solid ${theme.colors.gray[2]}`,
          })}
        />
        {settings && settings.can_accept_charges && settings.is_paid && (
          <Payment
            settings={settings}
            // updateAll={true}
            onUpdate={onUpdatePayment}
            space={props.space}
            options={["paid", "flexible", "recurring"]}
            inner={true}
          />
        )}
        {space.slug && space.permissions.can_send_email_invites[0] && window.$fetchedTopSpace.is_email_ready && (
          <Checkbox
            {...form.getInputProps("notify_members")}
            mt={15}
            onLabel="ON"
            offLabel="OFF"
            label={t(
              "spaces.settings.members.inviteEmail.invite.checkboxLabel"
            )}
            checked={form.values.notify_members}
          />
        )}
        <Checkbox
          {...form.getInputProps("add_to_subspaces")}
          mt={15}
          onLabel="ON"
          offLabel="OFF"
          label={"Add to all direct subspaces"}
        />
        {space.slug && !space.permissions.can_send_email_invites[0] && (
          <Alert
            color="green"
            radius="md"
            mt={15}
          >
            <Text>
              {t("spaces.settings.members.inviteEmail.invite.alertMessage")}
            </Text>
            <MantineProvider theme={{ primaryShade: 5 }}>
              <Button
                variant="filled"
                radius="xl"
                color="green"
                mt={15}
                mb={5}
                component={Link}
                to={`/app/pricing`}
              >
                {t("spaces.settings.members.inviteEmail.invite.alertbutton")}
              </Button>
            </MantineProvider>
          </Alert>
        )}
        {space.slug && space.permissions.can_send_email_invites[0] && !window.$fetchedTopSpace.is_email_ready && (
          <Alert
            color="green"
            radius="md"
            mt={15}
          >
            <Text>
              {"You are currently on a free trial and cannot send invitations through Sutra. Email invitations can be sent through our system once your first account payment is processed or by making a request to support@sutra.co"}
            </Text>
          </Alert>
        )}
        <Button
          variant="filled"
          radius="xl"
          mt={20}
          type="submit"
          sx={(theme) => ({
            backgroundColor: theme.colors.sutrablue[3],
            "&:hover": {
              backgroundColor: theme.colors.sutrablue[4],
            },
          })}
        >
          {t("spaces.settings.members.inviteEmail.invite.button")}
        </Button>
      </form>

      <PremiumModal opened={opened} setOpened={setOpened} />

    </Box>
  );
}
