import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { useDisclosure } from "@mantine/hooks";

import SpaceModel from "../../models/Space";
import SpaceNeo4jModel from "../../models/neo4j/Space";
import { useTranslation } from "react-i18next";

// import { notificationsStyle } from "./style/notifications.js"
import useNotificationsStyle from "./style/notifications.js";

import { setTiptapSidemenu } from "../../store/app";

import isSpaceThemed from "../../utils/IsSpaceThemed";
import useSidebarStyles from "../../utils/SidebarStyles";
import defaultSpaceColors from "../../collections/defaultSpaceColors";
import useActivityCounter from "../../utils/ActivityCounter";

import { sutraTheme } from "../../collections/sutraTheme";

import tinycolor from "tinycolor2";

import {
  Text,
  Box,
  ScrollArea,
  Button,
  Popover,
  Group,
  Stack,
  Indicator,
  Loader,
  Center,
  Tooltip,
  createStyles,
  Drawer
} from "@mantine/core";

import Avatar from "../utils/LazyAvatar";

const paginator = {
  per: 30,
  page: 1,
};

const loadingVariables = {
  firstLoad: false,
  reload: true,
  noMore: false,
};

const useStyles = createStyles((theme, { colors }) => ({
  primaryButtonStyle: {
    backgroundColor: isSpaceThemed(colors)
      ? colors.primary_button_background_color
      : theme.colors.gray[2],
    color: isSpaceThemed(colors) ? colors.primary_button_text_color : "#000000",
    "&:hover": {
      backgroundColor: isSpaceThemed(colors)
        ? tinycolor(colors.primary_button_background_color).darken(4).toString()
        : theme.colors.gray[1],
    },
  },
  badge: {
    justifyContent: "center",
    height: "100%",
    color: theme.colors.sutrapink[4],
  },
}));

function UnreadDot({ id }) {
  const { activityCount } = useActivityCounter(
    ["user_message"],
    [`all`],
    ["pod"],
    [`${id}`]
  );
  const { settingsView, registrationView } = useSidebarStyles();
  let colors = useSelector((state) => state.colors);
  colors = !settingsView && !registrationView ? colors : defaultSpaceColors;
  const { classes, cx, theme } = useStyles({ colors });

  return (
    <>
      {activityCount > 0 ? (
        <Stack className={classes.badge}>
          <FontAwesomeIcon icon={solid("circle")} />
        </Stack>
      ) : (
        <Stack></Stack>
      )}
    </>
  );
}

export default function DirectMessages(props) {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const space = useSelector((state) => state.space);
  const user = useSelector((state) => state.user);

  const { activityCount } = useActivityCounter(["user_message"]);

  const { settingsView, registrationView } = useSidebarStyles();
  let colors = useSelector((state) => state.colors);
  colors = !settingsView && !registrationView && !colors?.is_vision_builder ? colors : defaultSpaceColors;
  const { classes, cx, theme } = useStyles({ colors });

  const scroll = useSelector((state) => state.scroll);

  const [messages, setMessages] = useState([]);

  const [loading, setLoading] = useState(false);

  const [opened, handlers] = useDisclosure(false);

  const { notificationsStyle } = useNotificationsStyle();

  const dispatch = useDispatch();

  useEffect(() => {
    if (opened) {
      loadingVariables.reload = true;
      onFetch();
    }
  }, [opened]);

  useEffect(() => {
    onScrollBottom();
  }, [scroll]);

  useEffect(() => {
    if (props.opened) {
      loadingVariables.reload = true;
      onFetch();
    }
  }, [props]);


  const onViewAll = () => {
    navigate(`/home/direct`);
    handlers.toggle();
  };

  const onScrollBottom = () => {
    if (scroll && scroll.atBottom) {
      if (loadingVariables.noMore) {
        return;
      }
      if (!loading) {
        paginator.page++;
        onFetch();
      }
    }
  };

  const onFetch = () => {
    setLoading(true);

    let newMessages = [...messages];
    if (loadingVariables.reload) {
      newMessages = [];
      paginator.page = 1;
    }

    loadingVariables.reload = false;


    SpaceModel.onFetchByScope(
      "direct",
      ["members", "simple", "last_messages"],
      paginator,
      (data) => {
        loadingVariables.firstLoad = true;

        setMessages(newMessages.concat(data));
        setLoading(false);

        if (data.length == 0 && paginator.page > 1) {
          paginator.page--;
          loadingVariables.noMore = true;
        }
      },
      (data) => {},
      null
    );
  };

  const getMessagedUserFullName = (message) => {
    return message.abstract.full_name;
  };

  const getMessagedUserAvatar = (message) => {
    return message.abstract.avatar;
  };

  const getLastMessageAuthor = (message) => {
    return message.abstract.username === user.username
      ? "You"
      : message.abstract.full_name;
  };

  const getLastMessageText = (message) => {
    return message.abstract.text;
  };

  const onClickMessage = (message) => {
    handlers.close();
    if (
      window.location.pathname.indexOf("/space") >= 0 &&
      space &&
      space.slug &&
      !space.is_direct
    ) {
      dispatch(setTiptapSidemenu({ opened: "direct", option: message.slug }));
    } else {
      navigate(message.link);
    }
  };

  const RenderMessages = () => {
    return messages.map((message, index) => {
      return (
        <Box
          sx={(theme) => notificationsStyle.notificationItem}
          onClick={() => onClickMessage(message)}
        >
          <Group
            spacing={0}
            noWrap
            position="apart"
            sx={{
              width: "260px",
            }}
          >
            <Group
              spacing={15}
              noWrap
              sx={{
                // width: "100%",
                paddingRight: 10,
              }}
            >
              <Avatar radius="xl" src={getMessagedUserAvatar(message)} />
              <Stack spacing={0}>
                <Text
                  color="black"
                  weight="600"
                  pb={2}
                  sx={{ lineHeight: 1.2 }}
                >
                  {getMessagedUserFullName(message)}
                </Text>
                <Text
                  color="dimmed"
                  size="sm"
                  sx={{ lineHeight: 1.2, wordBreak: 'break-word' }}
                  lineClamp={2}
                >
                  {getLastMessageAuthor(message)}: {getLastMessageText(message)}
                </Text>
              </Stack>
            </Group>
            {/* Math.random() > .5 ? ( */}
            <UnreadDot id={message.slug} />
          </Group>
        </Box>
      );
    });
  };

  const RenderLoader = () => {
    return (
      <Center pt={50}>
        <Loader />
      </Center>
    );
  };

  const RenderInsides = () => {
    return (
      <Box>
        <Group
          position="apart"
          pt={10}
          pb={10}
          pl={20}
          pr={10}
          sx={(theme) => ({ backgroundColor: theme.colors.gray[1] })}
        >
          <Text color="dimmed">{t("layout.directMessages.label")}</Text>
          <Button
            onClick={() => onViewAll()}
            variant="outline"
            color="dark"
            radius="xl"
            size="xs"
            compact
          >
            {t("layout.directMessages.actionButton")}
          </Button>
        </Group>
        <ScrollArea style={{ height: props.component == "drawer" ? "calc(100vh - 100px)" : "calc(100vh - 150px)" }}>
          {!loadingVariables.firstLoad || loadingVariables.reload ? (
            <RenderLoader />
          ) : (
            <RenderMessages />
          )}
          {messages.length == 0 && loadingVariables.firstLoad ? (
            <Text size="sm" align="center" color="dimmed" px={20} py={20}>
              {t("layout.directMessages.empty")}
            </Text>
          ) : (
            ""
          )}
        </ScrollArea>
      </Box>
    );
  };

  const RenderTriggerButton = () => {
    return (
      <Indicator
        size={activityCount > 0 ? 20 : 0}
        label={activityCount > 0 ? activityCount : ""}
        offset={4}
        color="sutrapink"
        onClick={() => handlers.toggle()}
        styles={{
          indicator: {
            cursor: "pointer",
            backgroundColor: isSpaceThemed(colors)
              ? colors.default_badge_color
              : sutraTheme.colors.sutrapink[3],
            color: isSpaceThemed(colors)
              ? colors.default_badge_text_color
              : theme.colors.gray[1],
            "&:hover button": {
              backgroundColor: isSpaceThemed(colors)
                ? tinycolor(colors.default_badge_color).darken(4).toString()
                : sutraTheme.colors.sutrapink[1],
              color: isSpaceThemed(colors)
                ? tinycolor(colors.default_badge_text_color)
                    .darken(4)
                    .toString()
                : theme.colors.gray[1],
            },
          },
        }}
      >
        <Tooltip
          disabled={window.$isTouchDevice && window.$isTouchDevice()}
          label={t("layout.directMessages.directMessage")}
          withArrow
        >
          <Button
            variant="light"
            color="dark"
            radius="xl"
            onClick={() => handlers.toggle()}
            className={classes.primaryButtonStyle}
          >
            <FontAwesomeIcon icon={solid("message")} />
          </Button>
        </Tooltip>
      </Indicator>
    );
  };

  return (
    <>
      {props.component == "drawer" ? (
        <>
          <Drawer
            opened={props.opened}
            onClose={() => props.setOpened(false)}
            title=""
            padding={0}
            size="lg"
          >
            {RenderInsides()}
          </Drawer>
        </>
      ) : (
        <Popover
          opened={opened}
          onClose={handlers.close}
          width={300}
          position="bottom"
          placement="end"
          spacing={0}
          shadow="lg"
          target={RenderTriggerButton()}
        >
          {RenderInsides()}
        </Popover>
      )}
    </>
  );
}
