import React, { useEffect, useRef, useContext } from "react";

import { ActionCableContext } from "../../../components/Index";

const VisionChannel = (props) => {
  const visionChannel = useRef();

  const cable = useContext(ActionCableContext);

  useEffect(() => {
    cableConnect();
    return () => {
      if (visionChannel.current) {
        visionChannel.current.unsubscribe();
        visionChannel.current = null;
      }
    };
  }, []);

  const cableConnect = () => {
    if (!visionChannel.current) {
      visionChannel.current = cable.subscriptions.create(
        {
          channel: "VisionChannel",
          pod_slug: props.slug,
          token: window.$currentUserAuthToken,
        },
        {
          connected: () => {},
          disconnected: () => {},
          received: (data) => {
            console.log("VISION channel: " + JSON.stringify(data));
            if (data.slug == props.slug) {
              if (data.action == "update") {
                if (typeof props.onUpdate === "function") {
                  props.onUpdate(data.vision_id, data.type);
                }
              } else if (data.action == "add_content") {
                if (typeof props.onAddContent == "function") {
                  props.onAddContent(data);
                }
              }
            }
          },
        }
      );
    }
  };

  return (
    <></>
  );
};

export default VisionChannel;
