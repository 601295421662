import React, { useRef, useState, useEffect } from "react";

import {
  Text,
  Box,
  createStyles,
  TextInput,
  Group,
  NativeSelect,
  Button,
} from "@mantine/core";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  item: {
    paddingBottom: 16,
    marginBottom: 18,
    // borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  itemTitle: {
    fontSize: 18,
  },

  itemDescription: {
    fontSize: 14,
    color: theme.colors.gray[6],
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },

  subOptionWrap: {
    marginTop: 15,
    backgroundColor: theme.colors.gray[0],
    padding: 15,
    borderRadius: 5,
  },

  subOptionItem: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `6px ${theme.spacing.xs}px`,
    borderRadius: theme.radius.sm,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.white,

    "&:focus-within": {
      borderColor: theme.colors[theme.primaryColor][6],
    },
  },

  control: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,

    "&:disabled": {
      borderColor:
        theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3],
      opacity: 0.8,
      backgroundColor: "transparent",
    },
  },
}));

const providersData = [
  {
    value: "facebook-pixel",
    label: "Facebook pixel",
    placeholder: "Facebook pixel ID",
  },
  {
    value: "google-analytics",
    label: "Google Analytics",
    placeholder: "Tracking ID (UA-XXXXXXXX-X)",
  },
];

export default function Scripts({onUpdate, openPremiumModal}) {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const space = useSelector((state) => state.space);

  const [disableActions, setDisableActions] = useState("");
  const [scripts, setScripts] = useState([]);

  const propsLoaded = useRef();
  const lastSpaceId = useRef();
  const saveTimeout = useRef();

  useEffect(() => {
    return () => {
      propsLoaded.current = false;
    };
  }, []);

  useEffect(() => {

    if (!space.settings){
      return
    }

    propsLoaded.current = false;

    setScripts(
      space.settings.third_party_scripts
        ? space.settings.third_party_scripts
        : []
    );

    setTimeout(() => {
      propsLoaded.current = true;
      // setDisableActions(false);
    }, 1000);

  }, [space]);

  useEffect(() => {
    if (propsLoaded.current) {
      if (saveTimeout.current) {
        clearTimeout(saveTimeout.current);
      }

      saveTimeout.current = setTimeout(() => {
        onUpdate({
          pod_settings_attributes: { third_party_scripts: scripts },
        });
      }, 2000);
    }
  }, [scripts]);

  const onAddScript = () => {
    const newScripts = [...scripts];
    newScripts.push({ provider: null, id: null });
    setScripts(newScripts);
  };

  const onEditScript = (index, key, value) => {
    const newScripts = [...scripts];
    const script = newScripts[index];
    script[key] = value;
    setScripts(newScripts);
  };

  const onDeleteScript = (index) => {
    const newScripts = [...scripts];
    newScripts.splice(index, 1);
    setScripts(newScripts);
  };

  const getProviders = () => {
    const array = [];
    // for (let i = 0; i < providersData.length; i ++){

    //   let exist = false
    //   for (let j = 0; j < scripts.length; j++) {
    //     const script = scripts[j];
    //     if (script.provider == providersData[i].value){
    //       exist = true
    //     }
    //   }

    //   if (!exist){
    //     array.push(providersData[i])
    //   }
    // }
    // return array
    return providersData;
  };

  const getPlaceholder = (item) => {
    for (let i = 0; i < providersData.length; i++) {
      if (providersData[i].value == item.provider) {
        return providersData[i].placeholder;
      }
    }
    return "Select a provider";
  };

  const onAllScriptsValid = () => {
    for (let i = 0; i < scripts.length; i++) {
      const script = scripts[i];
      if (!script.provider || !script.id) {
        return false;
      }
    }
    return true;
  };

  return (
    <Box mt={20}>
      <Box className={classes.item}>
        <Text className={classes.itemTitle}>{"Add third party scripts"}</Text>
        <Text className={classes.itemDescription}>
          {
            "Add Google Analytics or a Facebook pixel to your registration page."
          }
        </Text>
        {scripts.map((item, index) => {
          return (
            <Group mt={10} sx={{
              alignItems: "flex-end",
            }}>
              <NativeSelect
                label="Script provider"
                placeholder="Select provider"
                data={getProviders()}
                value={item.provider}
                onChange={(event) =>
                  onEditScript(index, "provider", event.currentTarget.value)
                }
                disabled={disableActions}
              />
              <TextInput
                label="Account ID"
                placeholder={getPlaceholder(item)}
                className={classes.input}
                disabled={disableActions || !item.provider}
                value={item.id}
                onChange={(event) =>
                  onEditScript(index, "id", event.currentTarget.value)
                }
              />
              <Button
                color={"red"}
                mt={20}
                onClick={() => onDeleteScript(index)}
              >
                X
              </Button>
            </Group>
          );
        })}
        <Button
          radius={'xl'}
          mt={20}
          onClick={() => {
            if (typeof openPremiumModal === "function") {
              openPremiumModal();
            } else {
              onAddScript()
            }
          }}
          disabled={!onAllScriptsValid()}
        >
          Add script
        </Button>
      </Box>
    </Box>
  );
}
