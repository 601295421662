import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { setTiptapSidemenu } from "../../../store/app";

import { Text, Box, Modal, Textarea, Button } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useFocusTrap } from "@mantine/hooks";
import { useForm } from "@mantine/form";

import ViewModel from "../../../models/View";

const TiptapAi = (props) => {
  const space = useSelector((state) => state.space);
  const user = useSelector((state) => state.user);
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [allowAi, setAllowAi] = useState(false);

  const focusTrapRef = useFocusTrap();

  useEffect(() => {3
    
  }, []);

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "ai") {
      setOpened(true);
    } else {
      // setOpened(false);
    }
  }, [tiptapSidemenu]);

  useEffect(() => {}, [props]);

  const form = useForm({
    initialValues: {
      description: "",
      people: "",
    },

    validate: {
      description: (value) =>
        value.length >= 3
          ? null
          : "Fill this field for the AI to work properly",
      people: (value) =>
        value.length >= 3
          ? null
          : "Fill this field for the AI to work properly",
    },
  });

  const onSave = () => {
    dispatch(setTiptapSidemenu({ opened: "loading", text: "Generating language..." }));
    setOpened(false);
    setAllowAi(false);

    const id = window.location.pathname.indexOf("/registration") >= 0 ? "editable-registration-page" : "tiptap";

    // This is a test comment
    ViewModel.onAiMagic(
      id,
      space.slug,
      { ...form.values },
      (data) => {
        form.setFieldValue("description", "");
        form.setFieldValue("people", "");
      },
      (data) => {}
    );
  };

  return (
    <Modal
      opened={opened}
      closeOnClickOutside={false}
      closeOnEscape={false}
      onClose={() => {}}
      zIndex={99999}
      styles={{
        header: { marginBottom: 0 },
      }}
    >
      <Box>
        {allowAi ? (
          <form
            onSubmit={form.onSubmit((values) => onSave(values))}
            ref={focusTrapRef}
          >
            <Textarea
              {...form.getInputProps("description")}
              placeholder={t("tiptap.components.tiptapAi.placeholder")}
              label={t("tiptap.components.tiptapAi.label")}
              autosize
              minRows={2}
            />
            <Textarea
              {...form.getInputProps("people")}
              placeholder={t("tiptap.components.tiptapAi.placeholder2")}
              label={t("tiptap.components.tiptapAi.label2")}
              autosize
              mt={20}
              minRows={2}
            />
            <Button radius="xl" size="sm" mt={30} type="submit">
              {`Generate language`}
            </Button>
          </form>
        ) : (
          <>
            <Text>
              {t("tiptap.components.tiptapAi.text")}
            </Text>
            <Button radius="xl" size="sm" mt={30} mr={10} onClick={() => setAllowAi(true)}>
              {`Yes`}
            </Button>
            <Button radius="xl" size="sm" mt={30} color="dark" onClick={() => setOpened(false)}>
              {`No`}
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default TiptapAi;
