import axios from "axios";

class View {
  constructor() {
    if (this instanceof View) {
      throw Error("View class cannot be instantiated.");
    }
  }

  static onFetch = async (id, space_id, sucessCallback, errorCallback) => {

    const urlParams = new URLSearchParams(window.location.search);

    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      preview_ij: urlParams.has('preview_ij') ? true : null
    };

    await axios
      .get(`/api/v4/spaces/${space_id}/views/${id}.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onFetchRaw = async (id, space_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      raw: true
    };

    await axios
      .get(`/api/v4/spaces/${space_id}/views/${id}.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onFetchBackupById = async (
    id,
    backup_id,
    space_id,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      backup_id,
    };

    await axios
      .get(`/api/v4/spaces/${space_id}/views/${id}/backup.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onFetchBackups = async (
    id,
    space_id,
    { page, per },
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      page,
      per,
    };

    await axios
      .get(`/api/v4/spaces/${space_id}/views/${id}/backups.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onRestoreBackup = async (
    id,
    backup_id,
    space_id,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      backup_id,
    };

    await axios
      .post(
        `/api/v4/spaces/${space_id}/views/${id}/restore_backup.json`,
        params
      )
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onUpdate = async (
    id,
    space_id,
    content,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      content: content,
    };

    await axios
      .put(`/api/v4/spaces/${space_id}/views/${id}.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onAiMagic = async (
    id,
    space_id,
    ai_params,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      ...ai_params,
    };

    await axios
      .post(`/api/v4/spaces/${space_id}/views/${id}/ai_magic.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };
}

export default View;
