import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import SpaceModel from "../../../../models/Space";
import { setCookie } from "../../../../utils/Cookies";

import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  spaceContainer: {
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
  },
}));

export default function Gateway(props) {
  const navigate = useNavigate();

  const colors = useSelector((state) => state.colors);
  const space = useSelector((state) => state.space);

  const currentLocation = useLocation();

  useEffect(() => {
    const b = document.getElementsByTagName("body")[0];
    // debugger;
    b.style.display = "none";

    return () => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";
    };
  }, []);

  useEffect(() => {
    if (space.slug) {

      const search = currentLocation.search;
      const show_bundles = new URLSearchParams(search).get("bundles");
      if (show_bundles){
        // setCookie("bundles", show_bundles, 365 * 10);
        localStorage.setItem("bundles", show_bundles);
      }
      else{
        // setCookie("bundles", "null", 0);
        localStorage.removeItem('bundles');
      }

      SpaceModel.onGateway(
        space.slug,
        (data) => {
          localStorage.setItem("gateway_slug", space.slug);
          navigate(data.redirect_to.url);
        },
        (data) => {}
      );
    }
  }, [space]);

  return <p></p>;
}
