import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Container, Box } from "@mantine/core";

import { useDispatch, useSelector } from "react-redux";

import TopActions from "./TopActions";
import Breadcrumbs from "./Breadcrumbs";
import PinMessage from "./presentations/discussion/PinMessage";
import NewMessage from "./presentations/discussion/NewMessage";
import HeaderTitle from "./HeaderTitle";
import HeaderTags from "./HeaderTags";
import HeaderAuthor from "./HeaderAuthor";
import Calendar from "./Calendar";
import StatusBar from "./StatusBar";
import TagFilter from "./TagFilter";
import InteractionsBar from "./InteractionsBar";
import DummyBar from "./DummyBar";
import RedirectBar from "./RedirectBar";
import NewReflectionsBar from "./presentations/discussion/NewReflections";
import TagsChips from "./presentations/discussion/TagsChips";

import { setFixedHeaderStatus } from "../../store/app";

import isSpaceThemed from "../../utils/IsSpaceThemed";
import defaultSpaceColors from "../../collections/defaultSpaceColors";

let timeoutId = null;

let showAux = false;
let spaceAux = { header: { url: null } };

export default function FixedHeader(props) {
  const colors = useSelector((state) => state.colors);
  const colors2 = useSelector((state) => state.colors2);
  const [show, setShow] = useState(false);
  const space = useSelector((state) => state.space);
  const space2 = useSelector((state) => state.space2);
  const hiddenTitle = useSelector((state) => state.hiddenTitle);
  const hiddenBreadcrumbs = useSelector((state) => state.hiddenBreadcrumbs);
  const fixedHeaderStatus = useSelector((state) => state.fixedHeaderStatus);
  const newMessage = useSelector((state) => state.newMessage);

  const observer = useRef();
  const scrollViewport = useRef();

  const location = useLocation();

  const dispatch = useDispatch();

  const [tag, setTag] = useState(null);

  useEffect(() => {
    setShow(props.show);
    if (props.isMain) {
      const scrollViewComp =
        document.getElementsByClassName("content-scrollview")[0];
      if (scrollViewComp) {
        scrollViewport.current = scrollViewComp.getElementsByClassName(
          "mantine-ScrollArea-viewport"
        )[0];
        if (scrollViewport.current) {
          scrollViewport.current.addEventListener(
            "scroll",
            onScrollPositionChange
          );
          if (observer.current) {
            clearInterval(observer.current);
            observer.current = null;
          }
          observer.current = setInterval(() => {
            if (window.$headerIntervalActive) {
              window.$headerIntervalActive = false;
              const scrollTop = scrollViewport.current.scrollTop;
              onScrollPositionChange({ currentTarget: { scrollTop } });
            }
          }, 500);
        }
      }
    }

    return () => {
      if (props.isMain) {
        const scrollViewComp =
          document.getElementsByClassName("content-scrollview")[0];
        if (scrollViewComp) {
          scrollViewport.current = scrollViewComp.getElementsByClassName(
            "mantine-ScrollArea-viewport"
          )[0];
          if (scrollViewport.current) {
            scrollViewport.current.removeEventListener(
              "scroll",
              onScrollPositionChange
            );
            if (observer.current) {
              clearInterval(observer.current);
              observer.current = null;
            }
          }
        }
      }
    };
  }, []);

  useEffect(() => {
    setShow(props.inverted ? !fixedHeaderStatus.show : fixedHeaderStatus.show);
  }, [fixedHeaderStatus]);

  useEffect(() => {
    if (getSpace() && getSpace().slug) {
      spaceAux = { ...getSpace() };
    }
  }, [space, space2]);

  useEffect(() => {
    const search = location.search;
    const tag = new URLSearchParams(search).get("tag");
    setTag(null);
    if (tag) {
      setTag(tag);
    }
  }, [location]);

  const getSpace = () => {
    if (props.useSpace2) {
      return space2;
    } else {
      return space;
    }
  };

  const getColors = () => {
    if (props.useSpace2) {
      return isSpaceThemed(colors2) ? colors2 : defaultSpaceColors;
    } else {
      return isSpaceThemed(colors) ? colors : defaultSpaceColors;
    }
  };

  const onScrollPositionChange = (event) => {
    const pos = { y: event.currentTarget.scrollTop };

    // console.log("height:", event.currentTarget.innerHeight)

    let fixedHeight = 80;
    let skipHeight = 80;

    if (spaceAux && spaceAux.header && spaceAux.header.url) {
      fixedHeight += 284;
      // skipHeight += 284
    }
    if (spaceAux && spaceAux.pin_message) {
      fixedHeight += 84;
      skipHeight += 84;
    }
    window.$skipHeight = skipHeight;

    if (pos.y > fixedHeight && !showAux) {
      showAux = true;

      dispatch(setFixedHeaderStatus({ show: true }));
      window.$skipHeight = 0;
    } else if (pos.y <= fixedHeight && showAux) {
      showAux = false;

      dispatch(setFixedHeaderStatus({ show: false }));
      window.$skipHeight = skipHeight;
    }

    if (showAux) {
      window.$skipHeight = skipHeight + 90;
    } else {
      window.$skipHeight = skipHeight;
    }
  };

  return (
    <Box
      sx={{
        position: props.isMain ? "absolute" : "inline",
        backgroundColor: props.isMain ? getColors().background_color : "",
        zIndex: props.isMain ? 2 : "",
        width: props.isMain ? "calc(100% - 10px)" : "100%",
      }}
    >
      {props.isMain == false &&
        props.components.indexOf("interactions") >= 0 &&
        getSpace().settings?.show_progress_bar && (
          <InteractionsBar space={getSpace()} useSpace2={props.useSpace2} />
        )}

      <Container
        size={
          getSpace().space_width == "wide" ||
          window.location.pathname.indexOf("/demo") >= 0
            ? 960
            : 801
        }
      >
        {props.editable &&
        props.components.indexOf("topActions") >= 0 && getSpace().permissions.can_manage  ? (
          <TopActions
            useSpace2={props.useSpace2}
          />
        ) : (
          <Box sx={{height: "40px"}}></Box>
        )}
        {((show && props.isMain) || props.isMain == false) &&
        props.components.indexOf("breadcrumbs") >= 0 ? (
          <Breadcrumbs
            breadcrumbsHidden={hiddenBreadcrumbs.hidden}
            useSpace2={props.useSpace2}
          />
        ) : (
          ""
        )}
        {((show && props.isMain) || props.isMain == false) &&
        props.components.indexOf("title") >= 0 &&
        !hiddenTitle.hidden ? (
          <HeaderAuthor editable={props.editable} useSpace2={props.useSpace2} />
        ) : (
          ""
        )}
        {((show && props.isMain) || props.isMain == false) &&
        props.components.indexOf("title") >= 0 &&
        !hiddenTitle.hidden ? (
          <HeaderTitle editable={props.editable} useSpace2={props.useSpace2} />
        ) : (
          ""
        )}
        {((show && props.isMain) || props.isMain == false) &&
        props.components.indexOf("title") >= 0 &&
        !hiddenTitle.hidden ? (
          <HeaderTags editable={props.editable} useSpace2={props.useSpace2} />
        ) : (
          ""
        )}
        {((show && props.isMain) || props.isMain == false) &&
        props.components.indexOf("pinMessage") >= 0 &&
        getSpace() &&
        getSpace().pin_message ? (
          <PinMessage
            spaceId={getSpace().slug}
            messageId={getSpace().pin_message}
            onViewPinnedMessage={props.onViewPinnedMessage}
            useSpace2={props.useSpace2}
          />
        ) : (
          ""
        )}
        {((show && props.isMain) || props.isMain == false) &&
        props.components.indexOf("new_message") >= 0 &&
        newMessage.show ? (
          <NewMessage newMessage={newMessage} useSpace2={props.useSpace2} />
        ) : (
          ""
        )}
        {((show && props.isMain) || props.isMain == false) &&
        props.components.indexOf("calendar") >= 0 &&
        getSpace() &&
        getSpace().calendar &&
        getSpace().calendar.has_start_date ? (
          <Calendar
            spaceId={getSpace().slug}
            eventName={getSpace().name}
            spaceVersion={getSpace().version}
            eventDescription={getSpace().full_link}
            calendar={getSpace().calendar}
            canEdit={getSpace().permissions.can_edit}
            isMember={getSpace().permissions.is_member}
            useSpace2={props.useSpace2}
          />
        ) : (
          ""
        )}
        {((show && props.isMain) || props.isMain == false) &&
        props.components.indexOf("status") >= 0 &&
        getSpace() && (
          <StatusBar
            space={getSpace()}
            statusName={getSpace().pod_state}
            useSpace2={props.useSpace2}
          />
        )}
        {((show && props.isMain) || props.isMain == false) &&
        props.components.indexOf("tag") >= 0 &&
        tag ? (
          <TagFilter tagName={tag} useSpace2={props.useSpace2} />
        ) : (
          ""
        )}

        {((show && props.isMain) || props.isMain == false) &&
        props.components.indexOf("dummy") >= 0 ? (
          <DummyBar useSpace2={props.useSpace2} />
        ) : (
          ""
        )}
        {((show && props.isMain) || props.isMain == false) &&
        props.components.indexOf("redirect") >= 0 &&
        getSpace().redirect_to_space ? (
          <RedirectBar
            space={getSpace().redirect_to_space}
            useSpace2={props.useSpace2}
          />
        ) : (
          ""
        )}
        {((show && props.isMain) || props.isMain == false) &&
          props.components.indexOf("new_reflections") >= 0 && (
            <NewReflectionsBar space={getSpace()} useSpace2={props.useSpace2} />
          )}
        {((show && props.isMain) || props.isMain == false) &&
          props.components.indexOf("tags") >= 0 && (
            <TagsChips
              tagsList={props.tagsList}
              setTags={props.setTags}
              selectedTag={props.selectedTag}
              m={[5, 10, 0, 0]}
            />
          )}
      </Container>
    </Box>
  );
}
