import React from "react";
import { NodeViewWrapper, NodeViewContent } from "@tiptap/react";

import { stylesToCamelCase } from "../../../utils/StylesToString";

import TiptapInline from "./TiptapInline";

import { wideOrNarrow } from "../../../utils/PageWidth";

import { Box } from "@mantine/core";

export default function VisionInput(props) {
  return (
    <NodeViewWrapper
      className="react-component"
      style={{ maxWidth: wideOrNarrow() }}
    >
      <NodeViewContent
        contentEditable="false"
        style={{
          ...stylesToCamelCase(props.node.attrs.styles),
          textAlign: props.node.attrs.textAlign,
        }}
        className={`node-content ${props.node.attrs.class} interactive-input-wrapper`}
        radius={props.node.attrs.radius}
      >
        <Box mt={10}>
          <TiptapInline
            editable={false}
            content={""}
            modals={true}
            exclude={["formatbar"]}
            contextDb={`interactive-input-${props.node.attrs.id}`}
            withSaveButton={true}
            saveButtonText="Save"
            onSave={() => {}}
          />
        </Box>
      </NodeViewContent>
    </NodeViewWrapper>
  );
}
