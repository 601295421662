import React, { useState, useEffect, useRef } from "react";

import {
  Text,
  Box,
  Group,
  Drawer,
  Select,
  ScrollArea,
  Button,
} from "@mantine/core";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import StepModel from "../../../../models/EngagementStep";
import StepIcon from "./StepIcon";

import AddSimple from "./steps/AddSimple";
import EditMessage from "./steps/EditMessage";

import SendEmail from "./steps/SendEmail";
import AddToExistingSpace from "./steps/AddToExistingSpace";
import AddToNewSpace from "./steps/AddToNewSpace";
import AddFromSubSpace from "./steps/AddFromSubSpace";
import AddStructure from "./steps/AddStructure";
import ApplyTemplate from "./steps/ApplyTemplate";
import SetToPending from "./steps/SetToPending";
import SurveyPerson from "./steps/SurveyPerson";
import Webhook from "./steps/Webhook";
import Wait from "./steps/Wait";
import RemoveFromSpace from "./steps/RemoveFromSpace";

import PremiumModal from "../../../app/PremiumModal";

export default function AddStep(props) {
  const { t, i18n } = useTranslation();
  const [firstSelection, setFirstSelection] = useState(null);
  const [actions, setActions] = useState([]);
  const [canSetToPending, setCanSetToPending] = useState(false);
  const [canAddToExistingSpace, setCanAddToExistingSpace] = useState(false);
  const [canWait, setWait] = useState(false);
  const [canSurveyPerson, setCanSurveyPerson] = useState([]);
  const [canSendEmail, setCanSendEmail] = useState([]);
  const [canSendMessage, setCanSendMessage] = useState([]);
  const [canAddFromChildren, setCanAddFromChildren] = useState([]);
  const [canAddToDirectOpenChildren, setCanAddToDirectOpenChildren] = useState(
    []
  );
  const [canWebhook, setCanWebhook] = useState([]);
  const [opened, setOpened] = useState(false);
  const lastOpenedState = useRef(false);

  const space = useSelector((state) => state.space);

  useEffect(() => {
    if (props.functionSlug && props.functions) {
      const _function = props.functions.filter(
        (item) => item.slug == props.functionSlug
      )[0];

      if (_function && _function.actions){
        setActions(
          _function.actions.map((item) => ({
            label: item.name,
            value: item.slug,
            description: item.selection_description,
            icon: item.type,
          }))
        );
      }
    }
    if (props.selectedStep) {
      setFirstSelection(props.selectedStep.action_slug);
    } else {
      setFirstSelection();
    }
  }, [props]);

  useEffect(() => {
    setCanSetToPending(
      space &&
        space.permissions &&
        space.permissions.can_add_step_send_to_pending
    );
    setCanAddToExistingSpace(
      space &&
        space.permissions &&
        space.permissions.can_add_add_to_existing_space
    );
    setWait(space && space.permissions && space.permissions.can_add_step_wait);
    setCanSurveyPerson(
      space && space.permissions && space.permissions.can_add_step_survey_person
    );
    setCanSurveyPerson(
      space && space.permissions && space.permissions.can_add_step_survey_person
    );
    setCanSendEmail(
      space && space.permissions && space.permissions.can_add_step_send_email
    );

    setCanWebhook(
      space && space.permissions && space.permissions.can_add_step_webhook
    );

    setCanSendMessage(
      space && space.permissions && space.permissions.can_send_message
    );
    setCanAddFromChildren(
      space && space.permissions && space.permissions.can_add_from_children
    );
    setCanAddToDirectOpenChildren(
      space &&
        space.permissions &&
        space.permissions.can_add_to_direct_open_children
    );
  }, [space]);

  useEffect(() => {
    if (firstSelection == "set_to_pending" && !canSetToPending[0]) {
      setOpened([true, canSetToPending[1], canSetToPending[2]]);
      setFirstSelection(null);
    }
    if (
      firstSelection == "add_to_existing_space" &&
      !canAddToExistingSpace[0]
    ) {
      setOpened([true, canAddToExistingSpace[1], canAddToExistingSpace[2]]);
      setFirstSelection(null);
    }
    if (firstSelection == "wait" && !canWait[0]) {
      setOpened([true, canWait[1], canWait[2]]);
      setFirstSelection(null);
    }
    if (firstSelection == "survey_person" && !canSurveyPerson[0]) {
      setOpened([true, canSurveyPerson[1], canSurveyPerson[2]]);
      setFirstSelection(null);
    }
    if (firstSelection == "send_email" && !canSendEmail[0]) {
      setOpened([true, canSendEmail[1], canSendEmail[2]]);
      setFirstSelection(null);
    }
    if (firstSelection == "send_message" && !canSendMessage[0]) {
      setOpened([true, canSendMessage[1], canSendMessage[2]]);
      setFirstSelection(null);
    }
    if (firstSelection == "can_add_from_children" && !canAddFromChildren[0]) {
      setOpened([true, canAddFromChildren[1], canAddFromChildren[2]]);
      setFirstSelection(null);
    }
    if (
      firstSelection == "can_add_to_direct_open_children" &&
      !canAddToDirectOpenChildren[0]
    ) {
      setOpened([
        true,
        canAddToDirectOpenChildren[1],
        canAddToDirectOpenChildren[2],
      ]);
      setFirstSelection(null);
    }
    if (firstSelection == "webhook" && !canWebhook[0]) {
      setOpened([true, canWebhook[1], canWebhook[2]]);
      setFirstSelection(null);
    }
  }, [firstSelection]);

  const onSave = () => {
    if (props.selectedStep) {
      onUpdate();
    } else {
      onCreate();
    }
  };

  const getSelectionType = (slug) => {
    const type = actions.filter((item) => item.value == slug)[0];
    return type ? type.icon : "";
  };

  const onCreate = (params) => {
    StepModel.onCreate(
      space.slug,
      props.triggerId,
      { action_slug: firstSelection, position: props.position, params: params },
      (data) => {
        if (
          getSelectionType(firstSelection) == "send_email" &&
          params.object_id == "new_email"
        ) {
          props.setTriggerEdit(data.data.id);
        } else if (
          getSelectionType(firstSelection) == "send_message" &&
          params.object_id == "new_message"
        ) {
          props.setTriggerEdit(data.data.id);
        } else if (
          getSelectionType(firstSelection) == "add_structure" &&
          params.object_id == "new_structure"
        ) {
          props.setTriggerEdit(data.data.id);
        } else if (
          getSelectionType(firstSelection) == "survey_person" &&
          params.object_id == "new_survey"
        ) {
          props.setTriggerEdit(data.data.id);
        }
        else{
          props.onUpdate();
        }
        setTimeout(() => {
          props.setOpened(false);
        }, 200);
      },
      (data) => {}
    );
  };

  const onUpdate = () => {
    StepModel.onUpdate(
      props.selectedStep.id,
      space.slug,
      props.triggerId,
      { action_slug: firstSelection, position: props.position },
      (data) => {
        props.onUpdate();
        props.setOpened(false);
      },
      (data) => {}
    );
  };

  const onDelete = () => {
    StepModel.onDestroy(
      props.selectedStep.id,
      space.slug,
      props.triggerId,
      (data) => {
        props.onUpdate();
        props.setOpened(false);
      },
      (data) => {}
    );
  };

  const SelectItem = ({ label, description, ...others }) => (
    <div {...others}>
      <Group noWrap>
        <StepIcon icon={others.icon} />
        <div>
          <Text size="lg" weight={600}>
            {label}
          </Text>
          <Text size="md" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  );

  const displaySecondSelection = () => {
    switch (getSelectionType(firstSelection)) {
      case "send_email":
        return (
          <SendEmail
            onCreate={onCreate}
            mode="add"
            trigger={props.trigger}
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "send_message":
        return (
          <EditMessage
            onCreate={onCreate}
            mode="add"
            trigger={props.trigger}
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "add_to_existing_space":
        return (
          <AddToExistingSpace
            onCreate={onCreate}
            mode="add"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "add_to_new_space":
        return (
          <AddToNewSpace
            onCreate={onCreate}
            mode="add"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "add_from_sub_space":
        return (
          <AddFromSubSpace
            onCreate={onCreate}
            mode="add"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "add_structure":
        return (
          <AddStructure
            onCreate={onCreate}
            mode="add"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "apply_template":
        return (
          <ApplyTemplate
            onCreate={onCreate}
            mode="add"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "set_to_pending":
        return (
          <SetToPending
            onCreate={onCreate}
            mode="add"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "survey_person":
        return (
          <SurveyPerson
            onCreate={onCreate}
            mode="add"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "webhook":
        return (
          <Webhook
            onCreate={onCreate}
            mode="add"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "wait":
        return (
          <Wait
            onCreate={onCreate}
            mode="add"
            settings={props.settings}
            onSettingsChange={props.onSettingsChange}
          />
        );
        break;
      case "remove_from_space":
        return (
          <RemoveFromSpace
            onCreate={onCreate}
            mode="remove"
          />
        );
        break;
      default:
        return (
          firstSelection && (
            <AddSimple
              onCreate={onCreate}
              mode="add"
              settings={props.settings}
              onSettingsChange={props.onSettingsChange}
            />
          )
        );
        break;
    }
  };

  return (
    <>
      <Drawer
        opened={props.opened}
        onClose={() => {
          props.setOpened(false);
          setFirstSelection(null);
        }}
        title={
          <Text size="xl" weight="800">
            {!props.selectedStep
              ? t("spaces.settings.registration.addStep.addStep.description")
              : props.selectedStep.action}
          </Text>
        }
        padding="xl"
        size={600}
        offsetScrollbars
      >
        <ScrollArea style={{ height: "100%" }}>
          <Select
            label={t("spaces.settings.registration.addStep.addStep.label")}
            value={firstSelection}
            onChange={setFirstSelection}
            placeholder={t(
              "spaces.settings.registration.addStep.addStep.placeholder"
            )}
            itemComponent={SelectItem}
            data={actions}
            maxDropdownHeight={400}
            radius="sm"
            size="md"
          />
          {displaySecondSelection()}
          {!props.functionsType && (
            <Group position="apart" mt={20}>
              <Button radius={"xl"} onClick={() => onSave()}>
                {"Save"}
              </Button>
              {props.selectedStep && props.selectedStep.id && (
                <Button radius={"xl"} color={"red"} onClick={() => onDelete()}>
                  {"Delete"}
                </Button>
              )}
            </Group>
          )}
          <Box mt={100} />
        </ScrollArea>
      </Drawer>
      <PremiumModal opened={opened} setOpened={setOpened} />
    </>
  );
}
