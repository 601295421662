import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  MantineProvider,
  Select,
  Button,
  Group,
  Loader
} from '@mantine/core'

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'

import { useForm } from '@mantine/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import SpaceModel from '../../../../../models/Space'

const groupOrder = {
  'Current space': 1,
  'Sub spaces': 2,
  'All spaces': 3
};

export default function RemoveFromSpace(props) {

  const space = useSelector(state => state.space);
  const [spaces, setSpaces] = useState([]);

  const [searching, setSearching] = useState(false)
  const { t, i18n } = useTranslation();
  const searchTimeoutId = useRef();
  const selectedParents = useRef([]);
  const auxSpaces = useRef([])

  const sortedSpaces = spaces.sort((a, b) => {
    return groupOrder[a.group] - groupOrder[b.group];
  });

  const form = useForm({
    initialValues: {
      object_type: "pod",
      object_id: ""
    },

    validate: {
      object_id: (value) => value.length > 0 ? null : "Please select a space",
    }
  });

  useEffect(() => {
    onFetchSpaces()
    if (props.mode === "edit" && props?.step?.object_id) {
      form.setFieldValue("object_id", `${props.step.object_id}`)
    }
  }, [props])

  const onFetchSpaces = (query=null) => {
    auxSpaces.current = [
      { value: `${space.id}`, label: `${space.name} ${space.slug}`, group: "Current space" }
    ];

    const include = ["members", "simple"]
    if (query){
      include.push(`q=${query}`)
    }

    SpaceModel.onFetchDeepChildren(space.slug, include, {page: 1, per: 10}, ["active"], (data) => {
      for (let i = 0; i < data.data.length; i++) {
        auxSpaces.current.push({value: `${data.data[i].id}`, label: `${data.data[i].name} (${data.data[i].slug})`, group: "Sub spaces"})
      }

      setSpaces([...auxSpaces.current])

    }, (data) => {

    })

    SpaceModel.onFetchManaged([], include, {page: 1, per: 10}, (data) => {

      for (let i = 0; i < data.length; i++) {
        var index = auxSpaces.current.findIndex(x => x.value==data[i].id);
        index === -1 && (
          auxSpaces.current.push({value: `${data[i].id}`, label: `${data[i].name} (${data[i].slug})`, group: "All spaces"})
        )
      }

      setSpaces([...auxSpaces.current])

    }, (data) => {

    })

  }

  const onSend = () => {
    if (props.mode === "edit") {
      props.onUpdate(form.values)
    } else {
      console.log("form.values 22: ", form.values)
      props.onCreate(form.values)
    }
  }

  const onSearchSpaces = (query) => {
    setSearching(true)
    if (searchTimeoutId.current){
      clearTimeout(searchTimeoutId.current)
    }
    searchTimeoutId.current = setTimeout(() => {
      onFetchSpaces(query)
      setSearching(false)
    }, 1000)
  }

  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => onSend(values))}>
        <MantineProvider theme={{ primaryShade: 6 }} >
          <Select
            icon={searching ? <Loader size="xs" /> : <FontAwesomeIcon icon={solid('search')} />}
            label={t("spaces.settings.registration.steps.remove_from_space.label")}
            searchable
            mt={20}
            data={sortedSpaces}
            placeholder={t("spaces.settings.registration.steps.remove_from_space.placeholder")}
            {...form.getInputProps('object_id')}
            maxDropdownHeight={400}
            radius="sm"
            size="md"
            onSearchChange={(query) => onSearchSpaces(query)}
          />
        </MantineProvider>

        {props.mode === "edit" ? (
          <Group position="apart">
            <Button
              mt={25}
              radius="xl"
              type="submit"
              leftIcon={<FontAwesomeIcon icon={solid('check')} />}
            >
              {t("spaces.settings.registration.steps.remove_from_space.saveButton")}
            </Button>
            <Button
              onClick={(event) => props.onDestroy(event)}
              mt={25}
              radius="xl"
              color="dark"
              leftIcon={<FontAwesomeIcon icon={regular('trash-can')} />}
            >
              {t("spaces.settings.registration.steps.remove_from_space.deleteButton")}
            </Button>
          </Group>
        ) : (
          <Button
            type="submit"
            leftIcon={<FontAwesomeIcon icon={solid('check')} />}
            mt={25}
            radius="xl"
            fullWidth
          >
            {t("spaces.settings.registration.steps.remove_from_space.saveButton2")}
          </Button>
        )}
      </form>
    </Box>
  )
}
