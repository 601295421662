import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  Box,
  Text,
  Tooltip,
  NumberInput,
  Group,
  Badge,
  Button,
  Drawer,
  ScrollArea,
  createStyles,
} from "@mantine/core";

import { DatePicker, TimeInput } from "@mantine/dates";

import BundlesForm from "./BundlesForm";
import SectionHeader from "../layout/ActionsSectionHeader";
import PremiumModal from "../../../app/PremiumModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { sutraTheme } from "../../../../collections/sutraTheme";

import BundleModel from "../../../../models/Bundle";

import { colors } from "../../../../collections/colors";
import Swal from "sweetalert2";

import getSymbolFromCurrency from "currency-symbol-map";

import { CopyToClipboard } from "react-copy-to-clipboard";

const useStyles = createStyles((theme) => ({
  item: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  itemTitle: {
    fontSize: 18,
  },

  itemDescription: {
    fontSize: 14,
    color: theme.colors.gray[6],
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },

  subOptionWrap: {
    marginTop: 15,
    backgroundColor: theme.colors.gray[0],
    padding: 15,
    borderRadius: 5,
  },

  subOptionItem: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `6px ${theme.spacing.xs}px`,
    borderRadius: theme.radius.sm,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.white,

    "&:focus-within": {
      borderColor: theme.colors[theme.primaryColor][6],
    },
  },

  control: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,

    "&:disabled": {
      borderColor:
        theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3],
      opacity: 0.8,
      backgroundColor: "transparent",
    },
  },

  input: {
    width: "100%",
  },

  trashStyle: {
    cursor: "pointer",
    // backgroundColor: theme.colors.gray[6],
    color: theme.colors.gray[6],
    "&:hover": {
      color: theme.colors.gray[7],
      // backgroundColor: theme.colors.gray[7],
    },
  },
}));

export default function Bundles(props) {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();

  const [opened, setOpened] = useState(false);
  const [bundle, setBundle] = useState({});
  const [bundles, setBundles] = useState([]);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [validateOpened, setValidateOpened] = useState(false);
  const [validatePremium, setValidatePremium] = useState(false);

  const space = useSelector((state) => state.space);

  useEffect(() => {
    onFetch();
  }, [props]);

  useEffect(() => {
    setValidatePremium(
      space && space.permissions && space.permissions.can_create_bundles
    );
  }, [space]);

  const onFetch = () => {
    BundleModel.onFetch(
      space.slug,
      (data) => {
        setBundles([...data]);
      },
      (data) => {}
    );
  };

  const onCreate = () => {
    setBundle({});
    setOpened(true);
  };

  const onUpdate = (id) => {
    onFetch();
    setOpened(false);
  };

  const onEdit = (item) => {
    setBundle(item);
    setOpened(true);
  };

  const onCopy = (item) => {
    setCopiedToClipboard(item.id);
    setTimeout(() => {
      setCopiedToClipboard(false);
    }, 2000);
  };

  const onDestroy = (id) => {
    Swal.fire({
      title: "Are you sure?",
      html: "This bundle will be inactive for future uses.",
      showDenyButton: true,
      confirmButtonText: "Yes, delete",
      denyButtonText: "No",
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {
        BundleModel.onDestroy(
          space.id,
          id,
          (data) => {
            onFetch();
          },
          (data) => {}
        );
      } else if (result.isDenied) {
      }
    });
  };

  return (
    <>
      <Button
        mb={20}
        variant="filled"
        radius="xl"
        onClick={() => {
          if (validatePremium && !validatePremium[0]) {
            setValidateOpened([true, validatePremium[1], validatePremium[2]]);
          } else {
            onCreate();
          }
        }}
        leftIcon={<FontAwesomeIcon icon={solid("cubes")} />}
        sx={{
          backgroundColor: sutraTheme.colors.sutrablue[3],
          "&:hover": {
            backgroundColor: sutraTheme.colors.sutrablue[4],
          },
        }}
      >
        {"Create bundle"}
      </Button>
      <Box>
        {bundles.map((item, index) => {
          return (
            <Group
              key={index}
              sx={{
                borderBottom: "1px solid #eee",
                paddingBottom: "20px",
                marginBottom: "20px",
                alignItems: "flex-start",
              }}
              position="apart"
            >
              <Box>
                <Group>
                  <Box>
                    <Text size="xl" mb={3} weight={"bold"}>
                      {item.name}
                    </Text>
                    <Text Text color="dimmed">{`${getSymbolFromCurrency(
                      item.currency
                    )}${item.default_payment_amount} ${
                      item.default_payment_frequency
                    }`}</Text>
                  </Box>
                </Group>
              </Box>
              <Group spacing={5}>
                <Tooltip
                  label="Link copied to clipboard"
                  opened={copiedToClipboard === item.id}
                  position="bottom"
                  withArrow
                >
                  <CopyToClipboard onCopy={() => onCopy(item)} text={`https://sutra.co/space/${space.slug}/gateway?bundles=${item.id}`}>
                    <Box
                      ml={10}
                      mt={10}
                      className={classes.trashStyle}
                    >
                      <FontAwesomeIcon icon={solid("link")} />
                    </Box>
                  </CopyToClipboard>
                </Tooltip>
                <Box
                  ml={10}
                  mt={10}
                  className={classes.trashStyle}
                  onClick={(event) => onEdit(item)}
                >
                  <FontAwesomeIcon icon={solid("pencil")} />
                </Box>
                <Box
                  ml={10}
                  mt={10}
                  className={classes.trashStyle}
                  onClick={(event) => onDestroy(item.id)}
                >
                  <FontAwesomeIcon icon={solid("trash")} />
                </Box>
              </Group>
            </Group>
          );
        })}
      </Box>
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        title={
          <Text size="xl" weight="800">
            {bundle ? "New bundle" : "Edit bundle"}
          </Text>
        }
        padding="xl"
        size={600}
      >
        <ScrollArea style={{ height: "100%" }}>
          <BundlesForm
            opened={opened}
            setOpened={setOpened}
            bundle={bundle}
            spaceId={space.id}
            onUpdate={onUpdate}
          />
        </ScrollArea>
      </Drawer>
      <PremiumModal opened={validateOpened} setOpened={setValidateOpened} />
    </>
  );
}
