import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import PaymentMethod from "../payment/PaymentMethod";
import History from "../payment/History";
import TreeView from "../TreeView";

import {
  Group,
  Button,
  Drawer,
  Text,
  Popover,
  MantineProvider,
  AvatarsGroup,
  Avatar,
  createStyles,
  Modal,
  Box,
  Loader,
  Accordion,
  Tooltip,
} from "@mantine/core";

import { useMediaQuery } from "@mantine/hooks";

import { setActions, setTiptapSidemenu, setSpace } from "../../../store/app";
import SpaceModel from "../../../models/Space";
import CompletionModel from "../../../models/Completion";
import HocuspocusModel from "../../../models/Hocuspocus";
import DefaultAvatar from "../../../src/images/fallback/default.png";


import DuplicateForm from "../form/DuplicateForm";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../../utils/IsSpaceThemed";
import useSidebarStyles from "../../../utils/SidebarStyles";
import defaultSpaceColors from "../../../collections/defaultSpaceColors";

import PremiumModal from "../../app/PremiumModal";

import Swal from "sweetalert2";

import Broadcaster from "../../../helpers/Broadcaster";

import { getCookie } from "../../../utils/Cookies";

import InvitePeople from "../settings/members/InvitePeople";

import { useTranslation } from "react-i18next";

import trackMixpanel from "../../../utils/TrackMixpanel";

const useStyles = createStyles((theme, { colors, settingsView }) => ({
  primaryButtonStyle: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
  secondaryButtonStyle: {
    backgroundColor: colors.secondary_button_background_color,
    color: colors.secondary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.secondary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
  buttonCompleted: {
    backgroundColor: theme.colors.green[6],
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.colors.green[7],
    },
  },
  separatorRight: {
    borderRight: `1px solid ${
      !isSpaceThemed(colors)
        ? tinycolor(colors.sidebar_background_color).darken(5).toString()
        : tinycolor(colors.header_color).darken(10).toString()
    }`,
    height: "100%",
  },
  appearanceButtonRoot: {
    color: theme.colors.gray[7],
    fontWeight: 400,
    fontSize: 16,
    borderRadius: 5,
    display: "block",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
    paddingLeft: 10,
  },
  appearanceButtonLeftIcon: {
    color: theme.colors.gray[7],
    width: 20,
    display: "flex",
    justifyContent: "center",
  },
  appearanceButtonInner: {
    justifyContent: "flex-start",
  },
}));

export default function MainActions(props) {
  const { t, i18n } = useTranslation();
  const { settingsView, registrationView } = useSidebarStyles();
  let colors = useSelector((state) => state.colors);
  colors = !settingsView && !registrationView ? colors : defaultSpaceColors;
  const { classes, cx, theme } = useStyles({ colors: colors, settingsView });

  const mainActions = useSelector((state) => state.mainActions);
  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);
  const spaceTree = useSelector((state) => state.tree);
  const space = useSelector((state) => state.space);
  const user = useSelector((state) => state.user);
  const tiptap = useSelector((state) => state.tiptap);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const mediumScreen = useMediaQuery(
    `(min-width: ${theme.other.breakpoints.md}px)`
  );
  const largeScreen = useMediaQuery(
    `(min-width: ${theme.other.breakpoints.lg}px)`
  );
  const extraLargeScreen = useMediaQuery(
    `(min-width: ${theme.other.breakpoints.xl}px)`
  );

  const [editPage, setEditPage] = useState(false);
  const [editRegPage, setEditRegPage] = useState(false);
  const [editButtonDisabled, setEditButtonDisabled] = useState(true);
  const [openedSettings, setOpenedSettings] = useState(false);
  const [openedMembers, setOpenedMembers] = useState(false);
  const [openedColors, setOpenedColors] = useState(false);
  const [openedMoreOptions, setOpenedMoreOptions] = useState(false);
  const [openedMoreOptions2, setOpenedMoreOptions2] = useState(false);
  const [openedDuplicate, setOpenedDuplicate] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [loadingModalOpened, setLoadingModalOpened] = useState(false);
  const [deletingModalOpened, setDeletingModalOpened] = useState(false);
  const [treeModalOpened, setTreeModalOpened] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [createTemplate, setCreateTemplate] = useState(false);
  const [openedPaymentMethod, setOpenedPaymentMethod] = useState(false);
  const [validatePremium, setValidatePremium] = useState(false);
  const [validatePremium2, setValidatePremium2] = useState(false);
  const [openedPremium, setOpenedPremium] = useState(false);
  const [treeViewSelectedNode, setTreeViewSelectedNode] = useState({});
  const [inviteOpened, setInviteOpened] = useState(false);

  const onTriggerAction = (event, action) => {
    if (
      action.editPage == false &&
      location.pathname.indexOf("/content") >= 0
    ) {
      if (
        window.$tiptapNeedSaving &&
        window.$tiptapNeedSaving.status == false
      ) {
        dispatch(setActions(action));
      } else if (window.$tiptapNeedSaving) {
        setEditButtonDisabled(true);
        window.$tiptapNeedSaving.leave = true;

        setTimeout(() => {
          if (
            window.$tiptapNeedSaving &&
            window.$tiptapNeedSaving.leave == true
          ) {
            dispatch(setActions({ editPage: false }));
          }
        }, 3000);
      }
    } else if (action.manage == true && editPage == true) {
      dispatch(setActions({ editPage: false }));
      setTimeout(() => {
        dispatch(setActions({ manage: true }));
      }, 1000);
    } else {
      if (action.editPage === true) {
        trackMixpanel(user.username, "Edit space clicked", { space_slug: space.slug });
      }
      dispatch(setActions(action));
    }
  };

  const onPublishPage = () => {
    // ALERT?

    setLoadingModalOpened(true);
    setTimeout(() => {
      HocuspocusModel.onPublish(
        `circle-editable-registration-page-${space.slug}`,
        (data) => {
          setLoadingModalOpened(false);
          Swal.fire(
            "Page published",
            "The changes you made are now live!",
            "success"
          );
        },
        (data) => {
          setLoadingModalOpened(false);
          Swal.fire("Opps", "Something went wrong", "error");
        }
      );
    }, 4000);
  };

  useEffect(() => {
    return () => {
      onTriggerAction(null, {
        manage: false,
        editPage: false,
        editRegPage: false,
      });
    };
  }, []);

  useEffect(() => {
    setRegistered(getCookie(`interested_${space.slug}`));
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (createTemplate) {
      setOpenedDuplicate(true);
    }
  }, [createTemplate]);

  useEffect(() => {
    if (!openedDuplicate) {
      setCreateTemplate(false);
    }
  }, [openedDuplicate]);

  useEffect(() => {
    if (location.pathname.indexOf("/settings") >= 0) {
      setOpenedSettings(true);
    } else {
      setOpenedSettings(false);
    }
  }, [location]);

  useEffect(() => {
    if (mainActions) {
      if (mainActions.editPage != editPage) {
        setEditButtonDisabled(false);
        window.$spaceEditPage = editPage;
        setEditPage(mainActions.editPage);
        if (location.pathname.indexOf("/getting_started") >= 0) {
          navigate(`/space/${space.slug}/${space.version}`);
        }
      }
      if (mainActions.editRegPage != editRegPage) {
        setEditRegPage(mainActions.editRegPage);
      }

      if (mainActions.manage != openedSettings) {
        setOpenedSettings(mainActions.manage);
        if (mainActions.manage) {
          if (window.$currentRole === "moderator" || window.$currentRole === "editor") {
            navigate("settings/participation");
          } else {
            navigate("settings/main");
          }
        } else {
          navigate(space.version);
        }
      }
    }
  }, [mainActions]);

  useEffect(() => {
    if (location.pathname.indexOf("/members") >= 0) {
      setOpenedMembers(true);
    } else {
      setOpenedMembers(false);
    }
    // setEditButtonDisabled(true)
  }, [location]);

  useEffect(() => {
    dispatch(setTiptapSidemenu({ opened: "" }));
  }, [openedColors]);

  useEffect(() => {
    setCompleted(space.completed);
    setTimeout(() => {
      if (editButtonDisabled) {
        setEditButtonDisabled(false);
      }
    }, 3000);

    setValidatePremium(
      space && space.permissions && space.permissions.can_save_templates
    );
    setValidatePremium2(
      space && space.permissions && space.permissions.can_duplicate_spaces
    );
  }, [space]);

  useEffect(() => {
    const show_payments = new URLSearchParams(location.search).get("show_payments");
    if (show_payments && space && window.$userIsSubscribed) {
      setTimeout(() => {
        setOpenedPaymentMethod(true);
        window.history.replaceState(null, null, location.pathname);
      }, 1000);
    }
  }, [space]);

  const onMembers = () => {
    navigate("members");
  };

  const onReturnToSpace = () => {
    navigate(`/space/${space.slug}/${space.version}?refresh=true`);
  };

  const onReturnToSettings = () => {
    navigate(`/space/${space.slug}/settings/main`);
  };

  const onOpenRegPageColors = () => {
    dispatch(setTiptapSidemenu({ opened: "regPageColors" }));
  };

  const onJoin = () => {
    if (!user.authenticated) {
      if (space.calendar.allow_interested_on_join) {
        dispatch(setTiptapSidemenu({ opened: "interestedform" }));
        return;
      }

      localStorage.setItem("join_slug", space.slug);
      navigate(`/user/start?s=${space.slug}`);
      return;
    }

    SpaceModel.onJoin(
      space.slug,
      (data) => {
        navigate(`${data.redirect_to.url}`);
      },
      (data) => {}
    );
  };

  const onLeave = () => {
    Swal.fire({
      title: t("spaces.header.onLeave.title"),
      html: t("spaces.header.onLeave.message"),
      showDenyButton: true,
      confirmButtonText: t("spaces.header.onLeave.confirm"),
      denyButtonText: t("spaces.header.onLeave.deny"),
      confirmButtonColor: "#ff0000",
      denyButtonColor: "#666",
    }).then((result) => {
      if (result.isConfirmed) {
        SpaceModel.onLeave(
          space.slug,
          (data) => {
            // navigate(`/home/top`);
          },
          (data) => {}
        );
      } else if (result.isDenied) {
      }
    });
  };

  const onDuplicateSpace = (id, link) => {
    try {
      if (!validatePremium2[0]) {
        setOpenedPremium([true, validatePremium2[1], validatePremium2[2]]);
        return;
      }
    } catch {}
    setOpenedDuplicate(true);
  };

  const onArchive = () => {
    Swal.fire({
      title: t("spaces.header.onArchive.title"),
      html: t("spaces.header.onArchive.message"),
      showDenyButton: true,
      confirmButtonText: t("spaces.header.onArchive.confirm"),
      denyButtonText: t("spaces.header.onArchive.deny"),
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {
        setDeletingModalOpened(true);
        SpaceModel.onArchive(
          space.slug,
          (data) => {
            setTimeout(() => {
              setDeletingModalOpened(false);
              navigate(data.redirect_to);
            }, 3000);
          },
          (data) => {
            setTimeout(() => {
              setDeletingModalOpened(false);
              window.location.reload();
            }, 3000);
          }
        );
      } else if (result.isDenied) {
      }
    });
  };

  const onUnarchive = () => {
    Swal.fire({
      title: t("spaces.header.onUnarchive.title"),
      html: t("spaces.header.onUnarchive.message"),
      showDenyButton: true,
      confirmButtonText: t("spaces.header.onUnarchive.confirm"),
      denyButtonText: t("spaces.header.onUnarchive.deny"),
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {
        SpaceModel.onUnarchive(
          space.slug,
          (data) => {
            // navigate(data.redirect_to);
          },
          (data) => {}
        );
      } else if (result.isDenied) {
      }
    });
  };

  const onDelete = () => {
    Swal.fire({
      title: t("spaces.header.onDelete.title"),
      html: t("spaces.header.onDelete.message"),
      showDenyButton: true,
      confirmButtonText: t("spaces.header.onDelete.confirm"),
      denyButtonText: t("spaces.header.onDelete.deny"),
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {
        setDeletingModalOpened(true);
        SpaceModel.onDestroy(
          space.slug,
          (data) => {
            setTimeout(() => {
              setDeletingModalOpened(false);
              navigate(data.redirect_to);
            }, 3000);
          },
          (data) => {
            setTimeout(() => {
              setDeletingModalOpened(false);
              window.location.reload();
            }, 3000);
          }
        );
      } else if (result.isDenied) {
      }
    });
  };

  const onMarkAsCompleted = (_completed) => {
    setCompleted(_completed);

    CompletionModel.onCreate(
      space.slug,
      (data) => {
        Broadcaster.send(
          "change_sidebarTreeNode",
          document.getElementById(`sidebar_tree_node-${space.slug}`),
          { shouldUpdateTopTree: true }
        );

        dispatch(
          setSpace({ ...space, completed: data.completions.length > 0 })
        );
      },
      (data) => {}
    );
  };

  const setCompletionMarks = (_tree, marks) => {
    if (_tree && _tree.children && _tree.children.length > 0) {
      for (var i = 0; i < _tree.children.length; i++) {
        const child = _tree.children[i];
        if (child) {
          let found = false;
          for (var j = 0; j < marks.length; j++) {
            const mark = marks[j];

            if (mark[0] == child.pod_id) {
              child.completed = true;
              found = true;
              marks.splice(j, 1);
              break;
            }
          }

          if (!found) {
            child.completed = false;
          }

          setCompletionMarks(child, marks);
        }
      }
    }
  };

  const onOpenPreview = (preview) => {
    if (preview == "published") {
      window.open(`/space/${space.slug}/register?preview=true`, `_blank`);
      return;
    }
    if (preview == "draft") {
      window.open(`/space/${space.slug}/registration/view`, `_blank`);
      return;
    }
  };

  const onVersionControl = () => {
    dispatch(setTiptapSidemenu({ opened: "versionControl" }));
  };

  const onFix = () => {
    Swal.fire({
      title: t("spaces.header.onFix.title"),
      html: t("spaces.header.onFix.message"),
      showDenyButton: true,
      confirmButtonText: t("spaces.header.onFix.confirm"),
      denyButtonText: t("spaces.header.onFix.deny"),
      confirmButtonColor: "#ff0000",
      denyButtonColor: "#666",
    }).then((result) => {
      if (result.isConfirmed) {
        SpaceModel.onFix(
          space.slug,
          (data) => {
            if (data.fixed) {
              Swal.fire(
                t("spaces.header.onFix.confirmationTitle"),
                t("spaces.header.onFix.confirmationMessage"),
                "success"
              );
              window.location.reload();
            } else {
              Swal.fire(
                t("spaces.header.onFix.confirmationTitle"),
                t("spaces.header.onFix.confirmationError"),
                "info"
              );
            }
          },
          (data) => {}
        );
      } else if (result.isDenied) {
      }
    });
  };

  const onTiptapUndo = () => {
    tiptap.editor.commands.undo();
  };

  const showHeaderSeparator = (auth = true) => {
    if (auth) {
      return (
        props.authenticated &&
        location &&
        location.pathname.indexOf("/registration") < 0
      );
    } else {
      return location && location.pathname.indexOf("/registration") < 0;
    }
  };

  const onCreateTemplate = () => {
    try {
      if (!validatePremium[0]) {
        setOpenedPremium([true, validatePremium[1], validatePremium[2]]);
        return;
      }
    } catch {}
    setCreateTemplate(true);
  };

  const onDuplicate = (id, link) => {
    setOpenedDuplicate(false);
    onTriggerAction(event, { editPage: false });
    navigate(link);
  };

  const onGoToSpace = () => {
    navigate(
      `/space/${treeViewSelectedNode.slug}/${treeViewSelectedNode.version}`
    );
    setOpened(false);
  };

  const onOpenTutorial = (event) => {
    if (space.pod_state == "model") {
      return;
    }
    trackMixpanel(user.username, "Tutorial Opened");
    navigate(`/space/${space.slug}/getting_started`);
  };

  return (
    <>
      {!openedSettings &&
      !openedMembers &&
      location.pathname.indexOf("/demo") < 0 &&
      location.pathname.indexOf("/space") >= 0 &&
      (location.pathname.indexOf(`/${space.version}`) >= 0 ||
        location.pathname.indexOf("/pending") >= 0 ||
        location.pathname.indexOf("/survey") >= 0 ||
        location.pathname.indexOf("/processing") >= 0 ||
        location.pathname.indexOf("/getting_started") >= 0) ? (
        <>
          <Group
            position="right"
            spacing="xs"
            pr={showHeaderSeparator() ? 15 : 0}
            mr={showHeaderSeparator() ? 15 : 10}
            className={showHeaderSeparator() ? classes.separatorRight : ""}
            noWrap
          >
            {largeScreen &&
              space &&
              space.permissions &&
              !space.is_post &&
              location.pathname.indexOf(`${space.version}`) >= 0 &&
              space.permissions.allow_completions &&
              space.permissions.show_mark_complete_button_in_header &&
              !space.permissions.is_top_level &&
              user.authenticated &&
              !editPage && (
                <Button
                  className={cx(classes.primaryButtonStyle, {
                    [classes.buttonCompleted]: completed,
                  })}
                  leftIcon={<FontAwesomeIcon icon={solid("circle-check")} />}
                  radius="xl"
                  onClick={() => onMarkAsCompleted(!completed)}
                >
                  {completed
                    ? t("spaces.header.buttons.uncomplete")
                    : t("spaces.header.buttons.markComplete")}
                </Button>
              )}

            {(mediumScreen || largeScreen) &&
              space &&
              space.permissions &&
              space.permissions.can_edit &&
              location.pathname.indexOf("/content") >= 0 &&
              editPage && (
                <Tooltip
                  disabled={window.$isTouchDevice && window.$isTouchDevice()}
                  label={`Undo your last edit`}
                  withArrow
                >
                  <Button
                    className={classes.secondaryButtonStyle}
                    leftIcon={<FontAwesomeIcon icon={solid("rotate-left")} />}
                    onClick={(event) => onTiptapUndo()}
                    radius="xl"
                  >
                    {t("spaces.header.buttons.undo")}
                  </Button>
                </Tooltip>
              )}

            {(mediumScreen || largeScreen) &&
              space &&
              space.permissions &&
              space.permissions.can_edit &&
              location.pathname.indexOf("/content") >= 0 &&
              window.$spaceUserInteractions &&
              window.$spaceUserInteractions.can_manage && (
                <Tooltip
                  disabled={window.$isTouchDevice && window.$isTouchDevice()}
                  label={`Preview as a participant`}
                  withArrow
                >
                  <Button
                    className={classes.secondaryButtonStyle}
                    leftIcon={<FontAwesomeIcon icon={solid("eye")} />}
                    onClick={(event) =>
                      window.open(
                        `/space/${space.slug}/${space.version}?preview_ij=true`,
                        "_blank"
                      )
                    }
                    radius="xl"
                  >
                    {`Preview`}
                  </Button>
                </Tooltip>
              )}

            {mediumScreen &&
              space.version == "content" &&
              space &&
              space.permissions &&
              (space.permissions.can_edit || (space.permissions.can_moderate && space.is_post)) &&
              (location.pathname.indexOf("/content") >= 0 ||
                location.pathname.indexOf("/getting_started") >= 0) && (
                <>
                  {!editPage ? (
                    <Tooltip
                      disabled={
                        window.$isTouchDevice && window.$isTouchDevice()
                      }
                      label={`Switch into edit mode`}
                      withArrow
                    >
                      <Button
                        className={classes.primaryButtonStyle}
                        leftIcon={<FontAwesomeIcon icon={solid("edit")} />}
                        onClick={(event) =>
                          onTriggerAction(event, { editPage: true })
                        }
                        radius="xl"
                        // disabled={editButtonDisabled}
                      >
                        {t("spaces.header.buttons.edit")}{" "}
                        {`${
                          space.is_post
                            ? t("spaces.header.buttons.post")
                            : t("spaces.header.buttons.space")
                        }`}
                      </Button>
                    </Tooltip>
                  ) : (
                    <MantineProvider theme={{ primaryShade: 5 }}>
                      <Tooltip
                        disabled={
                          window.$isTouchDevice && window.$isTouchDevice()
                        }
                        label={`Switch to view mode`}
                        withArrow
                      >
                        <Button
                          color="green"
                          leftIcon={
                            <FontAwesomeIcon icon={solid("arrow-left")} />
                          }
                          onClick={(event) =>
                            onTriggerAction(event, { editPage: false })
                          }
                          radius="xl"
                          disabled={editButtonDisabled}
                        >
                          {t("spaces.header.buttons.viewPage")}
                        </Button>
                      </Tooltip>
                    </MantineProvider>
                  )}
                </>
              )}
            {mediumScreen &&
              space.version != "content" &&
              space &&
              space.permissions &&
              space.permissions.can_edit &&
              space.pod_type != "user_message" &&
              location.pathname.indexOf("/discussion") >= 0 && (
                <>
                  {!editPage ? (
                    <Tooltip
                      disabled={
                        window.$isTouchDevice && window.$isTouchDevice()
                      }
                      label={`Switch into edit mode`}
                      withArrow
                    >
                      <Button
                        className={classes.primaryButtonStyle}
                        leftIcon={<FontAwesomeIcon icon={solid("edit")} />}
                        onClick={(event) =>
                          onTriggerAction(event, { editPage: true })
                        }
                        radius="xl"
                      >
                        {t("spaces.header.buttons.editTitle")}
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      disabled={
                        window.$isTouchDevice && window.$isTouchDevice()
                      }
                      label={`Switch to view mode`}
                      withArrow
                    >
                      <MantineProvider theme={{ primaryShade: 5 }}>
                        <Button
                          color="green"
                          leftIcon={<FontAwesomeIcon icon={solid("edit")} />}
                          onClick={(event) =>
                            onTriggerAction(event, { editPage: false })
                          }
                          radius="xl"
                        >
                          {t("spaces.header.buttons.viewPage")}
                        </Button>
                      </MantineProvider>
                    </Tooltip>
                  )}
                </>
              )}

            {mediumScreen &&
              space.version != "content" &&
              space &&
              space.permissions &&
              space.permissions.can_manage &&
              (location.pathname.indexOf("/list") >= 0 ||
                location.pathname.indexOf("/events") >= 0 ||
                location.pathname.indexOf("/showcase") >= 0) && (
                <>
                  {!editPage ? (
                    <Tooltip
                      disabled={
                        window.$isTouchDevice && window.$isTouchDevice()
                      }
                      label={`Switch into edit mode`}
                      withArrow
                    >
                      <Button
                        className={classes.primaryButtonStyle}
                        leftIcon={<FontAwesomeIcon icon={solid("edit")} />}
                        onClick={(event) =>
                          onTriggerAction(event, { editPage: true })
                        }
                        radius="xl"
                      >
                        {t("spaces.header.buttons.editDescription")}
                      </Button>
                    </Tooltip>
                  ) : (
                    <MantineProvider theme={{ primaryShade: 5 }}>
                      <Tooltip
                        disabled={
                          window.$isTouchDevice && window.$isTouchDevice()
                        }
                        label={`Switch to view mode`}
                        withArrow
                      >
                        <Button
                          color="green"
                          leftIcon={<FontAwesomeIcon icon={solid("edit")} />}
                          onClick={(event) =>
                            onTriggerAction(event, { editPage: false })
                          }
                          radius="xl"
                        >
                          {t("spaces.header.buttons.viewPage")}
                        </Button>
                      </Tooltip>
                    </MantineProvider>
                  )}
                </>
              )}

            {mediumScreen &&
              space &&
              space.permissions &&
              (space.permissions.can_manage || space.permissions.can_moderate) &&
              !space.is_post &&
              // space.pod_state != "model" && // Removed because manage button was needed here
              (location.pathname.indexOf(`${space.version}`) >= 0 ||
                location.pathname.indexOf("/getting_started") >= 0) && (
                <Tooltip
                  disabled={window.$isTouchDevice && window.$isTouchDevice()}
                  label={`Privacy, payments, automation, and more`}
                  withArrow
                  width={200}
                  wrapLines
                  styles={{
                    body: { textAlign: "center" },
                  }}
                >
                  <Button
                    className={classes.primaryButtonStyle}
                    leftIcon={<FontAwesomeIcon icon={solid("cog")} />}
                    onClick={(event) =>
                      onTriggerAction(event, { manage: true })
                    }
                    radius="xl"
                  >
                    {t("spaces.header.buttons.manage")}
                  </Button>
                </Tooltip>
              )}

            {space &&
            space.permissions &&
            space.pod_type != "user_message" &&
            (space.permissions.is_member ||
              space.permissions.is_pending_member ||
              space.permissions.is_registration_survey_member ||
              space.permissions.can_manage ||
              space.permissions.is_admin) ? (
              <Popover
                opened={openedMoreOptions}
                onClose={() => setOpenedMoreOptions(false)}
                width={200}
                position="bottom"
                placement="end"
                size="md"
                styles={{
                  inner: {
                    padding: 4,
                  },
                }}
                target={
                  <Tooltip
                    disabled={window.$isTouchDevice && window.$isTouchDevice()}
                    label={`Basic actions`}
                    withArrow
                  >
                    <Button
                      variant="light"
                      color="dark"
                      radius="xl"
                      className={classes.primaryButtonStyle}
                      styles={{
                        root: {
                          padding: 0,
                          width: 38,
                          display: "flex",
                          justifyContent: "center",
                        },
                      }}
                      onClick={() => setOpenedMoreOptions((o) => !o)}
                    >
                      <FontAwesomeIcon icon={solid("ellipsis-vertical")} />
                    </Button>
                  </Tooltip>
                }
              >
                {space &&
                  space.permissions &&
                  !space.is_post &&
                  // space.pod_state != "model" && // Removed because manage button was needed here
                  space.permissions.can_manage && (
                    <Button
                      variant="subtle"
                      classNames={{
                        root: classes.appearanceButtonRoot,
                        inner: classes.appearanceButtonInner,
                        leftIcon: classes.appearanceButtonLeftIcon,
                      }}
                      leftIcon={
                        <FontAwesomeIcon size="md" icon={solid("user-plus")} />
                      }
                      onClick={(event) => setInviteOpened(true)}
                    >
                      {"Invite people"}
                    </Button>
                  )}

                {!largeScreen &&
                  space &&
                  space.permissions &&
                  space.permissions.is_member &&
                  space.permissions.allow_completions &&
                  space.permissions.show_mark_complete_button_in_header &&
                  !space.permissions.is_top_level &&
                  !space.is_post &&
                  user.authenticated &&
                  !editPage && (
                    <Button
                      variant="subtle"
                      classNames={{
                        root: classes.appearanceButtonRoot,
                        inner: classes.appearanceButtonInner,
                        leftIcon: classes.appearanceButtonLeftIcon,
                      }}
                      leftIcon={
                        <FontAwesomeIcon icon={solid("circle-check")} />
                      }
                      radius="xl"
                      onClick={() => onMarkAsCompleted(!completed)}
                    >
                      {completed
                        ? t("spaces.header.buttons.uncomplete")
                        : t("spaces.header.buttons.markComplete")}
                    </Button>
                  )}

                {!largeScreen &&
                  space &&
                  space.permissions &&
                  space.permissions.show_members &&
                  space.permissions.is_member &&
                  !space.is_post &&
                  !space.permissions.is_pending_member && (
                    <Button
                      variant="subtle"
                      classNames={{
                        root: classes.appearanceButtonRoot,
                        inner: classes.appearanceButtonInner,
                        leftIcon: classes.appearanceButtonLeftIcon,
                      }}
                      leftIcon={
                        <FontAwesomeIcon
                          size="md"
                          icon={solid("user-friends")}
                        />
                      }
                      onClick={() => onMembers()}
                    >
                      {t("spaces.header.buttons.members")}
                    </Button>
                  )}

                {!mediumScreen &&
                space.version == "content" &&
                space &&
                space.permissions &&
                space.permissions.can_edit ? (
                  <>
                    {!editPage ? (
                      <Button
                        variant="subtle"
                        classNames={{
                          root: classes.appearanceButtonRoot,
                          inner: classes.appearanceButtonInner,
                          leftIcon: classes.appearanceButtonLeftIcon,
                        }}
                        leftIcon={
                          <FontAwesomeIcon size="md" icon={solid("edit")} />
                        }
                        onClick={(event) => {
                          onTriggerAction(event, { editPage: true });
                          setOpenedMoreOptions(false);
                        }}
                      >
                        {t("spaces.header.buttons.edit")}{" "}
                        {`${
                          space.is_post
                            ? t("spaces.header.buttons.post") + "4"
                            : t("spaces.header.buttons.space")
                        }`}
                      </Button>
                    ) : (
                      <Button
                        variant="subtle"
                        classNames={{
                          root: classes.appearanceButtonRoot,
                          inner: classes.appearanceButtonInner,
                          leftIcon: classes.appearanceButtonLeftIcon,
                        }}
                        leftIcon={
                          <FontAwesomeIcon size="md" icon={solid("edit")} />
                        }
                        onClick={(event) => {
                          onTriggerAction(event, { editPage: false });
                          setOpenedMoreOptions(false);
                        }}
                      >
                        {t("spaces.header.buttons.viewPage")}
                      </Button>
                    )}
                  </>
                ) : (
                  ""
                )}

                {!mediumScreen &&
                  space &&
                  space.permissions &&
                  !space.is_post &&
                  // space.pod_state != "model" && // Removed because manage button was needed here
                  (space.permissions.can_manage || space.permissions.can_moderate) && (
                    <Button
                      variant="subtle"
                      classNames={{
                        root: classes.appearanceButtonRoot,
                        inner: classes.appearanceButtonInner,
                        leftIcon: classes.appearanceButtonLeftIcon,
                      }}
                      leftIcon={
                        <FontAwesomeIcon size="md" icon={solid("cog")} />
                      }
                      onClick={(event) =>
                        onTriggerAction(event, { manage: true })
                      }
                    >
                      {t("spaces.header.buttons.manage")}
                    </Button>
                  )}

                {space &&
                  space.permissions &&
                  space.permissions.can_manage &&
                  !space.is_post && (
                    <Button
                      onClick={() => onDuplicateSpace(true)}
                      variant="subtle"
                      classNames={{
                        root: classes.appearanceButtonRoot,
                        inner: classes.appearanceButtonInner,
                        leftIcon: classes.appearanceButtonLeftIcon,
                      }}
                      leftIcon={
                        <FontAwesomeIcon size="md" icon={solid("clone")} />
                      }
                    >
                      {t("spaces.header.buttons.duplicate")}
                    </Button>
                  )}

                {space &&
                  space.permissions &&
                  space.permissions.can_manage &&
                  !space.is_post &&
                  space.version == "content" && (
                    <Button
                      onClick={() => onCreateTemplate()}
                      variant="subtle"
                      classNames={{
                        root: classes.appearanceButtonRoot,
                        inner: classes.appearanceButtonInner,
                        leftIcon: classes.appearanceButtonLeftIcon,
                      }}
                      leftIcon={
                        <FontAwesomeIcon size="md" icon={solid("map")} />
                      }
                    >
                      {t("spaces.header.buttons.template")}
                    </Button>
                  )}

                {space &&
                  space.permissions &&
                  (space.permissions.can_manage ||
                    space.permissions.can_edit) &&
                  (editPage ? (
                    <Button
                      onClick={() => onVersionControl()}
                      variant="subtle"
                      classNames={{
                        root: classes.appearanceButtonRoot,
                        inner: classes.appearanceButtonInner,
                        leftIcon: classes.appearanceButtonLeftIcon,
                      }}
                      leftIcon={
                        <FontAwesomeIcon
                          size="md"
                          icon={solid("clock-rotate-left")}
                        />
                      }
                    >
                      {t("spaces.header.buttons.versionHistory")}
                    </Button>
                  ) : (
                    <Button
                      onClick={(event) => {
                        dispatch(setActions({ editPage: true }));
                        window.$openVersionHistoryOnEditMode = true;
                      }}
                      variant="subtle"
                      classNames={{
                        root: classes.appearanceButtonRoot,
                        inner: classes.appearanceButtonInner,
                        leftIcon: classes.appearanceButtonLeftIcon,
                      }}
                      leftIcon={
                        <FontAwesomeIcon
                          size="md"
                          icon={solid("clock-rotate-left")}
                        />
                      }
                    >
                      {t("spaces.header.buttons.versionHistory")}
                    </Button>
                  ))}

                {space && space.permissions && space.permissions.is_admin && (
                  <>
                    <Button
                      onClick={() => setTreeModalOpened(true)}
                      variant="subtle"
                      classNames={{
                        root: classes.appearanceButtonRoot,
                        inner: classes.appearanceButtonInner,
                        leftIcon: classes.appearanceButtonLeftIcon,
                      }}
                      leftIcon={
                        <FontAwesomeIcon size="md" icon={solid("spinner")} />
                      }
                    >
                      {"Space map"}
                    </Button>
                  </>
                )}

                {space &&
                  space.permissions &&
                  space.permissions.can_manage &&
                  space.pod_state != "archived" &&
                  !space.is_post && (
                    <Button
                      onClick={() => onArchive()}
                      variant="subtle"
                      classNames={{
                        root: classes.appearanceButtonRoot,
                        inner: classes.appearanceButtonInner,
                        leftIcon: classes.appearanceButtonLeftIcon,
                      }}
                      leftIcon={
                        <FontAwesomeIcon
                          size="md"
                          icon={solid("box-archive")}
                        />
                      }
                    >
                      {t("spaces.header.buttons.archive")}
                    </Button>
                  )}

                {space &&
                  space.permissions &&
                  space.permissions.can_manage &&
                  space.pod_state == "archived" &&
                  !space.is_post && (
                    <Button
                      onClick={() => onUnarchive()}
                      variant="subtle"
                      classNames={{
                        root: classes.appearanceButtonRoot,
                        inner: classes.appearanceButtonInner,
                        leftIcon: classes.appearanceButtonLeftIcon,
                      }}
                      leftIcon={
                        <FontAwesomeIcon
                          size="md"
                          icon={solid("box-archive")}
                        />
                      }
                    >
                      {t("spaces.header.buttons.unarchive")}
                    </Button>
                  )}

                {space &&
                  space.permissions &&
                  (space.permissions.can_manage ||
                    space.permissions.can_delete) && (
                    <Button
                      onClick={() => onDelete()}
                      variant="subtle"
                      classNames={{
                        root: classes.appearanceButtonRoot,
                        inner: classes.appearanceButtonInner,
                        leftIcon: classes.appearanceButtonLeftIcon,
                      }}
                      leftIcon={
                        <FontAwesomeIcon
                          size="md"
                          icon={solid("delete-left")}
                        />
                      }
                    >
                      {t("spaces.header.buttons.delete")}
                    </Button>
                  )}

                {space &&
                  space.permissions &&
                  !space.permissions.is_manager &&
                  space.permissions.is_member &&
                  window.$userIsSubscribed && (
                    <>
                      <Button
                        onClick={() => setOpenedPaymentMethod(true)}
                        variant="subtle"
                        classNames={{
                          root: classes.appearanceButtonRoot,
                          inner: classes.appearanceButtonInner,
                          leftIcon: classes.appearanceButtonLeftIcon,
                        }}
                        leftIcon={
                          <FontAwesomeIcon
                            size="md"
                            icon={solid("credit-card")}
                          />
                        }
                      >
                        {"Payments"}
                      </Button>
                    </>
                  )}

                {space &&
                  user &&
                  user.id &&
                  space.permissions &&
                  (!space.parents_versions ||
                    !space.user ||
                    (space.parents_versions &&
                      ((space.parents_versions.indexOf("list") < 0 &&
                        space.parents_versions.indexOf("showcase") < 0) ||
                        ((space.parents_versions.indexOf("list") >= 0 ||
                          space.parents_versions.indexOf("showcase") >= 0) &&
                          space.user &&
                          space.user.id != user.id)))) &&
                  (space.permissions.is_member ||
                    space.permissions.is_pending_member ||
                    space.permissions.is_registration_survey_member) && (
                    <Button
                      onClick={() => onLeave()}
                      variant="subtle"
                      classNames={{
                        root: classes.appearanceButtonRoot,
                        inner: classes.appearanceButtonInner,
                        leftIcon: classes.appearanceButtonLeftIcon,
                      }}
                      leftIcon={
                        <FontAwesomeIcon
                          size="md"
                          icon={solid("person-walking-arrow-right")}
                        />
                      }
                    >
                      {t("spaces.header.buttons.leave")}
                    </Button>
                  )}

                {space &&
                  space.permissions &&
                  space.permissions.can_manage && (
                    <Button
                      onClick={() => onOpenTutorial()}
                      variant="subtle"
                      classNames={{
                        root: classes.appearanceButtonRoot,
                        inner: classes.appearanceButtonInner,
                        leftIcon: classes.appearanceButtonLeftIcon,
                      }}
                      leftIcon={
                        <FontAwesomeIcon
                          size="md"
                          icon={solid("heart")}
                        />
                      }
                    >
                      {"Getting started"}
                    </Button>
                  )}
              </Popover>
            ) : (
              space &&
              space.permissions &&
              space.permissions.can_join &&
              !registered &&
              !window.$userIsPending && (
                <>
                  {!user?.authenticated && (
                    <Button
                      className={classes.primaryButtonStyle}
                      onClick={() => onJoin()}
                      radius="xl"
                    >
                      {"Login"}
                    </Button>
                  )}
                  <Button
                    className={classes.primaryButtonStyle}
                    onClick={() => onJoin()}
                    radius="xl"
                  >
                    {space.calendar.has_start_date
                      ? t("spaces.header.buttons.joinEvent")
                      : t("spaces.header.buttons.joinSpace")}
                  </Button>
                </>
              )
            )}

            {largeScreen &&
              space &&
              space.permissions &&
              space.permissions.show_members &&
              space.permissions.is_member &&
              location.pathname.indexOf(`/${space.version}`) >= 0 &&
              typeof space.members != "string" &&
              space.members.length > 1 && (
                <>
                  <AvatarsGroup
                    onClick={() => onMembers()}
                    limit={4}
                    total={space.members_count}
                    spacing={30}
                    // radius={10}
                    styles={{
                      root: {
                        justifyContent: "right",
                        cursor: "pointer",
                        paddingLeft: 12,
                      },
                      child: {
                        width: "auto",
                        border: `2px solid ${colors.header_color} !important`,
                      },
                      truncated: {
                        backgroundColor: isSpaceThemed(colors)
                          ? colors.primary_button_background_color
                          : theme.colors.gray[2],
                        color: isSpaceThemed(colors)
                          ? colors.primary_button_text_color
                          : "#000",
                        paddingRight: 5,
                        paddingLeft: 5,
                      },
                    }}
                  >
                    {space.members.map((member) => {
                      return (
                        <Avatar
                          styles={{
                            image: {
                              width: 34,
                            },
                            // root: { border: "none !important" }
                            // root: { border: `2px solid ${colors.header_color} !important` }
                          }}
                          key={`member-avatar-${member.id}`}
                          src={
                            member.avatar.url
                              ? member.avatar.url
                              : DefaultAvatar
                          }
                          component="span"
                        />
                      );
                    })}
                  </AvatarsGroup>
                </>
              )}
          </Group>

          <Drawer
            opened={openedDuplicate}
            onClose={() => setOpenedDuplicate(false)}
            title={
              <Text size="xs">
                {!createTemplate ? "DUPLICATE SPACE" : "SAVE AS TEMPLATE"}
              </Text>
            }
            padding="xl"
            size="xl"
          >
            <DuplicateForm
              setOpened={setOpenedDuplicate}
              onDuplicate={onDuplicate}
              spaceId={space.slug}
              spaceName={space.name}
              createTemplate={createTemplate}
            />
          </Drawer>
        </>
      ) : (
        <Group
          position="right"
          spacing="xs"
          pr={showHeaderSeparator(false) ? 15 : 0}
          mr={showHeaderSeparator(false) ? 15 : 0}
          className={showHeaderSeparator(false) ? classes.separatorRight : ""}
          noWrap
        >
          {location && location.pathname.indexOf("/registration") >= 0 ? (
            <>
              {location && location.pathname.indexOf("/edit") >= 0 && (
                <Tooltip
                  disabled={window.$isTouchDevice && window.$isTouchDevice()}
                  label={`Undo your las edit`}
                  withArrow
                >
                  <Button
                    className={classes.secondaryButtonStyle}
                    leftIcon={<FontAwesomeIcon icon={solid("rotate-left")} />}
                    onClick={(event) => onTiptapUndo()}
                    radius="xl"
                  >
                    {t("spaces.header.buttons.undo")}
                  </Button>
                </Tooltip>
              )}
              <Tooltip
                disabled={window.$isTouchDevice && window.$isTouchDevice()}
                label={`Change background color and page width`}
                withArrow
                width={200}
                wrapLines
                styles={{
                  body: { textAlign: "center" },
                }}
              >
                <Button
                  variant="light"
                  color="dark"
                  className={classes.secondaryButtonStyle}
                  leftIcon={<FontAwesomeIcon icon={solid("palette")} />}
                  onClick={(event) => onOpenRegPageColors()}
                  radius="xl"
                >
                  {t("spaces.header.buttons.appearance")}
                </Button>
              </Tooltip>
              <Popover
                opened={openedMoreOptions}
                onClose={() => setOpenedMoreOptions(false)}
                width={150}
                position="bottom"
                placement="center"
                size="md"
                styles={{
                  inner: {
                    padding: 4,
                  },
                }}
                target={
                  <Tooltip
                    disabled={window.$isTouchDevice && window.$isTouchDevice()}
                    label={`Preview content changes`}
                    withArrow
                    wrapLines
                    styles={{
                      body: { textAlign: "center" },
                    }}
                  >
                    <Button
                      variant="light"
                      color="dark"
                      radius="xl"
                      className={classes.secondaryButtonStyle}
                      onClick={() => setOpenedMoreOptions((o) => !o)}
                      leftIcon={<FontAwesomeIcon icon={solid("eye")} />}
                    >
                      {t("spaces.header.buttons.preview")}
                    </Button>
                  </Tooltip>
                }
              >
                <Button
                  variant="subtle"
                  classNames={{
                    root: classes.appearanceButtonRoot,
                    inner: classes.appearanceButtonInner,
                    leftIcon: classes.appearanceButtonLeftIcon,
                  }}
                  leftIcon={
                    <FontAwesomeIcon size="md" icon={solid("pencil-alt")} />
                  }
                  onClick={(event) => onOpenPreview("draft")}
                >
                  {t("spaces.header.buttons.draft")}
                </Button>
                <Button
                  variant="subtle"
                  classNames={{
                    root: classes.appearanceButtonRoot,
                    inner: classes.appearanceButtonInner,
                    leftIcon: classes.appearanceButtonLeftIcon,
                  }}
                  leftIcon={
                    <FontAwesomeIcon size="md" icon={solid("rocket")} />
                  }
                  onClick={(event) => onOpenPreview("published")}
                >
                  {t("spaces.header.buttons.published")}
                </Button>
              </Popover>
              <Tooltip
                disabled={window.$isTouchDevice && window.$isTouchDevice()}
                label={`Publish content changes`}
                withArrow
                wrapLines
                styles={{
                  body: { textAlign: "center" },
                }}
              >
                <Button
                  variant="light"
                  color="dark"
                  className={classes.primaryButtonStyle}
                  leftIcon={<FontAwesomeIcon icon={solid("rocket")} />}
                  onClick={(event) => onPublishPage()}
                  radius="xl"
                >
                  {t("spaces.header.buttons.publish")}
                </Button>
              </Tooltip>
              <Popover
                opened={openedMoreOptions2}
                onClose={() => setOpenedMoreOptions2(false)}
                width={200}
                position="bottom"
                placement="end"
                size="md"
                styles={{
                  inner: {
                    padding: 4,
                  },
                }}
                target={
                  <Tooltip
                    disabled={window.$isTouchDevice && window.$isTouchDevice()}
                    label={`Basic actions`}
                    withArrow
                  >
                    <Button
                      variant="light"
                      color="dark"
                      radius="xl"
                      className={classes.primaryButtonStyle}
                      styles={{
                        root: {
                          padding: 0,
                          width: 38,
                          display: "flex",
                          justifyContent: "center",
                        },
                      }}
                      onClick={() => setOpenedMoreOptions2((o) => !o)}
                    >
                      <FontAwesomeIcon icon={solid("ellipsis-vertical")} />
                    </Button>
                  </Tooltip>
                }
              >
                <Button
                  onClick={() => onVersionControl()}
                  variant="subtle"
                  classNames={{
                    root: classes.appearanceButtonRoot,
                    inner: classes.appearanceButtonInner,
                    leftIcon: classes.appearanceButtonLeftIcon,
                  }}
                  leftIcon={
                    <FontAwesomeIcon
                      size="md"
                      icon={solid("clock-rotate-left")}
                    />
                  }
                >
                  {t("spaces.header.buttons.versionHistory")}
                </Button>
              </Popover>
              <MantineProvider theme={{ primaryShade: 5 }}>
                <Button
                  color="green"
                  leftIcon={<FontAwesomeIcon icon={solid("arrow-left")} />}
                  onClick={(event) => onReturnToSettings()}
                  radius="xl"
                >
                  {t("spaces.header.buttons.returnToSettings")}
                </Button>
              </MantineProvider>
            </>
          ) : (
            <>
              {(location.pathname.indexOf(`/settings`) >= 0 ||
                location.pathname.indexOf(`/registration`) >= 0 ||
                location.pathname.indexOf(`/members`) >= 0) && (
                <MantineProvider theme={{ primaryShade: 5 }}>
                  <Button
                    color="green"
                    leftIcon={<FontAwesomeIcon icon={solid("arrow-left")} />}
                    onClick={(event) => onReturnToSpace()}
                    radius="xl"
                  >
                    {t("spaces.header.buttons.returnToSpace")}
                  </Button>
                </MantineProvider>
              )}
              {location.pathname.indexOf(`/demo`) >= 0 && (
                <MantineProvider theme={{ primaryShade: 5 }}>
                  <Button
                    color="green"
                    leftIcon={<FontAwesomeIcon icon={solid("arrow-left")} />}
                    onClick={(event) => onReturnToSettings()}
                    radius="xl"
                  >
                    {t("spaces.header.buttons.returnToSettings")}
                  </Button>
                </MantineProvider>
              )}
            </>
          )}
        </Group>
      )}
      <Modal
        opened={loadingModalOpened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        withCloseButton={false}
        onClose={() => {}}
        zIndex={99999}
      >
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
          }}
          pt={10}
        >
          <Loader />
          <Text mt={30}>{t("spaces.header.regPagePublishing")}</Text>
        </Box>
      </Modal>
      <Modal
        opened={deletingModalOpened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        withCloseButton={false}
        onClose={() => {}}
        zIndex={99999}
      >
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
          }}
          pt={10}
        >
          <Loader />
          <Text mt={30}>{t("spaces.header.spaceUpdating")}</Text>
        </Box>
      </Modal>
      <Drawer
        opened={openedPaymentMethod}
        onClose={() => setOpenedPaymentMethod(false)}
        title={<Text size="xs">{t("settings.billing.payments")}</Text>}
        padding="xl"
        size="xl"
      >
        <Accordion
          // iconPosition="right"
          iconSize={32}
          offsetIcon={false}
          mt={20}
          sx={(theme) => ({
            borderRadius: 10,
            borderBottom: "none",
            border: "solid 1px #f1f1f1",
          })}
          styles={{
            item: {
              borderBottom: "none",
            },
            control: {
              borderRadius: 10,
              fontWeight: 600,
            },
          }}
        >
          <Accordion.Item
            label={t("settings.billing.stripe.updatePaymentMethod")}
          >
            <PaymentMethod
              setOpenedPaymentMethod={setOpenedPaymentMethod}
              space={space}
            />
          </Accordion.Item>
          <Accordion.Item label={t("settings.billing.billingHistory")}>
            <History space={space} />
          </Accordion.Item>
        </Accordion>
      </Drawer>
      <Modal
        title={
          <Box sx={{ width: "100%" }}>
            <Group position="apart">
              <Text weight={"bold"}>{treeViewSelectedNode.name}</Text>
              {treeViewSelectedNode.name && (
                <Button
                  radius="xl"
                  onClick={() => onGoToSpace()}
                >{`View space`}</Button>
              )}
            </Group>
          </Box>
        }
        size="full"
        opened={treeModalOpened}
        onClose={() => setTreeModalOpened(false)}
        zIndex={99999}
        styles={{
          title: { width: "100%", padding: "20px 20px 0 20px" },
          close: { marginRight: "10px", marginTop: "20px" },
          body: {
            overflow: "hidden",
          },
          modal: {
            padding: "0px !important",
          },
          inner: {
            overflow: "hidden",
            height: "95vh",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
          }}
          pt={10}
        >
          {treeModalOpened && (
            <TreeView
              setOpened={setTreeModalOpened}
              setTreeViewSelectedNode={setTreeViewSelectedNode}
            />
          )}
        </Box>
      </Modal>
      <PremiumModal opened={openedPremium} setOpened={setOpenedPremium} />
      <InvitePeople opened={inviteOpened} setOpened={setInviteOpened} />
    </>
  );
}
