import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NodeViewWrapper, NodeViewContent } from "@tiptap/react";

import { stylesToCamelCase } from "../../../utils/StylesToString";
import InteractionModel from "../../../models/Interaction";
import SpaceModel from "../../../models/Space";
import MessageModel from "../../../models/Message";
import Message from "../../spaces_neo/presentations/discussion/Message";
import Reflections from "../../spaces_neo/presentations/discussion/Reflections";
import TiptapInline from "./TiptapInline";

import NotificationModel from "../../../../models/NotificationModel";

import { storeJSON } from "../../../utils/LocalDB";

import { wideOrNarrow } from "../../../utils/PageWidth";
import trackMixpanel from "../../../utils/TrackMixpanel";

import {
  Button,
  createStyles,
  Box,
  ScrollArea,
  Drawer,
  Text,
  Center,
  Stack,
} from "@mantine/core";

import {
  isNodeInsideNode,
  //setUniqId,
  checkInteractionAndHideIfNeededReact,
} from "../utils/selection";

import tinycolor from "tinycolor2";

import { useInView } from "react-intersection-observer";

import { ActionCableContext } from "../../../components/Index";
import { prosemirrorJSONToYDoc } from "y-prosemirror";

import isSpaceThemed from "../../../utils/IsSpaceThemed";
import isDark from "../../../utils/IsDarkColor";

const useStyles = createStyles((theme, { colors, props }) => ({
  drawer: {
    backgroundColor: colors.background_color,
  },
  drawerHeader: {
    color: colors.default_text_color,
    fontWeight: 700,
    paddingBottom: 10,
    // borderBottom: `1px solid ${colors.default_text_color}`
  },
  formStyle: {
    "&:focus": {
      border: `1px solid ${tinycolor(colors.background_color)
        .darken(20)
        .toString()}`,
    },
  },
  buttonStyle: {
    backgroundColor: props.node.attrs.buttonBackgroundColor,
    color: props.node.attrs.buttonTextColor,
    border: `1px solid ${props.node.attrs.buttonBackgroundColor}`,
    "&:hover": {
      backgroundColor: tinycolor(props.node.attrs.buttonBackgroundColor)
        .darken(5)
        .toString(),
    },
  },
  placeholder: {
    borderRadius: "10px",
    padding: "20px",
    backgroundColor: isSpaceThemed(colors)
      ? isDark(colors.background_color)
        ? tinycolor(colors.background_color).lighten(8).toString()
        : tinycolor(colors.background_color).darken(8).toString()
      : tinycolor(colors.background_color).darken(2).toString(),
    border: isSpaceThemed(colors)
      ? `1px solid ${
          isDark(colors.background_color)
            ? tinycolor(colors.background_color).lighten(20).toString()
            : tinycolor(colors.background_color).darken(20).toString()
        }`
      : `1px solid #e9ecef`,
  },
}));

export default function InteractiveInput(props) {
  const cable = useContext(ActionCableContext);

  const colors = useSelector((state) => state.colors);

  const { classes, cx, theme } = useStyles({ colors, props });
  const { t } = useTranslation();

  const [space, setSpace] = useState(null);
  const [messages, setMessages] = useState([]);
  const [interacted, setInteracted] = useState(false);
  const [tagging, setTagging] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [showPrivate, setShowPrivate] = useState(true);

  const [receiveData, setReceiveData] = useState(null);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [saving, setSaving] = useState(false);

  const [openedReflections, setOpenedReflections] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [reflectionsTagging, setReflectionsTagging] = useState(false);
  const [askPrivacy, setAskPrivacy] = useState(false);
  const [reflectionsDrawerHeader, setReflectionsDrawerHeader] = useState(
    t("spaces.presentations.discussion.reflectionHeader")
  );

  const navigate = useNavigate();
  const params = useParams();

  const { ref, inView } = useInView({ triggerOnce: true });

  const viewport = useRef();

  const loaded = useRef();
  const content = useRef();
  const auxPos = useRef();
  const querySearchCount = useRef(0);
  const messagesCopy = useRef([]);
  const fixTimeout = useRef([]);

  const paginator = useRef({
    per: 30,
    page: 1,
  });

  const lastConnectionAttempt = useRef({
    slug: null,
    state: null,
    channel: null,
  });

  useEffect(() => {
    if (window.$reloadTagsFeed == props.node.attrs.id) {
      window.$reloadTagsFeed = null;
      onFetchMessages();
    }
  }, [props]);

  useEffect(() => {
    const search = window.location.search;
    const interactive_slug = new URLSearchParams(search).get("tags_feed_slug");
    if (
      (inView || interactive_slug) &&
      !space &&
      (!loaded.current || props.node.attrs.forceUpdate)
    ) {
      if (!props.editor.isEditable) {
        onFetch();
        onFetchMessages();
        performCableConnection();
      }

      if (!props.editor.isEditable) {
        if (interactive_slug && interactive_slug == props.node.attrs.id) {
          scrollToMe();
        }
      }
    }
  }, [inView]);

  useEffect(() => {
    if (!props.editor.isEditable && inView && space) {
      onFetchMessages();
    } else {
      // Check on backend if another IJ exist with same ID
    }
  }, [showAll]);

  useEffect(() => {
    if (!props.editor.isEditable) {
      const search = window.location.search;
      const pod_message_id = new URLSearchParams(search).get("pod_message_id");

      if (pod_message_id && openMessages().length > 0) {
        if (querySearchCount.current == 0) {
          setShowAll(true);
          setTimeout(() => {
            searchForMessage(pod_message_id);
          }, 3000);
        } else {
          searchForMessage(pod_message_id);
        }
      }
    }
  }, [messages]);

  useEffect(() => {
    if (receiveData && !props.editor.isEditable) {
      let changes = receiveData.changes;
      if (receiveData.state != "multi") {
        changes = [receiveData];
      }

      for (var i = 0; i < changes.length; i++) {
        if (changes[i].state == "new") {
          onReceivedMessage(changes[i].pod_message_id);
        } else if (changes[i].state == "update") {
          onUpdatedMessage(changes[i].pod_message_id);
        } else if (changes[i].state == "destroy") {
          onDestroyedMessage(changes[i].pod_message_id);
        } else if (changes[i].state == "pin") {
          onPinnedMessage(changes[i].pod_message_id);
        } else if (changes[i].state == "unpin") {
          onPinnedMessage(null);
        }
      }
    }
  }, [receiveData]);

  const performCableConnection = () => {
    if (props.node.attrs.id) {
      for (let i = 0; i < props.node.attrs.id.length; i++) {
        const _id = props.node.attrs.id[i];

        cable.subscriptions.create(
          {
            channel: "PodsChannel",
            pod_slug: _id,
            token: window.$currentUserAuthToken,
          },
          {
            connected: () => {
              console.log("Tags feed Channel connected: " + _id);
            },
            disconnected: () => {},
            received: (data) => {
              console.log("Tags feed Channel connected: ");
              console.log(data);
              if (data.slug == _id && messageHasTags(data.tags)) {
                // setReceiveData(data);
                return;
              }
            },
          }
        );
      }
    }
    // if (lastConnectionAttempt.current.channel) {
    //   lastConnectionAttempt.current.channel.unsubscribe();
    // }
    // lastConnectionAttempt.current.channel = cable.subscriptions.create(
    //   {
    //     channel: "PodsChannel",
    //     pod_slug: props.node.attrs.id,
    //     token: window.$currentUserAuthToken,
    //   },
    //   {
    //     connected: () => {
    //       console.log("Tags feed Channel connected: " + props.node.attrs.id);
    //     },
    //     disconnected: () => {},
    //     received: (data) => {
    //       console.log("Tags feed Channel connected: ");
    //       console.log(data);
    //       if (props.node.attrs.id.indexOf(props.node.attrs.id) >= 0) {
    //         setReceiveData(data);
    //         return;
    //       }
    //     },
    //   }
    // );
  };

  const messageHasTags = (messageTags) => {
    if (props.node.attrs.tags) {
      for (let i = 0; i < props.node.attrs.tags.length; i++) {
        const tag1 = props.node.attrs.tags[i];
        for (let j = 0; j < messageTags.length; j++) {
          const tag2 = messageTags[j];
          if (tag1 == tag2) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const onReceivedMessage = (messageId) => {
    MessageModel.onFetchById(
      messageId,
      props.node.attrs.id,
      (data) => {
        if (data.user.id != window.$currentUserId) {
          if (!isManager() && !showAll) {
            return;
          }
        }

        let newMessages = [...messagesCopy.current];

        if (messagesCopy.current.length == 0) {
          messagesCopy.current = [...messages];
          newMessages = [...messagesCopy.current];
        }

        let exist = false;
        for (let i = 0; i < messagesCopy.current.length; i++) {
          const msg = messagesCopy.current[i];
          if (msg.id == data.id) {
            exist = true;
            break;
          }
        }

        if (!exist) {
          newMessages.push(data);
          messagesCopy.current.push(data);
          setMessages(newMessages);
        }
      },
      (data) => {}
    );
  };

  const onUpdatedMessage = (messageId) => {
    MessageModel.onFetchById(
      messageId,
      props.node.attrs.id,
      (data) => {
        const newMessages = [...messages];

        for (let i = 0; i < newMessages.length; i++) {
          if (newMessages[i].id == messageId) {
            newMessages[i] = data;
            if (selectedMessage && messageId == selectedMessage.id) {
              setSelectedMessage({ ...newMessages[i] });
            }
            break;
          }
        }

        setMessages(newMessages);
        messagesCopy.current = [...newMessages];
      },
      (data) => {}
    );
  };

  const onDestroyedMessage = (messageId) => {
    const newMessages = [...messages];

    for (let i = 0; i < newMessages.length; i++) {
      if (newMessages[i].id == messageId) {
        newMessages.splice(i, 1);
        break;
      }
    }

    setMessages(newMessages);
    messagesCopy.current = [...newMessages];
  };

  const onPinnedMessage = (messageId) => {
    const newSpace = { ...space };
    newSpace.pin_message = messageId;
    // dispatch(setSpace(newSpace));
  };

  const searchForMessage = (messageId) => {
    if (querySearchCount.current > 10) {
      return;
    }

    querySearchCount.current++;

    const search = window.location.search;
    const pod_message_id = new URLSearchParams(search).get("pod_message_id");
    const reflection_id = new URLSearchParams(search).get("reflection_id");

    for (let i = 0; i < messages.length; i++) {
      if (messages[i].id == messageId) {
        querySearchCount.current = 99;
        scrollToMessage(messageId);
        if (reflection_id) {
          const newMessage = { ...messages[i] };
          newMessage.reflectionId = reflection_id;

          setSelectedMessage(newMessage);
          setOpenedReflections(true);
          markReflectionAsSeen(messageId);
          setReflectionsDrawerHeader(
            t("spaces.presentations.discussion.reflectionHeader")
          );
        }
        return;
      }
    }

    onFetchMessages();
  };

  const scrollToMe = () => {
    if (!props.editor.isEditable) {
      return;
    }
    setTimeout(() => {
      try {
        const $scrollTo = document.getElementById(`${props.node.attrs.uid}`);
        const $contentScrollViewport = document
          .getElementById(`main-scroll-area`)
          .querySelectorAll("div")[0];

        const top =
          $scrollTo.offsetTop -
          $contentScrollViewport.offsetTop +
          $contentScrollViewport.scrollTop +
          80;

        $contentScrollViewport.scrollTo({
          top: top,
          behavior: "smooth",
        });
      } catch (e) {}
    }, 500);
  };

  const scrollToMessage = (messageId) => {
    if (!props.editor.isEditable) {
      return;
    }
    setTimeout(() => {
      try {
        const $scrollTo = document.getElementById(`msg_${messageId}`);
        const $contentScrollViewport = document
          .getElementById(`main-scroll-area`)
          .querySelectorAll("div")[0];

        const top =
          $scrollTo.offsetTop -
          $contentScrollViewport.offsetTop +
          $contentScrollViewport.scrollTop +
          80;

        $contentScrollViewport.scrollTo({
          top: top,
          behavior: "smooth",
        });
      } catch (e) {}
    }, 500);
  };

  const onFetch = () => {
    loaded.current = true;
  };

  const onFetchMessages = () => {
    const search = window.location.search;
    const _tag = props.node.attrs.tags;

    if (_tag) {
      trackMixpanel(window.$currentUsername, "Tag clicked", { space_slug: props.node.attrs.id }); 
    }

    MessageModel.onFetch(
      props.node.attrs.id,
      {
        tag: _tag,
        only_current_user: props.node.attrs.tagsFeedShowAll,
        reverse: true,
        per: props.node.attrs.tagsNumberOfPosts,
      },
      paginator.current.page,
      (data) => {
        setCanLoadMore(paginator.current.page < data.pages);

        if (paginator.current.page == 1) {
          messagesCopy.current = [...data.data];
          setMessages([...data.data]);
        } else if (data.data.length > 0) {
          const auxData = data.data.concat(messagesCopy.current);
          messagesCopy.current = [...auxData];
          setMessages([...auxData]);
        }
      },
      (data) => {}
    );
  };

  const onLoadMore = () => {
    paginator.current.page++;
    onFetchMessages();
  };

  const isManager = () => {
    return (
      window.$currentRole &&
      (window.$currentRole == "manager" || window.$currentRole == "admin")
    );
  };

  const onOpenReflections = (messageId) => {
    setOpenedReflections(true);
    setReflectionsDrawerHeader(
      t("spaces.presentations.discussion.reflectionHeader")
    );

    for (let i = 0; i < messages.length; i++) {
      if (messages[i].id == messageId) {
        markReflectionAsSeen(messageId);
        setSelectedMessage(messages[i]);
        break;
      }
    }
  };

  const markReflectionAsSeen = (messageId) => {
    NotificationModel.onMarkAsSeen("reflection", messageId, (data) => {});
  };

  const openMessages = () => {
    if (isManager()) {
      if (showPrivate) {
        return messages.filter((msg) => msg.privacy == "open");
      } else {
        return messages.filter((msg) => !msg.is_private);
      }
    } else {
      return messages.filter((msg) => msg.privacy == "open");
    }
  };

  return (
    <NodeViewWrapper
      className="react-component"
      id={props.node.attrs.uid}
      dataUid={props.node.attrs.uid}
      spaceId={props.node.attrs.id}
      parentId={props.node.attrs.circle_id}
      ref={ref}
      style={{
        maxWidth: wideOrNarrow(),
      }}
    >
      {checkInteractionAndHideIfNeededReact(props.editor, props.getPos) && (
        <NodeViewContent
          contentEditable="false"
          style={{
            ...stylesToCamelCase(props.node.attrs.styles),
            textAlign: props.node.attrs.textAlign,
          }}
          className={`node-content ${props.node.attrs.class} interactive-input-wrapper tags-feed`}
          radius={props.node.attrs.radius}
        >
          <Box>
            {openMessages().length > 0 && props.editor ? (
              !props.editor.isEditable ? (
                <Box>
                  <>
                    <ScrollArea
                      style={{ height: "100%" }}
                      viewportRef={viewport}
                    >
                      {openMessages().map((message) => {
                        return (
                          <Message
                            key={`message_${message.id}`}
                            spaceId={message.pod_slug}
                            spaceVersion={message.pod_version}
                            messageId={message.id}
                            htmlId={`${message.type}_${message.id}`}
                            messageCreatedAt={message.created_at}
                            messageTitle={
                              message.post ? message.post.subject : null
                            }
                            messageContent={
                              message.version == "new"
                                ? message.content
                                : message.message_body
                            }
                            messageUnseenCount={message.unseen_count}
                            tags={message.tags}
                            reflections={message.reflections}
                            user={message.user ? message.user : {}}
                            onOpenReflections={onOpenReflections}
                            version={message.version}
                            canPin={false}
                            canTag={message.user ? message.user.can_tag : false}
                            canLike={
                              message.user ? message.user.can_like : false
                            }
                            canReflect={true}
                            canEdit={
                              message.user ? message.user.can_edit : false
                            }
                            canDelete={
                              message.user ? message.user.can_delete : false
                            }
                            onUpdated={onUpdatedMessage}
                            type={"pod_message"}
                            tagging={tagging}
                            setTagging={setTagging}
                            isPinned={false}
                            canSetOpen={message.can_set_open}
                            canSetPrivate={
                              message.can_set_open
                              // || (message.user && message.user.can_edit)
                            }
                            isManager={isManager()}
                            isPrivate={message.is_private}
                            text={message.text}
                            isLiked={message.is_liked}
                            likesCount={message.likes_count}
                            hideUser={!props.node.attrs.tagsShowUser}
                            hideDate={!props.node.attrs.tagsDate}
                            hideActions={
                              !props.node.attrs.tagsShowMessageActions
                            }
                            hideTags={
                              !props.node.attrs.tagsShowMTagsOnEachMessage
                            }
                            context={
                              isNodeInsideNode(
                                props.editor,
                                props.getPos,
                                "column"
                              )
                                ? "tagFeed"
                                : null
                            }
                          />
                        );
                      })}
                    </ScrollArea>
                    <Box>
                      {canLoadMore && (
                        <Button
                          color="dark"
                          radius="xl"
                          ml={10}
                          compact
                          variant="outline"
                          sx={(theme) => ({
                            color: colors.primary_button_background_color,
                            border: `1px solid ${colors.primary_button_background_color}`,
                            "&:hover": {
                              backgroundColor: tinycolor(
                                colors.background_color
                              )
                                .darken(3)
                                .toString(),
                              // color: tinycolor(colors.primary_button_background_color).lighten(5).toString()
                            },
                          })}
                          onClick={(event) => onLoadMore()}
                        >
                          {t(
                            "tiptap.components.interactiveInput.loadMoreResponse"
                          )}
                        </Button>
                      )}
                    </Box>
                  </>

                  <Drawer
                    opened={openedReflections}
                    onClose={() => setOpenedReflections(false)}
                    title={reflectionsDrawerHeader}
                    padding="xl"
                    size={600}
                    classNames={{
                      drawer: classes.drawer,
                      header: classes.drawerHeader,
                    }}
                    // styles={{
                    //   drawer: {
                    //     backgroundColor: "red"
                    //   }
                    // }}
                  >
                    <Reflections
                      spaceId={window.$currentSlug}
                      spaceVersion={props.spaceVersion}
                      // canReflect={space.space.permissions.allow_reflections}
                      canReflect={true}
                      message={selectedMessage}
                      onUpdatedMessage={onUpdatedMessage}
                      useReflectCallback={true}
                      tagging={reflectionsTagging}
                      setTagging={setReflectionsTagging}
                    />
                  </Drawer>
                </Box>
              ) : (
                <Box className={classes.placeholder}>
                  <Stack spacing={5}>
                    <Text weight={600}>Tag feed</Text>
                    {props.editor.isEditable ? (
                      <Text>
                        This block will load messages with selected tags from
                        selected spaces
                      </Text>
                    ) : (
                      <Text>No messages posted yet</Text>
                    )}
                  </Stack>
                </Box>
              )
            ) : (
              <Box className={classes.placeholder}>
                <Stack spacing={5}>
                  <Text weight={600}>Tag feed</Text>
                  {props.editor.isEditable ? (
                    <Text>
                      This block will load messages with selected tags from
                      selected spaces
                    </Text>
                  ) : (
                    <Text>No messages posted yet</Text>
                  )}
                </Stack>
              </Box>
            )}
          </Box>
        </NodeViewContent>
      )}
    </NodeViewWrapper>
  );
}
