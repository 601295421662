import React, {useState, useEffect} from 'react';

import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

import { createStyles } from '@mantine/core';

import isSpaceThemed from '../../utils/IsSpaceThemed'
import useSidebarStyles from "../../utils/SidebarStyles"
import defaultSpaceColors from '../../collections/defaultSpaceColors'

import HeaderContent from './HeaderContent'

import tinycolor from 'tinycolor2'

const useStyles = createStyles((theme, {colors, hiddenHeader}) => ({
  appHeader: {
    display: "flex",
    height: theme.other.headerHeight,
    alignItems: "center",
    boxSizing: "border-box",
    borderBottom: `1px solid ${!isSpaceThemed(colors) ? tinycolor(colors.sidebar_background_color).darken(5).toString() : colors.header_color}`,
    width: "100%",
    backgroundColor: colors.header_color
  }
}))

let isAuthRoute = false
let isProfile = false

export default function Header() {

  const location = useLocation();

  const [opened, setOpened] = useState(false);
  const [burgerVisible, setBurgerVisible] = useState(true);

  const { settingsView, registrationView } = useSidebarStyles();
  let colors = useSelector(state => state.colors)
  colors = !settingsView && !registrationView && !colors?.is_vision_builder ? colors : defaultSpaceColors
  const hiddenHeader = useSelector(state => state.hiddenHeader);

  const { classes, cx, theme } = useStyles({colors, hiddenHeader});

  useEffect(() => {
    window.$sidebarIsOpen = opened
  }, [opened])

  useEffect(() => {

    const authRoutes = ["/user/sign_in", "/user/sign_up", "/user/recover", "/user/reset_password", "/user/start", "/user/magic_link", "/user/magic_sign_in", "/pay", "/edit"];
    isAuthRoute = false;
    // Is user profile route
    isProfile = location.pathname.split("/").length == 2 && location.pathname.split("/")[1].length > 0//TODO: Probably need a better regex for this validation
    // Is user profile route
    for (let i = 0; i < authRoutes.length; i++) {
      if (location.pathname.indexOf(authRoutes[i]) >= 0){
        isAuthRoute = true;
        break;
      }
    }

    if (isAuthRoute || isProfile){
      setOpened(false);
      setBurgerVisible(false);
    } else {

    }

  }, [location])

  if (hiddenHeader.hidden == true){
    return null
  }

  return (
    <div
      id={`main-header-container`}
      className={classes.appHeader}>
      <HeaderContent
        hiddenHeader={hiddenHeader}
        opened={opened}
        visible={burgerVisible}
        />
    </div>
  );
}
