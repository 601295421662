import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { useDisclosure } from "@mantine/hooks";

import NotificationModel from "../../../models/NotificationModel";
import { useTranslation } from "react-i18next";
import useNotificationsStyle from "./style/notifications.js";

import { setTiptapSidemenu } from "../../store/app";

import isSpaceThemed from "../../utils/IsSpaceThemed";
import useSidebarStyles from "../../utils/SidebarStyles";
import useActivityCounter from "../../utils/ActivityCounter";
import defaultSpaceColors from "../../collections/defaultSpaceColors";

import tinycolor from "tinycolor2";

import { sutraTheme } from "../../collections/sutraTheme";

import {
  Text,
  Box,
  ScrollArea,
  Button,
  Popover,
  Group,
  Grid,
  Stack,
  Indicator,
  Center,
  Loader,
  Tooltip,
  createStyles,
  Drawer,
} from "@mantine/core";

const paginator = {
  per: 30,
  page: 1,
};

const loadingVariables = {
  firstLoad: false,
  reload: true,
  noMore: false,
};

const useStyles = createStyles((theme, { colors }) => ({
  primaryButtonStyle: {
    backgroundColor: isSpaceThemed(colors)
      ? colors.primary_button_background_color
      : theme.colors.gray[2],
    color: isSpaceThemed(colors) ? colors.primary_button_text_color : "#000000",
    "&:hover": {
      backgroundColor: isSpaceThemed(colors)
        ? tinycolor(colors.primary_button_background_color).darken(4).toString()
        : theme.colors.gray[1],
    },
  },
}));

export default function Notifications(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { notificationsStyle } = useNotificationsStyle();
  const { activityCount } = useActivityCounter();

  const user = useSelector((state) => state.user);
  const space = useSelector((state) => state.space);

  const { settingsView, registrationView } = useSidebarStyles();
  let colors = useSelector((state) => state.colors);
  colors = !settingsView && !registrationView && !colors?.is_vision_builder ? colors : defaultSpaceColors;

  const { classes, theme, cx } = useStyles({ colors });

  const [notifications, setNotifications] = useState([]);

  const [opened, handlers] = useDisclosure(false);

  const dispatch = useDispatch();

  const onMarkAllAsSeen = () => {
    NotificationModel.onMarkAllAsSeen(
      (data) => {},
      (data) => {}
    );
  };

  const onClickNotification = (notification) => {
    handlers.close();

    if (notification.is_direct) {
      if (!window.$notificationRedirectionCount) {
        window.$notificationRedirectionCount = 0;
      }
      window.$notificationRedirectionCount++;
      navigate(
        `${notification.url ? notification.url : notification.actions.main}&c=${
          window.$notificationRedirectionCount
        }`
      );
      return;
    }

    if (
      notification.is_direct &&
      window.location.pathname.indexOf("/space") >= 0 &&
      space &&
      space.slug &&
      space.pod_type != "user_message"
    ) {
      dispatch(
        setTiptapSidemenu({
          opened: "direct",
          option: notification.slug,
          pod_message_id: notification.pod_message_id,
        })
      );
    } else {
      if (!window.$notificationRedirectionCount) {
        window.$notificationRedirectionCount = 0;
      }
      window.$notificationRedirectionCount++;
      navigate(
        `${notification.url ? notification.url : notification.actions.main}&c=${
          window.$notificationRedirectionCount
        }`
      );
    }
  };

  const RenderNotifications = () => {
    if (!user.activity) {
      return [];
    }
    return user.activity
      .filter((item) => item.notification_type != "user_message")
      .map((notification, index) => {
        return (
          <Box sx={(theme) => notificationsStyle.notificationItem}>
            <Box
              key={`notification-item-${notification.id}-${index}`}
              onClick={() => onClickNotification(notification)}
            >
              <Grid grow>
                <Grid.Col span={10}>
                  <Text color="dimmed" size="sm" lineClamp={2}>
                    {notification.subtitle}
                  </Text>
                  <Text
                    color="black"
                    weight="600"
                    pb={2}
                    sx={{ lineHeight: 1.2 }}
                  >
                    {notification.title}
                  </Text>
                  <Text color="black" size="sm" sx={{ lineHeight: 1.3 }}>
                    {notification.content}
                  </Text>
                  <Text
                    mt={5}
                    size="xs"
                    sx={(theme) => {
                      color: theme.colors.sutrapink[4];
                    }}
                    color="dimmed"
                  >
                    {notification.created_at}
                  </Text>
                </Grid.Col>
                {!notification.is_seen ? (
                  <Grid.Col span={1}>
                    <Stack sx={(theme) => notificationsStyle.badge}>
                      <FontAwesomeIcon icon={solid("circle")} />
                    </Stack>
                  </Grid.Col>
                ) : (
                  <Grid.Col span={1}> </Grid.Col>
                )}
              </Grid>
            </Box>
          </Box>
        );
      });
  };

  const RenderLoader = () => {
    return (
      <Center pt={50}>
        <Loader />
      </Center>
    );
  };

  const RenderInsides = () => {
    return (
      <Box>
        <Group
          position="apart"
          pt={10}
          pb={10}
          pl={20}
          pr={10}
          sx={(theme) => ({ backgroundColor: theme.colors.gray[1] })}
        >
          <Text color="dimmed">{t("layout.notifications.label")}</Text>
          <Button
            variant="outline"
            color="dark"
            radius="xl"
            size="xs"
            compact
            onClick={() => onMarkAllAsSeen()}
          >
            {t("layout.notifications.actionButton")}
          </Button>
        </Group>
        <ScrollArea
          style={{
            height:
              props.component == "drawer"
                ? "calc(100vh - 100px)"
                : "calc(100vh - 150px)",
          }}
        >
          <RenderNotifications />
          {user.activity && user.activity.length == 0 && (
            <Text size="sm" align="center" color="dimmed" px={20} py={20}>
              {t("layout.notifications.empty")}
            </Text>
          )}
        </ScrollArea>
      </Box>
    );
  };

  const RenderTriggerButton = () => {
    return (
      <Indicator
        size={activityCount > 0 ? 20 : 0}
        label={activityCount > 0 ? activityCount : ""}
        offset={4}
        // color="sutrapink"
        onClick={() => handlers.toggle()}
        styles={{
          indicator: {
            cursor: "pointer",
            backgroundColor: isSpaceThemed(colors)
              ? colors.default_badge_color
              : sutraTheme.colors.sutrapink[3],
            color: isSpaceThemed(colors)
              ? colors.default_badge_text_color
              : theme.colors.gray[1],
            "&:hover button": {
              backgroundColor: isSpaceThemed(colors)
                ? tinycolor(colors.default_badge_color).darken(4).toString()
                : sutraTheme.colors.sutrapink[1],
              color: isSpaceThemed(colors)
                ? tinycolor(colors.default_badge_text_color)
                    .darken(4)
                    .toString()
                : theme.colors.gray[1],
            },
          },
        }}
      >
        <Tooltip
          disabled={window.$isTouchDevice && window.$isTouchDevice()}
          label={t("layout.notifications.notification")}
          withArrow
        >
          <Button
            variant="light"
            color="dark"
            radius="xl"
            onClick={() => handlers.toggle()}
            className={classes.primaryButtonStyle}
          >
            <FontAwesomeIcon icon={solid("bell")} />
          </Button>
        </Tooltip>
      </Indicator>
    );
  };

  return (
    <>
      {props.component == "drawer" ? (
        <>
          <Drawer
            opened={props.opened}
            onClose={() => props.setOpened(false)}
            title=""
            padding={0}
            size="lg"
          >
            {RenderInsides()}
          </Drawer>
        </>
      ) : (
        <Popover
          opened={opened}
          // onOpen={handlers.open}
          onClose={handlers.close}
          width={300}
          position="bottom"
          placement="end"
          spacing={0}
          shadow="lg"
          target={RenderTriggerButton()}
        >
          {RenderInsides()}
        </Popover>
      )}
    </>
  );
}
