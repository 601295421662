import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import {stylesToString} from '../../../utils/StylesToString'

import VisionCircle from '../components/VisionCircle';

const VisionCircleNode = Node.create({
  name: 'visionCircle',
  allowGapCursor: false,
  addOptions() {
    return {
      inline: false,
      HTMLAttributes: {},
    }
  },
  inline() {
    return this.options.inline
  },
  group() {
    return this.options.inline ? 'inline' : 'block columnable'
  },
  draggable: true,
  addAttributes() {
    return {
      title: {
        default: "",
      },
      description: {
        default: "",
      },
      gallery_feature: {
        default: null,
      },
      styles: {
        default: {
          "margin-top": "8px",
          "margin-bottom": "8px",
          "margin-left": "0px",
          "margin-right": "0px",
          "padding-top": "6px",
          "padding-bottom": "0px",
          "padding-left": "0px",
          "padding-right": "0px",
          "background-color": "",
          "background-image": "none",
          "background-position": "center center",
          "background-size": "cover",
          "background-repeat": "no-repeat",
        },
        renderHTML: attributes => ({
          style: stylesToString(attributes.styles),
          styles: stylesToString(attributes.styles),
        })
      }
    }
  },
  parseHTML() {
    return [
      {
        tag: 'visionCircle',
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['visionCircle', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },
  addNodeView() {
    return ReactNodeViewRenderer(VisionCircle)
  },
})

export default VisionCircleNode;
