import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import SpaceModel from "../../../models/Space";
import VisionModel from "../../../models/Vision";

import VisionChannel from "./VisionChannel";

import defaultSpaceColors from "../../../collections/defaultSpaceColors";
import { setColors } from "../../../store/app";

import {
  Modal,
  ScrollArea,
  Box,
  Center,
  Loader,
  Group,
  Text,
  Grid,
  Image,
  createStyles,
  Button,
} from "@mantine/core";

import TiptapNoHP from "./TiptapNoHP";

const useStyles = createStyles(() => ({
  sidebarItem: {
    padding: "10px 10px",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#fafafa",
    },
  }
}));

const content_json = { type: "doc", content: [] };

const AIBuilderModal = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { classes } = useStyles();

  const [templates, setTemplates] = useState([
    { id: 1, name: "Single page course", type: "single_page", loading: true, content_json },
    { id: 2, name: "Multi page course", type: "multi_page", loading: true, content_json },
    { id: 3, name: "Worksheet", type: "worksheet", loading: true, content_json },
    // { id: 4, name: "Online summit", type: "online_summit", loading: true, content_json },
  ]);
  const [themes, setThemes] = useState([]);
  const [activeTemplateTab, setActiveTemplateTab] = useState(0);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [vision, setVision] = useState(null);
  const [space, setSpace] = useState(null);
  const [newNode, setNewNode] = useState(null);

  const [uploading, setUploading] = useState(false);

  const selectedTemplate = templates[activeTemplateTab];
  const loading = templates[activeTemplateTab]?.loading;
  const existsContent = Array.isArray(selectedTemplate?.content_json?.content);

  const default_text_color = selectedTheme?.default_text_color || undefined;
  const btnBgColor = selectedTheme?.primary_button_background_color || undefined;
  const btnTextColor = selectedTheme?.primary_button_text_color || undefined;

  useEffect(() => {
    onCreateSpace();
    onFetchThemes();
    // onFetchVision(63, "single_page");
    return () => {
      dispatch(setColors(defaultSpaceColors));
    }
  }, [])

  useEffect(() => {
    if (!newNode) return;
    
    const template = templates.find(t => t.type === newNode.type);
    if (!template) return;
  
    const exists = template.content_json.content.find(
      item => item.attrs?.uid === newNode.node?.attrs?.uid
    );
    
    if (!exists && newNode.node) {
      const updated = {
        ...template,
        content_json: {
          type: "doc",
          content: [...template.content_json.content, newNode.node]
        }
      };
      
      setTemplates(prev => prev.map(t => 
        t.id === template.id ? updated : t
      ));
    }
  
    setNewNode(null);
  }, [newNode, templates]);

  const onCreateSpace = (intention) => {
    const spaceParams = {
      intention: "VISION",
      version: "content",
      privacy_level: "open",
      join_on_create: true,
      launch_on_date: null,
      launch_time: null,
      show_start_date: false,
      start_date: new Date(new Date().getTime() + 86400000),
      end_date: new Date(new Date().getTime() + 90000000),
      start_time: new Date(new Date().getTime() + 86400000),
      end_time: new Date(new Date().getTime() + 90000000),
      content: "",
      hangout_link: "",
    };
    SpaceModel.onCreate(
      spaceParams,
      null,
      (data) => {
        onCreateVision(data.id);
        setSpace(data);
      },
      (data) => {}
    );
  };

  const onCreateVision = (space_id) => {
    VisionModel.onCreate(
      props.aiPrompt,
      space_id,
      (data) => {
        setVision(data);
      },
      (data) => {}
    );
  }
  
  const onFetchThemes = () => {
    SpaceModel.onFetchThemes(
      (data) => {
        setThemes(data);
      },
      (data) => {}
    );
  };

  const onFetchVision = (vision_id, type) => {
    if (!vision_id) {
      return;
    }
    VisionModel.onFetch(
      vision_id,
      (data) => {
        setVision(data);
        setTemplates(prev => prev.map(t => ({
          ...t,
          content_json: Array.isArray(data[t.type]?.content) ? data[t.type] : t.content_json,
          loading: t.type === type ? false : t.loading
        })));
      },
      (data) => {}
    );
  };

  const onUseTemplate = () => {
    setUploading(true);
    VisionModel.onUseTemplate(
      vision.id,
      space.slug,
      selectedTemplate.type,
      (data) => {
        setUploading(false);
        dispatch(setColors(defaultSpaceColors));
        if (data.redirect_url) {
          navigate(data.redirect_url);
        }
      },
      (data) => {
        setUploading(false);
      }
    );
  };

  const onAddContent = (data) => {
    console.log("DATA VISION BUILDER:::", data);
    setNewNode(data);
  };

  const onSelectedTheme = (theme) => {
    setSelectedTheme(theme);
    dispatch(setColors({...theme, is_vision_builder: true}));
    onSaveTheme(theme);
  };

  const onSaveTheme = (theme) => {
    SpaceModel.onSaveColors(
      space.slug,
      theme,
      (data) => {},
      (data) => {}
    );
  };

  return (
    <Modal
      styles={{ inner: { overflow: "hidden", height: "90vh", marginTop: 30 } }}
      withCloseButton={false}
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      padding={0}
      size="90%"
      zIndex={99999}
    >
      <Grid gutter={0}>
        <Grid.Col span={4} sx={{ borderRight: "1px solid #f1f3f5" }}>
          <Box pl={20} pr={20} sx={{ height: "85vh", display: 'flex', flexDirection: 'column' }}>

            <Text size="xl" weight={600} mt={20} mb={10}>{"Templates"}</Text>
            {templates.map((template, index) => {
              return (
                <Group
                  noWrap
                  className={classes.sidebarItem}
                  onClick={() => setActiveTemplateTab(index)}
                  align="top"
                >
                  <Box width={24} height={20}>
                    {template?.loading ? (
                      <Loader size={15} mt={5} />
                    ) : (
                      <FontAwesomeIcon size={16} icon={solid("bolt")} />
                    )}
                  </Box>
                  <Text align="left" weight={600}>{template.name}</Text>
                </Group>
              );
            })}

            <Box mt={20} style={{ width: '100%', height: 1, backgroundColor: "#f1f3f5" }}></Box>

            <Box sx={{ flex: 1 }} />

            <Group sx={{ justifyContent: "space-between" }} spacing={0}>
              <Text size="xl" weight={600} mt={20} mb={20}>{t("spaces.colors.form.colorTheme")}</Text>
              {!!selectedTheme && (
                <Button radius="xl" color="dark" size="xs" onClick={() => setSelectedTheme(null)}>
                  {"Reset"}
                </Button>
              )}
            </Group>
            <Grid>
              {Object.keys(themes).map((theme) => {
                return (
                  <Grid.Col xs={4}>
                    <Box
                      class={`${selectedTheme?.id == themes[theme].id ? "theme-selected" : ""}`}
                      onClick={(event) => onSelectedTheme(themes[theme])}
                    >
                      <Image
                        radius="md"
                        src={`../../../themes/${themes[theme].file_name}`}
                        alt={themes[theme].title}
                      />
                    </Box>
                  </Grid.Col>
                );
              })}
            </Grid>

          </Box>
        </Grid.Col>

        <Grid.Col span={8} sx={{ backgroundColor: selectedTheme?.header_color }}>
          <Box sx={{ position: "relative", width: "100%", zIndex: 99999999 }}>
            <Box sx={{ width: "100%", paddingLeft: 40, paddingRight: 40, borderBottom: selectedTheme ? null : "1px solid #f1f3f5" }}>
              <Box
                pt={20}
                mt={10}
                pb={30}
              >
                <Group position="apart">
                  <Text weight="700" size="lg" color={selectedTheme?.header_link_color}>{selectedTemplate.name}</Text>
                  <Group position={"right"}>
                    {existsContent && (
                      <>
                        {uploading ? (
                          <>
                            <Text color={default_text_color}>Loading...</Text>
                            <Loader size={20} />
                          </>
                        ) : (
                          <Button radius="xl" disabled={selectedTemplate.loading} sx={{ backgroundColor: btnBgColor, color: btnTextColor }} onClick={onUseTemplate}>
                            {"Use template"}
                          </Button>
                        )}
                      </>
                    )}
                  </Group>
                </Group>
              </Box>
            </Box>
          </Box>
          <ScrollArea style={{ height: "90vh", backgroundColor: selectedTheme?.background_color }}>
            <Box pb={30}></Box>
            <Box sx={{ paddingLeft: 24, paddingRight: 24 }}>
              {existsContent && (
                <>
                  {selectedTemplate.content_json.content.length > 0 ? (
                    <TiptapNoHP
                      key={uploading ? "tiptap-uploading" : `tiptap-${new Date()}`}
                      content={selectedTemplate.content_json}
                    />
                  ) : (
                    <Box mb={180}></Box>
                  )}
                </>
              )}
              {!!loading && (
                <Center>
                  <Loader />
                </Center>
              )}
            </Box>
          </ScrollArea>
        </Grid.Col>
      </Grid>

      {!!space?.slug && (
        <VisionChannel
          slug={space.slug}
          onUpdate={onFetchVision}
          onAddContent={onAddContent}
        />
      )}

    </Modal>
  );
};

export default AIBuilderModal;
