import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { NodeViewWrapper, NodeViewContent } from "@tiptap/react";

import { stylesToCamelCase } from "../../../utils/StylesToString";
import { wideOrNarrow } from "../../../utils/PageWidth";
import Broadcaster from "../../../helpers/Broadcaster";

import { checkInteractionAndHideIfNeededReact, setUniqId} from "../utils/selection";

import { Button, createStyles } from "@mantine/core";

import tinycolor from "tinycolor2";

import CompletionModel from "../../../models/Completion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";

import { setSpace } from "../../../store/app";

const useStyles = createStyles((theme) => ({
  buttonCompleted: {
    backgroundColor: theme.colors.green[6],
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.colors.green[7],
    },
  },
}));

export default function ActionButton(props) {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const space = useSelector((state) => state.space);
  const [completed, setCompleted] = useState(false);

  const { classes, cx, theme } = useStyles();

  //setUniqId(props.editor, props.node, props.getPos);

  useEffect(() => {
    if (space && space.slug) {
      setCompleted(space.completed);
    }
  }, [space]);

  const getRadius = () => {
    if (props.node.attrs.radius == "no-radius") {
      return "xs";
    } else if (props.node.attrs.radius == "circle-radius") {
      return "xl";
    } else if (props.node.attrs.radius == "with-radius") {
      return "sm";
    } else {
      return "xs";
    }
  };

  const onLink = (event) => {
    
    
    if (props.node.attrs.actionCallbackTarget == "_nothing") {
      return;
    }

    const isJumpTo = props.node.attrs.actionCallbackValue.indexOf("#jumpTo=") >= 0;

    if (isJumpTo) {
      const uid = props.node.attrs.actionCallbackValue.split('#jumpTo=')[1];
      window.location.href= "#jumpTo=" + uid;

      return;
    }

    const isPreview = new URLSearchParams(window.location.search).get("preview") === "true" && window.location.pathname.indexOf("register") >= 0;
    const isGateway = props.node.attrs.actionCallbackValue.indexOf(`/space/${space.slug}/gateway`) >= 0;
    const shouldOpenDemo = isPreview && isGateway;

    if (
      isGateway && props.node.attrs.actionCallbackValue.indexOf("sutra.co") < 0 && props.node.attrs.actionCallbackValue.indexOf("lvh.me") < 0
    ) {
      navigate(props.node.attrs.actionCallbackValue);
    } else {
      const url = props.node.attrs.actionCallbackValue.split("/");
      const hrefDemo = `/${url[3]}/${url[4]}/demo/start`;
      if (
        props.node.attrs.actionCallbackTarget == "_blank" ||
        event.ctrlKey ||
        event.metaKey
      ) {
        if (shouldOpenDemo) {
          window.open(hrefDemo, "_blank");
        } else {
          window.open(props.node.attrs.actionCallbackValue, "_blank");
        }
      } else {
        if (props.node.attrs.actionCallbackValue.indexOf("lvh.me") >= 0) {
          if (url[3]) {
            if (url[4] != space.slug && url[5] == "gateway"){
              if (shouldOpenDemo) {
                window.location.href = hrefDemo;
              } else {
                window.location.href = `/${url[3]}/${url[4]}/${url[5]}`;
              }
            }
            else{
              if (shouldOpenDemo) {
                window.location.href = hrefDemo;
              } else {
                window.location.href = `/${url[3]}/${url[4]}/${url[5]}`;
              }
            }
          } else {
            if (shouldOpenDemo) {
              window.location.assign(hrefDemo);
            } else {
              window.location.assign(props.node.attrs.actionCallbackValue);
            }
          }
        } else {
          if (shouldOpenDemo) {
            window.location.assign(hrefDemo);
          } else {
            window.location.assign(props.node.attrs.actionCallbackValue);
          }
        }
      }
    }
  };

  const onMarkAsCompleted = (event) => {
    CompletionModel.onCreate(
      space.slug,
      (data) => {
        Broadcaster.send(
          "change_sidebarTreeNode",
          document.getElementById(`sidebar_tree_node-${space.slug}`),
          { shouldUpdateTopTree: true }
        );

        dispatch(setSpace({ ...space, completed: data.completed }));

        if (data.completed) {
          setTimeout(() => {
            onLink(event);
          }, 500);
        }
      },
      (data) => {
        navigate(`/user/start?s=${space.slug}`);
      }
    );
  };

  return (
    <NodeViewWrapper
      className="react-component"
      id={props.node.attrs.uid}
      dataUid={props.node.attrs.uid}
      style={{
        maxWidth: wideOrNarrow(),
      }}
    >
      {checkInteractionAndHideIfNeededReact(props.editor, props.getPos) && (
        <NodeViewContent
          contentEditable="false"
          style={{
            ...stylesToCamelCase(props.node.attrs.styles),
            textAlign: props.node.attrs.textAlign,
          }}
          className={`node-content ${props.node.attrs.class}`}
          radius={props.node.attrs.radius}
        >
          <form>
            {props.editor && props.editor.isEditable ? (
              <Button
                fullWidth
                radius={getRadius()}
                sx={(theme) => ({
                  backgroundColor:
                    props.node.attrs.isMarkComplete && completed
                      ? theme.colors.green[6]
                      : props.node.attrs.buttonBackgroundColor,
                  color: props.node.attrs.isMarkComplete && completed ? "#fff" : props.node.attrs.buttonTextColor,
                  border: props.node.attrs.isMarkComplete && completed ? "none" : `1px solid ${props.node.attrs.buttonBackgroundColor}`,
                  "&:hover": {
                    backgroundColor:
                      props.node.attrs.isMarkComplete && completed
                        ? theme.colors.green[7]
                        : tinycolor(props.node.attrs.buttonBackgroundColor)
                            .darken(5)
                            .toString(),
                  },
                })}
                leftIcon={
                  props.node.attrs.isMarkComplete ? (
                    completed ? (
                      <FontAwesomeIcon icon={solid("circle-check")} />
                    ) : (
                      <FontAwesomeIcon icon={regular("circle-check")} />
                    )
                  ) : null
                }
              >
                {props.node.attrs.isMarkComplete
                  ? completed
                    ? `${props.node.attrs.buttonText}`
                    : `${props.node.attrs.buttonText}`
                  : `${props.node.attrs.buttonText}`}
              </Button>
            ) : (
              <Button
                fullWidth
                radius={getRadius()}
                // component={Anchor}
                onClick={(event) =>
                  props.node.attrs.isMarkComplete
                    ? onMarkAsCompleted(event)
                    : onLink(event)
                }
                // target="_blank"
                sx={(theme) => ({
                  backgroundColor:
                    props.node.attrs.isMarkComplete && completed
                      ? theme.colors.green[6]
                      : props.node.attrs.buttonBackgroundColor,
                  color: props.node.attrs.isMarkComplete && completed ? "#fff" : props.node.attrs.buttonTextColor,
                  border: props.node.attrs.isMarkComplete && completed ? "none" : `1px solid ${props.node.attrs.buttonBackgroundColor}`,
                  "&:hover": {
                    backgroundColor:
                      props.node.attrs.isMarkComplete && completed
                        ? theme.colors.green[7]
                        : tinycolor(props.node.attrs.buttonBackgroundColor)
                            .darken(5)
                            .toString(),
                  },
                })}
                leftIcon={
                  props.node.attrs.isMarkComplete ? (
                    completed ? (
                      <FontAwesomeIcon icon={solid("circle-check")} />
                    ) : (
                      <FontAwesomeIcon icon={regular("circle-check")} />
                    )
                  ) : null
                }
              >
                {props.node.attrs.isMarkComplete
                  ? completed
                    ? `${props.node.attrs.buttonText}`
                    : `${props.node.attrs.buttonText}`
                  : `${props.node.attrs.buttonText}`}
              </Button>
            )}
          </form>
        </NodeViewContent>
      )}
    </NodeViewWrapper>
  );
}
