import React, { useState, useEffect } from "react";

import {
  Button,
  Grid,
  Group,
  Menu,
  Text,
  Title,
  Box,
  Divider,
  MantineProvider,
} from "@mantine/core";

import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import useFrequencyOptions from "../../../collections/FrequencyOptions";
import { useTranslation } from "react-i18next";
import { colors } from "../../../collections/colors";
import BroadcastModel from "../../../models/Broadcast";
import SpaceModel from "../../../models/Space";

import Swal from "sweetalert2";

export default function Item(props) {
  const [currentFrequency, setCurrentFrequency] = useState(-1);
  const [active, setActive] = useState(true);
  const [excluded, setExcluded] = useState(true);
  const { frequencyOptions } = useFrequencyOptions();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const onLeave = (event) => {
    event.preventDefault();
    Swal.fire({
      title: t("settings.subscriptions.leaveSpaceConfirmationTitle"),
      html: t("settings.subscriptions.leaveSpaceConfirmationHtml"),
      showDenyButton: true,
      confirmButtonText: t("settings.subscriptions.leaveSpaceButtonText"),
      denyButtonText: t("settings.subscriptions.leaveSpaceDenyButtonText"),
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {
        onLeaveConfirmation();
      } else if (result.isDenied) {
      }
    });
  };

  const onLeaveConfirmation = () => {
    SpaceModel.onLeave(
      props.spaceId,
      (data) => {
        // navigate(`/home/top`);
      },
      (data) => {}
    );
    setActive(false);
  };

  const getCurrentFrequencyLabel = () => {
    for (let i = 0; i < frequencyOptions.length; i++) {
      if (frequencyOptions[i].value == currentFrequency) {
        return frequencyOptions[i].label;
      }
    }

    return t("settings.subscriptions.options");
  };

  const goToPayments = () => {
    navigate(`${props.spaceLink}?show_payments=true`);
  }

  const extraSmall = useMediaQuery("(max-width: 576px)");

  return (
    <Box>
      <Divider
        sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
        mt={10}
        mb={10}
      />
      <Grid grow>
        <Grid.Col xs={2} sm={4} lg={9}>
          {props.parents.map((parent, index) => {
            return (
              <>
                <Text color="dimmed" component={Link} to={`/space/${parent.slug}/${parent.version}`}>
                  {parent.name}
                </Text>
                {index < props.parents.length - 1 && (
                  <Text color="dimmed" component={Link} to={`/space/${parent.slug}/${parent.version}`}>
                    {", "}
                  </Text>
                )}
              </>
            );
          })}
          <Box>
            <Title
              sx={(theme) => ({ color: theme.colors.sutrablue[4] })}
              component={Link}
              to={props.spaceLink}
              order={3}
            >
              {props.spaceName}
            </Title>
          </Box>
        </Grid.Col>
        <Grid.Col span={2}>
          <Group position={extraSmall ? "left" : "right"}>
            <Menu
              placement="end"
              control={
                <Button radius="xl" variant="outline">
                  {`View`}
                </Button>
              }
            >
              <Menu.Label>
                {`Options`}
              </Menu.Label>
              <Menu.Item icon={" "} onClick={() => goToPayments()}>
                {`Payments`}
              </Menu.Item>
              <Menu.Item icon={" "} onClick={(event) => onLeave(event)}>
                {`Cancel subscription`}
              </Menu.Item>
            </Menu>
          </Group>
        </Grid.Col>
      </Grid>
    </Box>
  );
}
