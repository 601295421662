import React from "react";

import { NodeViewWrapper, NodeViewContent } from "@tiptap/react";
import { useSelector } from "react-redux";

import { stylesToCamelCase } from "../../../utils/StylesToString";
import { wideOrNarrow } from "../../../utils/PageWidth";
import isDark from "../../../utils/IsDarkColor";
import isSpaceThemed from "../../../utils/IsSpaceThemed";

import {
  Group,
  Stack,
  createStyles,
  Image,
  Text,
} from "@mantine/core";
import { useElementSize, useMediaQuery } from "@mantine/hooks";
import tinycolor from "tinycolor2";

const useStyles = createStyles(
  (theme, { colors, isDark, listFlatView }, getRef) => ({
    spaceListItem: {
      backgroundPosition: "center",
      backgroundSize: "cover",
      borderRadius: listFlatView ? "0px" : "10px",
      backgroundColor: listFlatView
        ? "none"
        : isDark(colors.background_color)
        ? tinycolor(colors.background_color).lighten(1).toString()
        : tinycolor(colors.background_color).darken(1).toString(),

      padding: listFlatView ? "35px 20px" : "20px 20px 15px",
      border: listFlatView
        ? "none"
        : isSpaceThemed(colors)
        ? `1px solid ${
            isDark(colors.background_color)
              ? tinycolor(colors.background_color).lighten(20).toString()
              : tinycolor(colors.background_color).darken(20).toString()
          }`
        : `1px solid #e9ecef`,

      display: "flex",
      // alignItems: "center",
      width: "100%",
      // marginBottom: "0px",

      "&:hover": {
        // boxShadow: listFlatView ? "none" : "0px 0px 2px 0px rgba(0,0,0,0.4)",
        boxShadow: listFlatView ? "none" : "0px 0px 5px 0px rgba(0,0,0,0.2)",
        backgroundColor: isDark(colors.background_color)
          ? tinycolor(colors.background_color).lighten(2).toString()
          : tinycolor(colors.background_color).darken(2).toString(),
        cursor: "pointer",
        textDecoration: "none",
      },
    },

    title: {
      textDecoration: "none",
      // color: "#000",
      color: isSpaceThemed(colors) ? colors.default_text_color : "#000",
      fontSize: "1.5em",
      lineHeight: "1.3em",
      fontWeight: 600,

      "&:hover": {
        textDecoration: "none",
      },
    },
    titleGroup: {
      marginTop: 10,
    },
    abstract: {
      textDecoration: "none",
      // color: "#000",
      color: isSpaceThemed(colors) ? colors.default_text_color : "#000",
      margin: 0,
      "overflow-wrap": "break-word",
      "word-wrap": "break-word",

      "-ms-word-break": "break-all",
      /* This is the dangerous one in WebKit, as it breaks things wherever */
      "word-break": "break-all",
      /* Instead use this non-standard one: */
      "word-break": "break-word",

      "&:hover": {
        textDecoration: "none",
      },
    },

    titleSm: {
      fontSize: "1.2em",
    },
  })
);

export default function VisionCircle(props) {
  const smallScreen = useMediaQuery("(max-width: 1300px)");
  const { refSize, width, height } = useElementSize();

  const name = props.node.attrs.title;
  const description = props.node.attrs.description;
  const gallery_feature = props.node.attrs.gallery_feature;

  const colors = useSelector((state) => state.colors);

  const { classes, cx, theme } = useStyles({
    colors,
    isDark: isDark,
    listFlatView: false
  });

  return (
    <NodeViewWrapper
      className="react-component"
      id={props.node.attrs.uid}
      dataUid={props.node.attrs.uid}
      style={{
        display: "block",
        width: "100%",
        maxWidth: wideOrNarrow(),
      }}
    >
      <NodeViewContent
        className={`node-content`}
        radius={props.node.attrs.radius}
        contentEditable="false"
        style={{
          ...stylesToCamelCase(props.node.attrs.styles),
          textAlign: props.node.attrs.textAlign,
        }}
      >
        <Stack className={classes.spaceListItem} spacing={0} ref={refSize}>
          {!!gallery_feature?.url && smallScreen && (
            <Image
              height={220}
              radius="md"
              src={gallery_feature.url}
              alt={name}
              sx={{ objectFit: "fill" }}
            />
          )}
          <Group
            position="apart"
            noWrap
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Stack spacing={8}>
              <Group
                spacing="sm"
                sx={{ display: "flex", alignItems: "center" }}
                className={classes.titleGroup}
              >
                <Text
                  className={cx(classes.title, {
                    [classes.titleSm]: width < theme.other.breakpoints.xs,
                  })}
                  lineClamp={2}
                >
                  {name}
                </Text>
              </Group>
              <Text className={classes.abstract} lineClamp={3}>
                {description}
              </Text>
            </Stack>

            {!!gallery_feature?.url && !smallScreen && (
              <Image
                width={200}
                height={120}
                radius="md"
                ml={20}
                src={gallery_feature.url}
                alt={name}
                sx={{ objectFit: "fill" }}
              />
            )}
          </Group>
        </Stack>
      </NodeViewContent>
    </NodeViewWrapper>
  );
}
