import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Home2 } from "tabler-icons-react";

import NewSpace from "../spaces_neo/New";
import AIBuilderModal from "../tiptap/components/AIBuilderModal";

import useActivityCounter from "../../utils/ActivityCounter";

import {
  Button,
  Box,
  UnstyledButton,
  Group,
  Stack,
  ThemeIcon,
  Text,
  MantineProvider,
  Badge,
  Divider,
  createStyles,
  useMantineTheme,
  Modal,
  Textarea,
  Progress,
} from "@mantine/core";

const useStyles = createStyles((theme, _params, getRef) => ({
  control: {
    fontWeight: 500,
    display: "block",
    width: "100%",
    borderRadius: `${theme.radius.md}px`,
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    color: theme.black,
    fontSize: theme.fontSizes.md,

    "&:hover": {
      backgroundColor: theme.colors.gray[1],
      color: theme.black,
      textDecoration: "none",
    },
    [`&:hover .${getRef("themeIcon")}`]: {
      backgroundColor: "#fff",
      // backgroundColor: theme.colors.gray[1],
    },
  },

  themeIcon: {
    ref: getRef("themeIcon"),
  },
}));

export default function Sidebar() {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const location = useLocation();

  const counters = useSelector((state) => state.counters);
  const user = useSelector((state) => state.user);

  const [newSpaceOpened, setNewSpaceOpened] = useState(false);
  const [newAIModalOpened, setNewAIModalOpened] = useState(false);
  const [newAIModalPromptOpened, setNewAIModalPromptOpened] = useState(false);
  const [newSpaceAIBuilderOpened, setNewSpaceAIBuilderOpened] = useState(false);

  const [aiPrompt, setAIPrompt] = useState("");

  const { activityCount } = useActivityCounter(["user_message"]);

  const spacesItems = [
    {
      key: "spaceList",
      label: t("home.sidebar.spaces"),
      to: "/home/top",
      active: "top",
      icon: <Home2 size={22} strokeWidth={1.5} />,
      validation: () => true,
    },
    {
      key: "directMessages",
      label: t("home.sidebar.directMessages"),
      to: "/home/direct",
      active: "direct",
      icon: <FontAwesomeIcon icon={regular("message")} />,
      validation: () => true,
    },
    {
      key: "invitations",
      label: t("home.sidebar.invitations"),
      to: "/home/invitations",
      active: "invitations",
      icon: <FontAwesomeIcon icon={regular("envelope")} />,
      validation: (user) => user.open_invitations > 0,
    },
  ];

  const supportItems = [
    {
      key: "gettingStarted",
      label: t("home.sidebar.gettingStarted"),
      to: "/app/getting_started",
      active: "getting_started",
      icon: <FontAwesomeIcon icon={regular("heart")} />,
      validation: (user) => true,
    },
    {
      key: "scheduleDemo",
      label: t("home.sidebar.scheduleDemo"),
      to: "/app/schedule_demo",
      active: "demo",
      icon: <FontAwesomeIcon icon={regular("calendar-check")} />,
      validation: () => true,
    },
    {
      key: "upgrade",
      label: t("home.sidebar.upgrade"),
      to: "/app/pricing",
      active: "upgrade",
      icon: <FontAwesomeIcon icon={regular("star")} />,
      validation: (user) => user.is_basic || user.is_personal || user.is_pro,
    },
  ];

  const theme = useMantineTheme();

  const checkActiveLink = (item) => {
    return location.pathname.indexOf(item.to) >= 0;
  };

  return (
    <Box>
      <Box key="createButton" pl={20} pr={20} pt={20} pb={10}>
        <Button
          fullWidth
          radius="xl"
          size="md"
          onClick={() => {
            if (user.is_beta) {
              setNewAIModalOpened(true);
            } else {
              setNewSpaceOpened(true);
            }
          }}
          leftIcon={<FontAwesomeIcon icon={solid("plus")} />}
        >
          {t("home.sidebar.createButton")}
        </Button>
      </Box>
      <MantineProvider theme={{ primaryShade: 6 }}>
        <Box key="coreNav" p={10}>
          {spacesItems.map(
            (item) =>
              item.validation(user) && (
                <Box>
                  <UnstyledButton
                    component={Link}
                    key={item.key}
                    to={item.to}
                    className={classes.control}
                  >
                    <Group position="apart" spacing={0}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ThemeIcon
                          variant="light"
                          size={40}
                          radius="md"
                          color="sutrablue"
                          className={classes.themeIcon}
                        >
                          {item.icon}
                        </ThemeIcon>
                        <Box ml="md">
                          <Text weight={checkActiveLink(item) ? "800" : ""}>
                            {item.label}
                          </Text>
                        </Box>
                      </Box>
                      {item.key === "directMessages" &&
                        activityCount > 0 && (
                          <Badge
                            size="sm"
                            variant="filled"
                            sx={{ backgroundColor: theme.colors.sutrapink[3] }}
                          >
                            {activityCount}
                          </Badge>
                        )}
                      {item.key === "invitations" &&
                        user.open_invitations > 0 && (
                          <Badge
                            size="sm"
                            variant="filled"
                            sx={{ backgroundColor: theme.colors.sutrapink[3] }}
                          >
                            {user.open_invitations}
                          </Badge>
                        )}
                    </Group>
                  </UnstyledButton>
                </Box>
              )
          )}
        </Box>
        <Divider
          sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
          ml={20}
          mr={20}
        />
        <Box key="supportNav" p={10}>
          {supportItems.map(
            (item) =>
              item.validation(user) && (
                <Box>
                  <UnstyledButton
                    key={item.key}
                    component={Link}
                    to={item.to}
                    className={classes.control}
                  >
                    <Group position="apart" spacing={0}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ThemeIcon
                          variant="light"
                          size={40}
                          radius="md"
                          color="sutrablue"
                          className={classes.themeIcon}
                        >
                          {item.icon}
                        </ThemeIcon>
                        <Box ml="md">
                          <Text weight={checkActiveLink(item) ? "800" : ""}>
                            {item.label}
                          </Text>
                        </Box>
                      </Box>
                    </Group>
                  </UnstyledButton>
                </Box>
              )
          )}
        </Box>
        <Divider
          sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
          ml={20}
          mr={20}
        />
        {user.is_basic && (
          <Box p={20}>
            <Box
              p={20}
              sx={{
                backgroundColor: theme.colors.blue[0],
                borderRadius: theme.radius.md,
              }}
            >
              <Text weight={600} sx={{ lineHeight: 1.4 }}>
                {t("home.sidebar.promoBoxTitle")}
              </Text>
              <Stack mt={12} spacing={10}>
                <Group key="promoBoxItem1">
                  <FontAwesomeIcon icon={solid("check")} />
                  <Text size="sm">{t("home.sidebar.promoBoxItem1")}</Text>
                </Group>
                <Group key="promoBoxItem2">
                  <FontAwesomeIcon icon={solid("check")} />
                  <Text size="sm">{t("home.sidebar.promoBoxItem2")}</Text>
                </Group>
                <Group key="promoBoxItem3">
                  <FontAwesomeIcon icon={solid("check")} />
                  <Text size="sm">{t("home.sidebar.promoBoxItem3")}</Text>
                </Group>
              </Stack>
              <Button
                fullWidth
                mt={20}
                radius="xl"
                size="xs"
                variant="gradient"
                gradient={{ from: "teal", to: "blue" }}
                leftIcon={<FontAwesomeIcon icon={solid("star")} />}
                component={Link}
                to="/app/pricing"
              >
                {t("home.sidebar.promoBoxButton")}
              </Button>
            </Box>
          </Box>
        )}
      </MantineProvider>

      <NewSpace
        opened={newSpaceOpened}
        setOpened={(bool) => {
          setNewSpaceOpened(bool);
          if (!bool && user.is_beta) {
            setNewAIModalOpened(true);
          }
        }}
      />

      {newSpaceAIBuilderOpened && (
        <AIBuilderModal
          aiPrompt={aiPrompt}
          opened={newSpaceAIBuilderOpened}
          setOpened={(bool) => {
            setAIPrompt("");
            setNewSpaceAIBuilderOpened(bool);
          }}
        />
      )}

      <Modal
        opened={newAIModalOpened}
        onClose={() => setNewAIModalOpened(false)}
        withCloseButton={false}
        padding={30}
      >
        <Box>
          <Button
            style={{ height: 80, marginBottom: 20 }}
            fullWidth
            variant="outline"
            leftIcon={<FontAwesomeIcon icon={solid("asterisk")} size="xl" />}
            onClick={() => {
              setNewAIModalOpened(false);
              setNewSpaceOpened(true);
            }}
          >
            {"Start from scratch"}
          </Button>
          <Button
            style={{ height: 80 }}
            fullWidth
            variant="gradient"
            gradient={{ from: '#fa89f4', to: 'cyan' }}
            leftIcon={<FontAwesomeIcon icon={solid("wand-magic-sparkles")} size="xl" />}
            onClick={() => {
              setNewAIModalOpened(false);
              setNewAIModalPromptOpened(true);
            }}
          >
            {"Start with AI"}
          </Button>
        </Box>
      </Modal>

      <Modal
        opened={newAIModalPromptOpened}
        onClose={() => {
          setAIPrompt("");
          setNewAIModalPromptOpened(false);
          setNewAIModalOpened(true);
        }}
        withCloseButton={false}
      >
        <Textarea
          label={"What do you want to create?"}
          placeholder={"Write something..."}
          data-autofocus
          value={aiPrompt}
          onChange={(event) => setAIPrompt(event.currentTarget.value)}
          minRows={5}
          maxRows={5}
          styles={{ label: { fontWeight: "600", fontSize: 18, paddingBottom: 10 } }}
        />

        <Progress
          value={aiPrompt.length * 2}
          mt={20}
        />

        <Button
          mt={20}
          mb={10}
          fullWidth
          size="lg"
          disabled={aiPrompt.length >= 25 && aiPrompt.length <= 2000 ? false : true}
          leftIcon={<FontAwesomeIcon icon={solid("wand-magic-sparkles")} size="md" />}
          onClick={() => {
            setNewAIModalPromptOpened(false)
            setNewSpaceAIBuilderOpened(true);
          }}
        >
          {"Start generating"}
        </Button>
      </Modal>

    </Box>
  );
}
