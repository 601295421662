import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function useActivityCounter(
  notificationTypes = "all",
  itemsIds = "all",
  itemsTypes = "all",
  itemsSlugs = "all"
) {
  const user = useSelector((state) => state.user);

  const getCount = () => {
    if (!user || !user.activity) {
      return 0;
    }

    return user.activity.filter(
      (n) =>
        !n.is_seen &&
        (notificationTypes === "all" ||
          notificationTypes.indexOf(n.notification_type) >= 0) &&
        (((itemsIds === "all" || itemsIds.indexOf(n.item_slug) >= 0) &&
          (itemsTypes === "all" || itemsTypes.indexOf(n.item_type) >= 0)) ||
          ((itemsTypes === "all" || itemsTypes.indexOf(n.item_type) >= 0) &&
            (itemsSlugs === "all" ||
              itemsSlugs.indexOf(
                n.pod_messages.length > 0 ? n.pod_messages[0].pod_slug : ""
              ) >= 0)) ||
          ((itemsTypes === "all" || itemsTypes.indexOf(n.item_type) >= 0) &&
          (itemsSlugs === "all" ||
            itemsSlugs.indexOf(n.slug) >= 0 || itemsSlugs.indexOf(n.item_id) >= 0)) ||
          ((itemsTypes === "all" || itemsTypes.indexOf(n.item_type) >= 0) &&
            (itemsSlugs === "all" ||
              (n.parents_slugs &&
                itemsSlugs.some((item) => n.parents_slugs.includes(item))))) ||
          (notificationTypes.indexOf("parent_activity") >= 0 &&
            itemsIds.some((item) => n.parents.includes(item))))
    ).length;
  };

  const [activityCount, setActivityCount] = useState(0);

  useEffect(() => {
    setActivityCount(getCount());
  }, [user, itemsSlugs]);

  return {
    activityCount,
  };
}
