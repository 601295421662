import React, { useState, useEffect, useRef } from "react";
import {
  Image,
  Center,
  Loader,
  UnstyledButton,
  Group,
  Text,
  Button,
  ScrollArea,
  createStyles,
  Badge,
  Menu,
  Highlight,
  Box,
  Avatar,
  MantineProvider,
} from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { SpotlightProvider, openSpotlight } from "@mantine/spotlight";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import SearchModel from "../../models/Search";
import data from "@emoji-mart/data";

import tinycolor from "tinycolor2";

import useSidebarStyles from "../../utils/SidebarStyles";
import isSpaceThemed from "../../utils/IsSpaceThemed";
import trackMixpanel from "../../utils/TrackMixpanel";

import defaultSpaceColors from "../../collections/defaultSpaceColors";

import { useMediaQuery } from "@mantine/hooks";

import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme, { colors }) => ({
  primaryButtonStyle: {
    backgroundColor: isSpaceThemed(colors)
      ? colors.primary_button_background_color
      : theme.colors.gray[2],
    color: isSpaceThemed(colors) ? colors.primary_button_text_color : "#000000",
    "&:hover": {
      backgroundColor: isSpaceThemed(colors)
        ? tinycolor(colors.primary_button_background_color).darken(4).toString()
        : theme.colors.gray[1],
    },
  },
  action: {
    position: "relative",
    display: "block",
    width: "100%",
    padding: "10px 12px",
    borderRadius: theme.radius.sm,
  },

  actionHovered: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[1],
  },

  actionBody: {
    flex: 1,
  },
}));

const CustomAction = ({
  action,
  styles,
  classNames,
  hovered,
  onTrigger,
  ...others
}) => {
  const navigate = useNavigate();
  const imageCont = useRef();

  const onTrigger2 = (event, action) => {
    if (action.type == "block") {
      if (action.slug == window.$currentSlug) {
        // Scroll to
        scrollTo(action.uid);
      } else {
        // Navigate to
        navigate(action.link);
      }
    } else {
      navigate(action.link);
    }
  };

  const scrollTo = (uid) => {
    const element = document.querySelector(`[data-uid="${uid}"]`);

    if (element) {
      element.click();
      element.scrollIntoView({ block: "nearest" });

      const selection = window.getSelection();
      selection.removeAllRanges();

      // Create a new range and set its boundaries to the content of the element
      const range = document.createRange();
      range.selectNodeContents(element);

      // Add the range to the selection
      selection.addRange(range);
    } else {
      console.error(`Element with data-uid="${uid}" not found.`);
    }
  };

  const getEmojiCode = (emoji) => {
    if (emoji) {
      return data.emojis[emoji].skins[0].native;
    } else {
      return null;
    }
  };

  const displayEmoji = (emojicon) => {
    if (emojicon && emojicon != "") {
      if (emojicon.split("/")[0] === "emoji") {
        return getEmojiCode(emojicon.split("/")[1]);
      } else if (emojicon.split("/")[0] === "icon") {
        const iconType = emojicon.split("/")[1].split("#")[0];
        const iconValue = emojicon.split("/")[1].split("#")[1];
        if (iconValue === "bolt") {
          return <FontAwesomeIcon icon={solid("bolt")} />;
        }

        return <FontAwesomeIcon icon={[iconType, iconValue]} />;
        // return (<FontAwesomeIcon icon={solid(iconValue)} />)
      } else {
        return getEmojiCode(emojicon);
      }
    }

    return <FontAwesomeIcon icon={solid("bolt")} />;
  };

  const { settingsView, registrationView } = useSidebarStyles();
  let colors = useSelector((state) => state.colors);
  colors = !settingsView && !registrationView ? colors : defaultSpaceColors;
  const { t, i18n } = useTranslation();

  const { classes, cx } = useStyles(
    { colors },
    {
      styles,
      classNames,
      name: "Spotlight",
    }
  );

  return (
    <UnstyledButton
      className={cx(classes.action, { [classes.actionHovered]: hovered })}
      tabIndex={-1}
      onMouseDown={(event) => event.preventDefault()}
      onClick={(event) => onTrigger2(event, action)}
      {...others}
    >
      <Group
        noWrap
        sx={{
          alignItems: "flex-start",
        }}
      >
        <Box mt={5} sx={{ width: "25px", height: "25px" }}>
          <Center>
            {action.image && action.type != "space" && (
              <Box>
                <Avatar
                  withPlaceholder
                  src={action.image}
                  alt={""}
                  size={25}
                  radius={100}
                />
              </Box>
            )}
            {action.type == "space" && displayEmoji(action.icon)}
          </Center>
        </Box>

        <div className={classes.actionBody}>
          <Group position="apart">
            <Group spacing={5}>
              <Highlight
                highlight={action.highlight}
                component={Text}
                sx={{
                  lineHeight: "1",
                }}
              >
                {action.title}
              </Highlight>
              {action.parents_names && action.parents_names.length > 0 && (
                <Text size="xs" color="dimmed">{action.parents_names[0]}</Text>
              )}
            </Group>
          </Group>
          {action.description && (
            <Highlight
              mt={3}
              highlight={action.highlight}
              component={Text}
              color="dimmed"
              size="xs"
            >
              {`${action.description}`}
            </Highlight>
          )}
          <MantineProvider theme={{ primaryShade: 6 }}>
            <Group mt={2} spacing={10}>
              {(action.type == "block" || action.type == "reflection") && (
                <Text size="xs" color="dimmed" weight={600}>
                  {action.author}
                </Text>
              )}
              {(action.type == "block" || action.type == "reflection") && (
                <>
                  <Text size="xs" color="dimmed">
                    •
                  </Text>
                  <Text size="xs" color="dimmed">
                    {action.created_at}
                  </Text>
                </>
              )}
              {action.type == "block" && (
                <Badge size={"xs"} color="lime">
                  {t("layout.search.discussion")}
                </Badge>
              )}
              {action.type == "reflection" && (
                <Badge size={"xs"} color="yellow">
                  {t("layout.search.reflection")}
                </Badge>
              )}
              {action.message_type == "comment" && (
                <Badge size={"xs"} color="orange">
                  {t("layout.search.comment")}
                </Badge>
              )}
            </Group>
          </MantineProvider>
        </div>
      </Group>
    </UnstyledButton>
  );
};

const Search = () => {
  const { t, i18n } = useTranslation();

  const [actions, setActions] = useState([]);
  const [scopeName, setScopeName] = useState("All spaces");
  const [scope2Name, setScope2Name] = useState("Everything");
  const [searching, setSearching] = useState(false);

  const searchTimeout = useRef();
  const lastQuery = useRef();

  const { settingsView, registrationView } = useSidebarStyles();

  let colors = useSelector((state) => state.colors);
  colors = !settingsView && !registrationView && !colors?.is_vision_builder ? colors : defaultSpaceColors;

  const { classes, cx, theme } = useStyles({ colors });

  const largeScreen = useMediaQuery(
    `(min-width: ${theme.other.breakpoints.lg}px)`
  );

  useEffect(() => {
    onSearch(lastQuery.current);
  }, [scopeName, scope2Name]);

  const onSearch = async (query) => {
    lastQuery.current = query;
    setSearching(true);
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }
    searchTimeout.current = setTimeout(() => {
      SearchModel.onSearch(
        query,
        [scopeName, scope2Name],
        (data) => {
          setActions([...data]);
          setSearching(false);
          if (query) {
            trackMixpanel(window.$currentUsername, "Search performed", { query });
          }
        },
        (data) => {
          setSearching(false);
        }
      );
    }, 1000);
  };

  const ActionsWrapper = ({ children }) => {
    return (
      <div style={{ height: "500px" }}>
        <Group
          position="left"
          spacing={10}
          px={15}
          py="xs"
          sx={(theme) => ({
            borderTop: `1px solid ${
              theme.colorScheme === "dark"
                ? theme.colors.dark[4]
                : theme.colors.gray[2]
            }`,
          })}
        >
          <Menu
            control={
              <Button compact variant={"outline"} radius="xl">
                {scopeName}
              </Button>
            }
          >
            <Menu.Label onClick={() => setScopeName("All spaces")}>
              {t("layout.search.allSpaces")}
            </Menu.Label>
            {location.pathname.indexOf("/space") >= 0 && (
              <>
                <Menu.Label
                  onClick={() => setScopeName("Within top level space")}
                >
                  {t("layout.search.withinTop")}
                </Menu.Label>
                <Menu.Label onClick={() => setScopeName("Within this space")}>
                  {t("layout.search.withinSpace")}
                </Menu.Label>
              </>
            )}
          </Menu>
          <Menu
            control={
              <Button compact variant={"outline"} radius="xl">
                {scope2Name}
              </Button>
            }
          >
            <Menu.Label onClick={() => setScope2Name("Everything")}>
              {t("layout.search.everything")}
            </Menu.Label>
            <Menu.Label onClick={() => setScope2Name("Titles only")}>
              {t("layout.search.titlesOnly")}
            </Menu.Label>
            <Menu.Label onClick={() => setScope2Name("Tags only")}>
              {t("layout.search.tagsOnly")}
            </Menu.Label>
            <Menu.Label onClick={() => setScope2Name("Content")}>
              {t("layout.search.content")}
            </Menu.Label>
            <Menu.Label onClick={() => setScope2Name("Discussion")}>
              {t("layout.search.discussion")}
            </Menu.Label>
            <Menu.Label onClick={() => setScope2Name("Events")}>
              {t("layout.search.events")}
            </Menu.Label>
            <Menu.Label onClick={() => setScope2Name("Users")}>
              {t("layout.search.users")}
            </Menu.Label>
          </Menu>
          {searching && <Loader ml={5} size="xs" />}
        </Group>
        <ScrollArea style={{ height: "450px", marginBottom: "100px" }}>
          {children}
        </ScrollArea>
      </div>
    );
  };

  return (
    <>
      <SpotlightProvider
        actions={actions}
        searchIcon={<FontAwesomeIcon icon={solid("search")} />}
        searchPlaceholder={t("layout.search.placeholder")}
        shortcut="mod + shift + 1"
        nothingFoundMessage={t("layout.search.nothingFound")}
        onQueryChange={(query) => onSearch(query)}
        limit={60}
        actionsWrapperComponent={ActionsWrapper}
        actionComponent={CustomAction}
        closeOnActionTrigger={true}
      >
        <Button
          className={classes.primaryButtonStyle}
          variant="light"
          color="dark"
          radius="xl"
          onClick={openSpotlight}
          leftIcon={largeScreen && <FontAwesomeIcon icon={solid("search")} />}
        >
          {largeScreen ? (
            t("layout.search.search")
          ) : (
            <FontAwesomeIcon icon={solid("search")} />
          )}
        </Button>
      </SpotlightProvider>
    </>
  );
};

export default Search;
