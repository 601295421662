import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector } from "react-redux";

import MessageModel from "../../../../models/Message";

import Message from "../discussion/Message";
import Reflections from "../discussion/Reflections";

import NotificationModel from "../../../../../models/NotificationModel";

import isSpaceThemed from "../../../../utils/IsSpaceThemed";
import defaultSpaceColors from '../../../../collections/defaultSpaceColors'
import trackMixpanel from "../../../../utils/TrackMixpanel";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import {
  Button,
  createStyles,
  Box,
  ScrollArea,
  Drawer,
  Center,
  Divider,
  Group,
  Text
} from "@mantine/core";

import tinycolor from "tinycolor2";

import { useInView } from "react-intersection-observer";

import { useTranslation } from "react-i18next";

import { ActionCableContext } from "../../../../components/Index";

const useStyles = createStyles((theme, { colors, props }) => ({
  drawer: {
    backgroundColor: colors.background_color,
  },
  drawerHeader: {
    color: colors.default_text_color,
    fontWeight: 700,
    paddingBottom: 10,
    // borderBottom: `1px solid ${colors.default_text_color}`
  },
  formStyle: {
    "&:focus": {
      border: `1px solid ${tinycolor(colors.background_color)
        .darken(20)
        .toString()}`,
    },
  },
  buttonStyle: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primar_button_text_color,
    border: `1px solid ${colors.primary_button_background_color}`,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(5)
        .toString(),
    },
  },
  dividerStyle: {
    marginTop: 10,
    marginBottom: 10,
    borderTop: `1px solid ${
      !isSpaceThemed(colors)
        ? tinycolor(colors.sidebar_background_color).darken(5).toString()
        : colors.header_color
    }`,
  },
}));

export default function Comments(props) {
  const cable = useContext(ActionCableContext);

  const getColors = () => {
    if (props.useSpace2) {
      return isSpaceThemed(colors2) ? colors2 : defaultSpaceColors;
    } else {
      return isSpaceThemed(colors) ? colors : defaultSpaceColors;
    }
  };

  const colors = useSelector((state) => state.colors);
  const colors2 = useSelector((state) => state.colors2);
  const space = useSelector((state) => state.space);
  const space2 = useSelector((state) => state.space2);
  const user = useSelector((state) => state.user);
  const { classes, cx, theme } = useStyles({ colors: getColors(), props });
  const { t } = useTranslation();

  const [messages, setMessages] = useState([]);
  const [tagging, setTagging] = useState(0);

  const [receiveData, setReceiveData] = useState(null);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [allowComments, setAllowComments] = useState(false);

  const [openedReflections, setOpenedReflections] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [reflectionsTagging, setReflectionsTagging] = useState(false);
  const [reflectionsDrawerHeader, setReflectionsDrawerHeader] = useState(
    window.$showBackButtonModal ? t("spaces.presentations.discussion.backToPost") : t("spaces.presentations.discussion.reflectionHeader")
  );

  const { ref, inView } = useInView({ triggerOnce: true });

  const viewport = useRef();

  const loaded = useRef();
  const messagesCopy = useRef([]);
  const querySearchCount = useRef(0);

  const paginator = useRef({
    per: 30,
    page: 1,
  });

  const lastConnectionAttempt = useRef({
    slug: null,
    state: null,
    channel: null,
  });

  useEffect(() => {
    if (inView && !loaded.current) {
      onFetchMessages();
      performCableConnection();
    }
  }, [inView]);

  useEffect(() => {
    const search = window.location.search;
    const pod_message_id = new URLSearchParams(search).get("pod_message_id");

    if (pod_message_id && messages.length > 0) {
      searchForMessage(pod_message_id);
    }
  }, [messages]);

  useEffect(() => {
    if (!getSpace().slug){
      return
    }
    if (!window.$currentSpaceAllowComments){
      setAllowComments(getSpace().permissions.allow_comments)
    }
    else{
      if (window.$currentSpaceAllowComments.slug == getSpace().slug){
        setAllowComments(window.$currentSpaceAllowComments.allow)
      }
      else{
        setAllowComments(getSpace().permissions.allow_comments)
      }
    }
  }, [space, space2])

  useEffect(() => {
    if (receiveData) {
      let changes = receiveData.changes;
      if (receiveData.state != "multi") {
        changes = [receiveData];
      }

      for (var i = 0; i < changes.length; i++) {
        if (changes[i].state == "new") {
          onReceivedMessage(changes[i].pod_message_id);
        } else if (changes[i].state == "update") {
          onUpdatedMessage(changes[i].pod_message_id);
        } else if (changes[i].state == "destroy") {
          onDestroyedMessage(changes[i].pod_message_id);
        } else if (changes[i].state == "pin") {
          onPinnedMessage(changes[i].pod_message_id);
        } else if (changes[i].state == "unpin") {
          onPinnedMessage(null);
        }
      }
    }
  }, [receiveData]);

  const getSpace = () => {
    if (props.useSpace2){
      return space2;
    }
    else{
      return space;
    }
  }

  const performCableConnection = () => {
    if (lastConnectionAttempt.current.channel) {
      //lastConnectionAttempt.current.channel.unsubscribe();
    }
    lastConnectionAttempt.current.channel = cable.subscriptions.create(
      {
        channel: "PodsChannel",
        pod_slug: getSpace().slug,
        token: window.$currentUserAuthToken,
      },
      {
        connected: () => {
          console.log("Interactive Input Channel connected: " + getSpace().slug);
        },
        disconnected: () => {},
        received: (data) => {
          console.log("Interactive Input Received data: ");
          console.log(data);
          if (data.slug == getSpace().slug) {
            setReceiveData(data);
            return;
          }
        },
      }
    );
  };

  const onReceivedMessage = (messageId) => {
    MessageModel.onFetchById(
      messageId,
      getSpace().slug,
      (data) => {
        if (data.user.id == user.id) {
          setTimeout(() => {
            scrollToBottom();
          }, 500);
        }

        let newMessages = [...messagesCopy.current];

        if (messagesCopy.current.length == 0) {
          messagesCopy.current = [...messages];
          newMessages = [...messagesCopy.current];
        }

        newMessages.push(data);
        messagesCopy.current.push(data);

        setMessages(newMessages);
      },
      (data) => {}
    );
  };

  const onUpdatedMessage = (messageId) => {
    MessageModel.onFetchById(
      messageId,
      getSpace().slug,
      (data) => {
        const newMessages = [...messages];

        for (let i = 0; i < newMessages.length; i++) {
          if (newMessages[i].id == messageId) {
            newMessages[i] = data;
            if (selectedMessage && messageId == selectedMessage.id) {
              setSelectedMessage({ ...newMessages[i] });
            }
            break;
          }
        }

        setMessages(newMessages);
        messagesCopy.current = [...newMessages];
      },
      (data) => {}
    );
  };

  const onDestroyedMessage = (messageId) => {
    const newMessages = [...messages];

    for (let i = 0; i < newMessages.length; i++) {
      if (newMessages[i].id == messageId) {
        newMessages.splice(i, 1);
        break;
      }
    }

    setMessages(newMessages);
    messagesCopy.current = [...newMessages];
  };

  const onPinnedMessage = (messageId) => {
    //const newSpace = { ...space };
    //newSpace.pin_message = messageId;
    // dispatch(setSpace(newSpace));
  };

  const searchForMessage = (messageId) => {
    if (querySearchCount.current > 10) {
      return;
    }

    querySearchCount.current++;

    const search = window.location.search;
    const pod_message_id = new URLSearchParams(search).get("pod_message_id");
    const reflection_id = new URLSearchParams(search).get("reflection_id");

    for (let i = 0; i < messages.length; i++) {
      if (messages[i].id == messageId) {
        querySearchCount.current = 99;
        scrollToMessage(messageId);
        if (reflection_id) {
          const newMessage = { ...messages[i] };
          newMessage.reflectionId = reflection_id;

          setSelectedMessage(newMessage);
          setOpenedReflections(true);
          markReflectionAsSeen(messageId);
          setReflectionsDrawerHeader(
            window.$showBackButtonModal ? t("spaces.presentations.discussion.backToPost") : t("spaces.presentations.discussion.reflectionHeader")
          );
        }
        return;
      }
    }

    onFetchMessages();
  };

  const scrollToMe = () => {
    setTimeout(() => {
      try {
        const $scrollTo = document.getElementById(`${props.node.attrs.uid}`);
        const $contentScrollViewport = document.getElementById(`main-scroll-area`).querySelectorAll("div")[0];;
        const top =
          $scrollTo.offsetTop -
          $contentScrollViewport.offsetTop +
          $contentScrollViewport.scrollTop +
          80;

        $contentScrollViewport.scrollTo({
          top: top,
          behavior: "smooth",
        });
      } catch (e) {}
    }, 500);
  };

  const scrollToMessage = (messageId) => {
    setTimeout(() => {
      try {
        const $scrollTo = document.getElementById(`msg_${messageId}`);
        const $contentScrollViewport = document.getElementById(`main-scroll-area`).querySelectorAll("div")[0];;
        const top =
          $scrollTo.offsetTop -
          $contentScrollViewport.offsetTop +
          $contentScrollViewport.scrollTop +
          80;

        $contentScrollViewport.scrollTo({
          top: top,
          behavior: "smooth",
        });
      } catch (e) {}
    }, 500);
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      try {
        const $contentScrollViewport = document.getElementById(`main-scroll-area`).querySelectorAll("div")[0];;
        if ($contentScrollViewport) {
          const top = $contentScrollViewport.scrollHeight;
          $contentScrollViewport.scrollTo({ top: top });
        }
      } catch (e) {}
    }, 500);
  };

  const onFetchMessages = () => {
    const search = window.location.search;
    const _tag = new URLSearchParams(search).get("tag");

    if (_tag) {
      trackMixpanel(window.$currentUsername, "Tag clicked", { space_slug: getSpace().slug }); 
    }

    MessageModel.onFetch(
      getSpace().slug,
      { tag: _tag, message_type: "comment" },
      paginator.current.page,
      (data) => {
        setCanLoadMore(paginator.current.page < data.pages);

        if (paginator.current.page == 1) {
          messagesCopy.current = [...data.data];
          setMessages([...data.data]);
        } else if (data.data.length > 0) {
          const auxData = data.data.concat(messagesCopy.current);
          messagesCopy.current = [...auxData];
          setMessages([...auxData]);
        }
      },
      (data) => {}
    );
  };

  const onLoadMore = () => {
    paginator.current.page++;
    onFetchMessages();
  };

  const onOpenReflections = (messageId) => {
    setOpenedReflections(true);
    setReflectionsDrawerHeader(
      t("spaces.presentations.discussion.reflectionHeader")
    );

    for (let i = 0; i < messages.length; i++) {
      if (messages[i].id == messageId) {
        markReflectionAsSeen(messageId);
        setSelectedMessage(messages[i]);
        break;
      }
    }
  };

  const markReflectionAsSeen = (messageId) => {
    NotificationModel.onMarkAsSeen("reflection", messageId, (data) => {});
  };

  if (!allowComments) {
    return null;
  }

  return (
    <Box ref={ref}>
      <Divider mt={10} mb={30} className={classes.dividerStyle} />
      {getSpace().slug && messages.length > 0 && (
        <Box>
          {canLoadMore && (
            <Center>
              <Button
                color="dark"
                radius="xl"
                compact
                variant="outline"
                sx={(theme) => ({
                  color: getColors().primary_button_background_color,
                  border: `1px solid ${getColors().primary_button_background_color}`,
                  "&:hover": {
                    backgroundColor: tinycolor(getColors().background_color)
                      .darken(3)
                      .toString(),
                  },
                })}
                onClick={(event) => onLoadMore()}
              >
                {t("tiptap.components.interactiveInput.loadMoreResponse")}
              </Button>
            </Center>
          )}
          <ScrollArea style={{ height: "100%" }} viewportRef={viewport}>
            {messages.map((message) => {
              return (
                <Message
                  key={`message_${message.id}`}
                  spaceId={getSpace().slug}
                  spaceVersion={getSpace().version}
                  messageId={message.id}
                  htmlId={`${message.type}_${message.id}`}
                  messageCreatedAt={message.created_at}
                  messageTitle={message.post ? message.post.subject : null}
                  messageContent={
                    message.version == "new"
                      ? message.content
                      : message.message_body
                  }
                  messageUnseenCount={message.unseen_count}
                  tags={message.tags}
                  reflections={message.reflections}
                  user={message.user ? message.user : {}}
                  onOpenReflections={onOpenReflections}
                  version={message.version}
                  canPin={false}
                  canTag={message.user ? message.user.can_tag : false}
                  canLike={message.user ? message.user.can_like : false}
                  canReflect={true}
                  canEdit={message.user ? message.user.can_edit : false}
                  canDelete={message.user ? message.user.can_delete : false}
                  onUpdated={onUpdatedMessage}
                  type={"pod_message"}
                  tagging={tagging}
                  setTagging={setTagging}
                  isPinned={getSpace().pin_message == message.id}
                  text={message.text}
                  isLiked={message.is_liked}
                  likesCount={message.likes_count}
                  buttonsZIndex={props.buttonsZIndex}
                />
              );
            })}
          </ScrollArea>

          {getSpace() && getSpace().permissions && (
            <Drawer
              opened={openedReflections}
              onClose={() => setOpenedReflections(false)}
              title={
                <Group
                  sx={{
                    "&:hover": {
                      cursor: window.$showBackButtonModal ? "pointer" : "default"
                    }
                  }}
                  onClick={(event) => window.$showBackButtonModal ? setOpenedReflections(false) : null}>
                  {window.$showBackButtonModal && (
                    <FontAwesomeIcon size="md" icon={solid("arrow-left")} />
                  )}
                  <Text>
                    {window.$showBackButtonModal ? t("spaces.presentations.discussion.backToPost") : t("spaces.presentations.discussion.reflectionHeader")}
                  </Text>
                </Group>
              }
              padding="xl"
              size={600}
              classNames={{
                drawer: classes.drawer,
                header: classes.drawerHeader,
              }}
            >
              <Reflections
                spaceId={getSpace().slug}
                spaceVersion={getSpace().version}
                canReflect={true}
                message={selectedMessage}
                onUpdatedMessage={onUpdatedMessage}
                useReflectCallback={true}
                tagging={reflectionsTagging}
                setTagging={setReflectionsTagging}
              />
            </Drawer>
          )}
        </Box>
      )}
    </Box>
  );
}
