import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import VisionInput from '../components/VisionInput';
import {stylesToString} from '../../../utils/StylesToString'

const VisionInputNode = Node.create({
  name: 'visionInput',
  draggable: false,
  allowGapCursor: false,
  selectable: false,
  group: "block",
  inline: false,

  addAttributes() {
    return {
      styles: {
        default: {
          "margin-top": "0px",
          "margin-bottom": "0px",
          "margin-left": "0px",
          "margin-right": "0px",
          "padding-top": "0px",
          "padding-bottom": "10px",
          "padding-left": "0px",
          "padding-right": "0px",
          "background-color": "",
          "background-image": "none",
          "background-position": "center center",
          "background-size": "cover",
          "background-repeat": "no-repeat",
        },
        renderHTML: attributes => ({
          style: stylesToString(attributes.styles),
          styles: stylesToString(attributes.styles),
        })
      }
    }
  },
  parseHTML() {
    return [
      {
        tag: 'visionInput',
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['visionInput', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },
  addNodeView() {
    return ReactNodeViewRenderer(VisionInput)
  },
})

export default VisionInputNode;
