import React, { useState, useEffect, useRef } from "react";

import {
  Group,
  Text,
  Switch,
  Button,
  createStyles,
  useMantineTheme,
  Textarea,
} from "@mantine/core";

import Scripts from "./Scripts";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import SpaceSettingModel from "../../../../models/SpaceSetting";

import PremiumModal from "../../../app/PremiumModal";

const useStyles = createStyles((theme) => ({
  itemTitle: {
    fontSize: 18,
  },

  itemDescription: {
    fontSize: 14,
    color: theme.colors.gray[6],
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },
}));

export default function Registration({
  onUpdate,
  blockUntilRegistered,
  setBlockUntilRegistered,
}) {
  const { t, i18n } = useTranslation();
  const theme = useMantineTheme();
  const space = useSelector((state) => state.space);

  const { classes } = useStyles({ theme });

  const propsLoaded = useRef();

  const [useLegacyRegistrationPage, setUseLegacyRegistrationPage] = useState(false);
  const [blockUntilRegistered2, setBlockUntilRegistered2] = useState(false);
  const [opened, setOpened] = useState(false);
  const [validatePremium, setValidatePremium] = useState(false);
  const [description, setDescription] = useState("");

  const saveTimeoutId = useRef();

  useEffect(() => {
    if (!space.settings) {
      return;
    }

    propsLoaded.current = false;
    setBlockUntilRegistered2(space.block_until_registered);

    setUseLegacyRegistrationPage(space.use_legacy_registration_page);

    setValidatePremium(
      space && space.permissions && space.permissions.can_create_registration_page
    );

    const plain_description = space.calendar?.plain_description;
    setDescription(typeof plain_description === "string" ? plain_description.replace(/<br>/g, "\n") : "");

    setTimeout(() => {
      propsLoaded.current = true;
      // setDisableActions(false);
    }, 1000);
  }, [space]);

  useEffect(() => {
    
    if (propsLoaded.current) {
      onUpdate({ block_until_registered: blockUntilRegistered2 });
      setBlockUntilRegistered(blockUntilRegistered2)
    }
  }, [blockUntilRegistered2]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({ use_legacy_registration_page: useLegacyRegistrationPage });
    }
  }, [useLegacyRegistrationPage]);

  useEffect(() => {
    if (!propsLoaded.current) {
      return;
    }
    if (saveTimeoutId.current) {
      clearTimeout(saveTimeoutId.current);
    }

    saveTimeoutId.current = setTimeout(() => {
      onSave(description);
    }, 1000);
  }, [description]);

  const onEditRegistrationPage = () => {
    if(validatePremium && !validatePremium[0]) {
      setOpened([true, validatePremium[1], validatePremium[2]]);
      return;
    }
    if (space && space.use_legacy_registration_page) {
      window.location.href= `/circle/${space.slug}/pages/manage/1?auth_token=${window.$currentUserAuthToken}`;
    } else {
      window.location.href= `/space/${space.slug}/registration/edit`;
    }
  };

  const onViewRegistrationPage = () => {
    if (space && space.use_legacy_registration_page) {
      window.open(
        `/circle/${space.slug}/register?preview=true&auth_token=${window.$currentUserAuthToken}`,
        `_blank`
      );
    } else {
      if (validatePremium) {
        if (validatePremium[0] && !blockUntilRegistered2) {
          window.open(`/space/${space.slug}/demo/start`, `_blank`);
        } else {
          window.open(`/space/${space.slug}/register?preview=true`, `_blank`);
        }
      } else {
        window.open(`/space/${space.slug}/register?preview=true`, `_blank`);
      }
    }
  };

  const onSave = (description) => {
    SpaceSettingModel.onUpdate(
      space.slug,
      { description },
      (data) => {},
      (data) => {}
    );
  };

  return (
    <>
      <Group position="apart" noWrap spacing="xl" mb={20}>
        <div>
          <Text className={classes.itemTitle}>
            {t("spaces.settings.main.main.title2")}
          </Text>
          <Text className={classes.itemDescription}>
            {t("spaces.settings.main.main.description2")}
          </Text>
        </div>
        <Switch
          onLabel="ON"
          offLabel="OFF"
          className={classes.switch}
          size="lg"
          checked={blockUntilRegistered2}
          onChange={(event) => {
            if(validatePremium && !validatePremium[0]) {
              setOpened([true, validatePremium[1], validatePremium[2]]);
            } else {
              setBlockUntilRegistered2(event.currentTarget.checked)
            }
          }}
        />
      </Group>
      {space && space.has_legacy_registration_page && (
        <Group position="apart" noWrap spacing="xl" mb={20}>
          <div>
            <Text className={classes.itemTitle}>
              {t("spaces.settings.main.main.title3")}
            </Text>
            <Text className={classes.itemDescription}>
              {t("spaces.settings.main.main.description3")}
            </Text>
          </div>
          <Switch
            onLabel={t("spaces.settings.main.main.onButton")}
            offLabel={t("spaces.settings.main.main.offButton")}
            className={classes.switch}
            size="lg"
            checked={useLegacyRegistrationPage}
            onChange={(event) => {
              if(validatePremium && !validatePremium[0]) {
                setOpened([true, validatePremium[1], validatePremium[2]]);
              } else {
                setUseLegacyRegistrationPage(event.currentTarget.checked)
              }
            }}
          />
        </Group>
      )}

      <Group mb={10}>
        <Button onClick={() => onEditRegistrationPage()} radius="xl">
          {t("spaces.settings.main.main.editButton")}
        </Button>
        <Button onClick={() => onViewRegistrationPage()} radius="xl">
          {t("spaces.settings.main.main.previewButton")}
        </Button>
      </Group>

      <div>
        <Text className={classes.itemTitle} mt={20}>
          {/*t("spaces.settings.main.main.title2")*/}
          {"Add description for login page"}
        </Text>
        <Text className={classes.itemDescription}>
          {/*t("spaces.settings.main.main.description2")*/}
          {"This text will show up under the space name on the login page for this space."}
        </Text>
      </div>

      <Textarea
        mt={10}
        value={description}
        onChange={(event) => setDescription(event.currentTarget.value)}
      />

      <Scripts
        onUpdate={onUpdate}
        openPremiumModal={validatePremium && !validatePremium[0] ? () => {
          setOpened([true, validatePremium[1], validatePremium[2]]);
        } : undefined}
      />
      <PremiumModal opened={opened} setOpened={setOpened} />
    </>
  );
}
