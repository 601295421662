import axios from "axios";
import { getCookie } from "../utils/Cookies";

class Space {
  constructor() {
    if (this instanceof User) {
      throw Error("Space class cannot be instantiated.");
    }
  }

  static onFetch = async (
    id,
    include,
    sucessCallback,
    errorCallback,
    signal
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      include,
      invitation_slug: getCookie("invitation_slug"),
      top_slug: window.$currentTopSlug,
    };

    await axios
      .get(`/api/v4/spaces/${id}.json`, { params, signal })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          try {
            errorCallback(error.response.data);
          } catch (e) {
          } finally {
          }
        }
      });
  };

  static onFetchSimpleShow = async (
    id,
    include,
    sucessCallback,
    errorCallback,
    signal
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      include,
      invitation_slug: getCookie("invitation_slug"),
      top_slug: window.$currentTopSlug,
      type: "simple_show",
    };

    await axios
      .get(`/api/v4/spaces/${id}.json`, { params, signal })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          try {
            errorCallback(error.response.data);
          } catch (e) {
          } finally {
          }
        }
      });
  };


  static onFetchBasic = async (
    id,
    include,
    sucessCallback,
    errorCallback,
    signal
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      include,
      invitation_slug: getCookie("invitation_slug"),
      top_slug: window.$currentTopSlug,
      type: "basic",
    };

    await axios
      .get(`/api/v4/spaces/${id}.json`, { params, signal })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          try {
            errorCallback(error.response.data);
          } catch (e) {
          } finally {
          }
        }
      });
  };

  static onFetchSimple = async (
    id,
    type,
    sucessCallback,
    errorCallback,
    signal
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      type: type,
      invitation_slug: getCookie("invitation_slug"),
      top_slug: window.$currentTopSlug,
      include: ["none"],
    };

    await axios
      .get(`/api/v4/spaces/${id}.json`, { params, signal })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          try {
            errorCallback(error.response.data);
          } catch (e) {
          } finally {
          }
        }
      });
  };

  static onFetchByScope = async (
    scope,
    include,
    paginator,
    sucessCallback,
    errorCallback,
    signal
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      include,
      scope,
      ...paginator,
      top_slug: window.$currentTopSlug,
    };

    await axios
      .get(`/api/v4/spaces.json`, { params, signal })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          try {
            errorCallback(error.response.data);
          } catch (e) {
          } finally {
          }
        }
      });
  };

  static onFetchManaged = async (
    exclude_slug,
    include,
    paginator,
    sucessCallback,
    errorCallback,
    signal
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      exclude_slug,
      scope: "all_managed",
      include,
      ...paginator,
      top_slug: window.$currentTopSlug,
    };

    await axios
      .get(`/api/v4/spaces.json`, { params, signal })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          try {
            errorCallback(error.response.data);
          } catch (e) {
          } finally {
          }
        }
      });
  };

  static onFetchManagedDiscussions = async (
    exclude_slug,
    include,
    paginator,
    sucessCallback,
    errorCallback,
    signal
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      exclude_slug,
      scope: "all_managed_discussions",
      include,
      ...paginator,
      top_slug: window.$currentTopSlug,
    };

    await axios
      .get(`/api/v4/spaces.json`, { params, signal })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          try {
            errorCallback(error.response.data);
          } catch (e) {
          } finally {
          }
        }
      });
  };

  static onFetchByUser = async (
    username,
    include,
    paginator,
    sucessCallback,
    errorCallback,
    signal
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      include,
      scope: "user",
      ...paginator,
      username,
      top_slug: window.$currentTopSlug,
    };

    await axios
      .get(`/api/v4/spaces.json`, { params, signal })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          try {
            errorCallback(error.response.data);
          } catch (e) {
          } finally {
          }
        }
      });
  };

  static onFetchDirectMessagesCount = async (sucessCallback, errorCallback) => {
    const params = {
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .get(`/api/v4/spaces/direct_messages_count`, { params: params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onViewAllDirectMessages = async (sucessCallback, errorCallback) => {
    const params = {
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .post(`/api/v4/spaces/view_all_direct_messages`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onCreate = async (space, parent, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space,
      parent_id: parent,
    };

    await axios
      .post(`/api/v4/spaces.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onUpdate = async (slug, space, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space,
    };

    await axios
      .put(`/api/v4/spaces/${slug}.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onUploadHeader = async (slug, file, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      file: file,
    };

    await axios
      .post(`/api/v4/spaces/${slug}/upload_header.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onUploadLogo = async (slug, file, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      file: file,
    };

    await axios
      .post(`/api/v4/spaces/${slug}/upload_logo.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onUploadFile = async (slug, file, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      file: file,
    };

    await axios
      .post(`/api/v4/spaces/${slug}/upload_file.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onUploadThumbnail = async (
    slug,
    file,
    sucessCallback,
    progressCallback,
    errorCallback
  ) => {

    const formData = new FormData();
    formData.append("file", file);
    formData.append("auth_token", window.$currentUserAuthToken);
    formData.append("locale", window.$currentUserLanguage);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progressEvent => {
        const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        sucessCallback(percentage);
      }
    };
    

    await axios
      .post(`/api/v4/spaces/${slug}/upload_thumbnail.json`, formData, config)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onRemoveThumbnail = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .post(`/api/v4/spaces/${slug}/remove_thumbnail.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onRemoveHeader = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .post(`/api/v4/spaces/${slug}/remove_header.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onRemoveLogo = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .post(`/api/v4/spaces/${slug}/remove_logo.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onSaveColors = async (slug, space, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space,
    };

    await axios
      .post(`/api/v4/spaces/${slug}/colors.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onFetchThemes = async (sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .get(`/api/v4/spaces/themes.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onUpdateTree = async (tree, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      ...tree,
    };

    await axios
      .post(`/api/v4/spaces/update_tree.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onJoin = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      joining: true
    };

    await axios
      .post(`/api/v4/spaces/${slug}/gateway.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onJoinWithCoupon = async (slug, coupon_code, price, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      coupon_code,
      price,
      joining: true
    };

    await axios
      .post(`/api/v4/spaces/${slug}/gateway.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onLeave = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .delete(`/api/v4/spaces/${slug}/leave.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onArchive = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .delete(`/api/v4/spaces/${slug}/archive.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onUnarchive = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .post(`/api/v4/spaces/${slug}/unarchive.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onRestore = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .post(`/api/v4/spaces/${slug}/restore.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onDestroy = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .delete(`/api/v4/spaces/${slug}.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onHardDestroy = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .delete(`/api/v4/spaces/${slug}/hard_destroy.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };


  static onPin = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .post(`/api/v4/spaces/${slug}/pin.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onDuplicate = async (space, slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space,
      top_slug: window.$currentTopSlug,
      full: true
    };

    await axios
      .post(`/api/v4/spaces/${slug}/duplicate.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onCopyTemplate = async (
    slug,
    copy_template_into_slug,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      space: {
        copy_resources: true,
        copy_sub_circles: true,
      },
      copy_template_into_slug,
      is_reg_page: window.location.pathname.indexOf("/registration") >=[ 0]
    };

    await axios
      .post(`/api/v4/spaces/${slug}/duplicate.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onFetchContent = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .get(`/api/v4/spaces/${slug}/content.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onSidebarUnreadCount = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .get(`/api/v4/spaces/${slug}/sidebar_unread_count.json`, {
        params: params,
      })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onFetchDirectChildren = async (
    slug,
    include,
    paginator,
    scopes,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      ...paginator,
      include,
      scopes,
      direct: true,
    };

    await axios
      .get(`/api/v4/spaces/${slug}/children.json`, { params: params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onFetchDeepChildren = async (
    slug,
    include,
    paginator,
    scopes,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      ...paginator,
      include,
      scopes,
      deep: true,
    };

    await axios
      .get(`/api/v4/spaces/${slug}/children.json`, { params: params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onFetchChildren = async (
    slug,
    include,
    paginator,
    scopes,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      ...paginator,
      include,
      scopes,
    };

    await axios
      .get(`/api/v4/spaces/${slug}/children.json`, { params: params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onFetchSidebarChildren = async (
    slug,
    sucessCallback,
    errorCallback
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      top_slug: window.$currentTopSlug,
    };

    await axios
      .get(`/api/v4/spaces/${slug}/sidebar.json`, { params: params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onFetchLayouts = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      context:
        window.location.pathname.indexOf("/registration") >= 0
          ? "registration_view"
          : "content",
    };

    await axios
      .get(`/api/v4/spaces/${slug}/layouts.json`, { params: params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onFetchTemplates = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      from:
        window.location.pathname.indexOf("registration") >= 0
          ? "sutraregtemplates"
          : "sutratemplates",
      context:
        window.location.pathname.indexOf("/registration") >= 0
          ? "registration_view"
          : "content",
    };

    await axios
      .get(`/api/v4/spaces/${slug}/templates.json`, { params: params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onFetchModels = async (sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .get(`/api/v4/models.json`, { params: params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onGateway = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      invitation_slug: getCookie("invitation_slug"),
      // bundles: getCookie("bundles"),
      bundles: localStorage.getItem('bundles')
    };

    await axios
      .post(`/api/v4/spaces/${slug}/gateway.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onAccess = async (slug, sucessCallback, errorCallback) => {

    const urlParams = new URLSearchParams(window.location.search);

    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      invitation_slug: getCookie("invitation_slug"),
      affiliate: getCookie("affiliate_space"),
      affiliate_date: getCookie("affiliate_space_date"),
      preview_ij: urlParams.has('preview_ij') ? true : null,
      processing_payment: urlParams.has('processing_payment') ? true : null
    };

    await axios.get(`/api/v4/spaces/${slug}/access.json`, {params})
    .then(res => {

      sucessCallback(res.data)
    })
    .catch((error) => {
      try {

        errorCallback(error.response.data)
      } catch (e) {

      } finally {

      }
    });
  }

  static onFix = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .post(`/api/v4/spaces/${slug}/fix.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onLink = async (slug, parentId, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      parent_id: parentId
    };

    await axios
      .post(`/api/v4/spaces/${slug}/link.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onUnlink = async (slug, parentId, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      parent_id: parentId
    };

    await axios
      .delete(`/api/v4/spaces/${slug}/unlink.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onCanBeLinked = async (slug, parentId, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      parent_id: parentId
    };

    await axios
      .post(`/api/v4/spaces/${slug}/can_be_linked.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onFetchLink = async (url, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      url,
    };

    await axios
      .get(`/api/v4/spaces/fetch_url.json`, { params })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onPermissions = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .post(`/api/v4/spaces/${slug}/permissions.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onNeo4j = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
    };

    await axios
      .get(`/api/v4/spaces/${slug}/neo4j.json`, {params})
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };
  
  static onZoomData = async (slug, zoom_user_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      zoom_user_id: zoom_user_id
    };

    await axios
      .get(`/api/v4/spaces/${slug}/zoom_data.json`, {params})
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onZoomDisconnect = async (slug, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    };

    await axios
      .delete(`/api/v4/spaces/${slug}/disconnect_zoom.json`, {params})
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onZoomCreateMeeting = async (_params, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      ..._params
    };

    await axios
      .post(`/api/v4/spaces/zoom_create_meeting.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };
  
}

export default Space;
