import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  Box,
  Text,
  Tooltip,
  NumberInput,
  Group,
  Badge,
  Button,
  Drawer,
  ScrollArea,
  createStyles,
} from "@mantine/core";

import { DatePicker, TimeInput } from "@mantine/dates";

import CouponsForm from "./CouponsForm";
import SectionHeader from "../layout/ActionsSectionHeader";
import PremiumModal from "../../../app/PremiumModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { sutraTheme } from "../../../../collections/sutraTheme";

import CouponModel from "../../../../models/Coupon";

import { colors } from "../../../../collections/colors";

import Swal from "sweetalert2";

import getSymbolFromCurrency from "currency-symbol-map";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { DateTime } from "luxon";

const useStyles = createStyles((theme) => ({
  item: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  itemTitle: {
    fontSize: 18,
  },

  itemDescription: {
    fontSize: 14,
    color: theme.colors.gray[6],
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },

  subOptionWrap: {
    marginTop: 15,
    backgroundColor: theme.colors.gray[0],
    padding: 15,
    borderRadius: 5,
  },

  subOptionItem: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `6px ${theme.spacing.xs}px`,
    borderRadius: theme.radius.sm,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.white,

    "&:focus-within": {
      borderColor: theme.colors[theme.primaryColor][6],
    },
  },

  control: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,

    "&:disabled": {
      borderColor:
        theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3],
      opacity: 0.8,
      backgroundColor: "transparent",
    },
  },

  input: {
    width: "100%",
  },

  trashStyle: {
    cursor: "pointer",
    // backgroundColor: theme.colors.gray[6],
    color: theme.colors.gray[6],
    "&:hover": {
      color: theme.colors.gray[7],
      // backgroundColor: theme.colors.gray[7],
    },
  },
}));

export default function Coupons(props) {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();

  const [opened, setOpened] = useState(false);
  const [coupon, setCoupon] = useState({});
  const [coupons, setCoupons] = useState([]);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [durationTooltip, setDuratoinTooltip] = useState(false);
  const [validateOpened, setValidateOpened] = useState(false);
  const [validatePremium, setValidatePremium] = useState(false);

  const space = useSelector((state) => state.space);

  useEffect(() => {
    onFetch();
  }, [props]);

  useEffect(() => {
    setValidatePremium(
      space && space.permissions && space.permissions.can_create_coupon
    );
  }, [space]);

  const onFetch = () => {
    CouponModel.onFetch(
      space.slug,
      (data) => {
        setCoupons([...data]);
      },
      (data) => {}
    );
  };

  const onUpdate = () => {
    onFetch();
  };

  const onDestroy = (id) => {
    Swal.fire({
      title: "Are you sure?",
      html: "This coupon will be no longer be available for future use.",
      showDenyButton: true,
      confirmButtonText: "Yes, delete",
      denyButtonText: "No",
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {
        CouponModel.onDestroy(
          space.id,
          id,
          (data) => {
            onFetch();
          },
          (data) => {}
        );
      } else if (result.isDenied) {
      }
    });
  };

  const getDurationText = (item) => {
    if (item.frequency == "once") {
      return "once";
    } else {
      return "for all payments";
    }
  };

  const onCopyCode = (item) => {
    setCopiedToClipboard(item.id);
    setTimeout(() => {
      setCopiedToClipboard(false);
    }, 2000);
  };

  return (
    <>
      <Button
        mb={10}
        variant="filled"
        radius="xl"
        onClick={() => {
          if (validatePremium && !validatePremium[0]) {
            setValidateOpened([true, validatePremium[1], validatePremium[2]]);
          } else {
            setOpened(true)
          }
        }}
        leftIcon={<FontAwesomeIcon icon={solid("tag")} />}
        sx={{
          backgroundColor: sutraTheme.colors.sutrablue[3],
          "&:hover": {
            backgroundColor: sutraTheme.colors.sutrablue[4],
          },
        }}
      >
        {"Create coupon"}
      </Button>
      <Box pt={10}>
        {coupons.map((item) => {
          return (
            <Group
              sx={{
                borderBottom: "1px solid #eee",
                paddingBottom: "20px",
                marginBottom: "20px",
                alignItems: "flex-start",
              }}
              position="apart"
            >
              <Box>
                <Group>
                  <Box>
                    <Tooltip
                      disabled={
                        window.$isTouchDevice && window.$isTouchDevice()
                      }
                      label="Code copied to clipboard"
                      opened={copiedToClipboard == item.id}
                      position="bottom"
                      withArrow
                    >
                      <CopyToClipboard
                        onCopy={() => onCopyCode(item)}
                        text={item.code}
                      >
                        <Badge color="green" variant="dot" size="xl" mb={10}>
                          {item.code}
                        </Badge>
                      </CopyToClipboard>
                    </Tooltip>
                    <Box>
                      <Tooltip
                        wrapLines
                        width={220}
                        disabled={
                          window.$isTouchDevice && window.$isTouchDevice()
                        }
                        label={
                          item.frequency == "once"
                            ? "If this coupon is applied to a subscription, only the first payment will be discounted."
                            : "If this coupon is applied to a subscription, all recurring payments will be discounted."
                        }
                        // opened={copiedToClipboard == item.id}
                        position="bottom"
                        withArrow
                      >
                        {item.discount_type == "percent" ? (
                          <Text color="dimmed" ml={10}>{`${
                            item.formatted_value
                          } off ${getDurationText(item)}`}</Text>
                        ) : (
                          <Text
                            color="dimmed"
                            ml={10}
                          >{`${getSymbolFromCurrency(item.currency)}${
                            item.value
                          } off ${getDurationText(item)}`}</Text>
                        )}
                      </Tooltip>
                    </Box>
                  </Box>
                </Group>
                {item.expire_at && (
                  <Text color="dimmed" ml={10}>
                    {`Valid until ${DateTime.fromSQL(item.expire_at).toFormat(
                      "LLL d, yyyy, h:mm a"
                    )}`}
                  </Text>
                )}
              </Box>
              <Box
                ml={10}
                mt={10}
                className={classes.trashStyle}
                onClick={(event) => onDestroy(item.id)}
              >
                <FontAwesomeIcon icon={solid("trash")} />
              </Box>
            </Group>
          );
        })}
      </Box>
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        title={
          <Text size="xl" weight="800">
            {coupon ? "New coupon" : "Edit coupon"}
          </Text>
        }
        padding="xl"
        size={600}
      >
        <ScrollArea style={{ height: "100%" }}>
          <CouponsForm
            opened={opened}
            setOpened={setOpened}
            coupon={coupon}
            onUpdate={onUpdate}
          />
        </ScrollArea>
      </Drawer>
      <PremiumModal opened={validateOpened} setOpened={setValidateOpened} />
    </>
  );
}
