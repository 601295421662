import axios from "axios";

class Vision {
  constructor() {
    if (this instanceof User) {
      throw Error("Vision class cannot be instantiated.");
    }
  }

  static onFetch = async (
    id,
    sucessCallback,
    errorCallback,
    signal
  ) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken
    };

    await axios
      .get(`/api/v4/visions/${id}.json`, { params, signal })
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          try {
            errorCallback(error.response.data);
          } catch (e) {
          } finally {
          }
        }
      });
  };

  static onCreate = async (prompt, space_id, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      prompt,
      space_id
    };

    await axios
      .post(`/api/v4/visions.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

  static onUseTemplate = async (id, slug, type, sucessCallback, errorCallback) => {
    const params = {
      locale: window.$currentUserLanguage,
      auth_token: window.$currentUserAuthToken,
      id,
      slug,
      type
    };

    await axios
      .post(`/api/v4/visions/use_template.json`, params)
      .then((res) => {
        sucessCallback(res.data);
      })
      .catch((error) => {
        try {
          errorCallback(error.response.data);
        } catch (e) {
        } finally {
        }
      });
  };

}

export default Vision;
