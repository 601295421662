import React from "react";
import { useSelector } from "react-redux";
import { InlineWidget } from "react-calendly";

import {

  ScrollArea,

} from "@mantine/core";

import { Helmet } from "react-helmet";

import { useTranslation } from "react-i18next";

export default function ScheduleDemo() {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user);

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Helmet>
        <title>{t("app.scheduleDemo.tabTitle")}</title>
      </Helmet>
      <InlineWidget
        url="https://calendly.com/sutrasupport/30-minute-sutra-demo"
        styles={{
          height: "1500px",
        }}
        prefill={{
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
          name: `${user.first_name} ${user.last_name}`,
        }}
      />
    </ScrollArea>
  );
}
