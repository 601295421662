import React from "react";
import { Text, createStyles } from "@mantine/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const useStyles = createStyles((theme) => ({
  symbol: {
    textAlign: "center",
    fontSize: 30,
    fontWeight: 700,
    width: 60,
  },
}));

export default function StepIcon(props) {
  const { classes, cx, theme } = useStyles();

  const getColor = () => {
    const value = props.icon;

    if (value == "send_email" || value == "send_message" || value == "push") {
      return theme.colors.blue[5];
    } else if (
      value == "add_to_existing_space" ||
      value == "add_to_new_space" ||
      value == "add_from_sub_space" ||
      value == "add_from_children" ||
      value == "add_to_direct_open_children" ||
      value == "add_sub_circle_members" ||
      value == "add_all_members_from_parent" ||
      value == "add_member_to_parent"
    ) {
      return theme.colors.green[5];
    } else if (value == "survey_person") {
      return theme.colors.violet[5];
    } else if (value == "set_to_pending") {
      return theme.colors.red[5];
    } else if (
      value == "wait" ||
      value == "wait_until" ||
      value == "wait_until_structure_complete"
    ) {
      return theme.colors.gray[5];
    } else if (value == "webhook") {
      return theme.colors.yellow[5];
    } else if (value == "delete_posts" || value == "delete_messages" || value == "remove_from_space") {
      return theme.colors.red[5];
    } else {
      return theme.colors.blue[5];
    }
  };

  const displayStepIcon = () => {
    const value = props.icon;

    return (
      <Text className={classes.symbol} sx={(theme) => ({ color: getColor() })}>
        {value == "send_email" && <FontAwesomeIcon icon={solid("envelope")} />}
        {value == "send_message" && (
          <FontAwesomeIcon icon={solid("envelope")} />
        )}
        {value == "push" && <FontAwesomeIcon icon={solid("bell")} />}
        {(value == "add_to_existing_space" ||
          value == "add_to_new_space" ||
          value == "add_from_sub_space" ||
          value == "add_from_children" ||
          value == "add_to_direct_open_children" ||
          value == "add_all_members_from_parent" ||
          value == "add_member_to_parent" ||
          value == "add_sub_circle_members") && (
          <FontAwesomeIcon icon={solid("user")} />
        )}
        {value == "survey_person" && (
          <FontAwesomeIcon icon={solid("square-poll-horizontal")} />
        )}
        {value == "set_to_pending" && (
          <FontAwesomeIcon icon={solid("circle-pause")} />
        )}
        {(value == "wait" ||
          value == "wait_until" ||
          value == "wait_until_structure_complete") && (
          <FontAwesomeIcon icon={solid("clock")} />
        )}
        {(value == "delete_posts" ||
          value == "delete_messages" ||
          value == "remove_from_space") && (
          <FontAwesomeIcon icon={solid("trash")} />
        )}
        {value == "webhook" && <FontAwesomeIcon icon={solid("link")} />}
      </Text>
    );
  };

  return <div>{displayStepIcon()}</div>;
}
